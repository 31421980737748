import { ReactElement } from 'react';
import { Collapse, Stack, Typography } from '@mui/material';

import { toAbsoluteUrl } from '~utils/assetsHelpers';

import { useBusinessProfileUpdateWidgetContext } from './Context';
import {
  BusinessProfileUpdateWidgetCardRoot,
  BusinessProfileUpdateWidgetCardContent,
  BusinessProfileUpdateWidgetCardImg,
  BusinessProfileUpdateWidgetCardButton,
} from './styles';

const BusinessProfileUpdateWidgetCard = (): ReactElement => {
  const {
    expandedCard: { expanded: expandedCard },
    openDialog: { toggleOpen: toogleOpenDialog },
  } = useBusinessProfileUpdateWidgetContext();

  return (
    <Collapse in={expandedCard} timeout="auto" unmountOnExit>
      <BusinessProfileUpdateWidgetCardRoot>
        <BusinessProfileUpdateWidgetCardContent>
          <Stack spacing={2} direction="row" alignItems="center">
            <BusinessProfileUpdateWidgetCardImg
              src={toAbsoluteUrl('/icons/icon_quiz.svg')}
              alt="Quiz Icon"
            />

            <Stack spacing={0} justifyContent="center">
              <Typography
                component="h6"
                variant="body1"
                color="common.white"
                fontWeight="bold"
              >
                Completa tu perfil de empresa
              </Typography>

              <Typography variant="caption" color="common.white">
                Según tus respuestas, ajustaremos nuestra plataforma,
                comunicación y funcionalidades para brindarte una mejor
                experiencia.
              </Typography>
            </Stack>
          </Stack>

          <BusinessProfileUpdateWidgetCardButton
            variant="contained"
            color="inherit"
            size="small"
            onClick={() => toogleOpenDialog(true)}
          >
            Completar
          </BusinessProfileUpdateWidgetCardButton>
        </BusinessProfileUpdateWidgetCardContent>
      </BusinessProfileUpdateWidgetCardRoot>
    </Collapse>
  );
};

export default BusinessProfileUpdateWidgetCard;
