import { SelectWithScopeOption } from '~globals/types';
import { RouteCalculationModeType } from '~globals/types/enums';

export const routeCalculationModesOptions: SelectWithScopeOption[] = [
  {
    value: String(RouteCalculationModeType.Faster),
    label: 'Recorrido más rapido',
    scopeDescription:
      'Estima la planificación a la ruta más rápidas. (Mayor velocidad media).',
  },
  {
    value: String(RouteCalculationModeType.Shorter),
    label: 'Recorrido más corto',
    scopeDescription:
      'Estima la planificación a la ruta con menor kilometraje.',
  },
];
