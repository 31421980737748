import { useMemo } from 'react';
import { chain } from 'lodash';

import { VehicleSearchItem } from '~services/vehicle/types';
import { ItemExtended } from '~services/item/types';
import { isPlanStart } from '~utils/plans';

import { useSubscription } from './useSubscription';
import { useSelectedRows, SelectedRowsReturn } from './useSelectedRows';

export interface UseGenerateRoutesSelectedDataParams {
  vehiclesData: VehicleSearchItem[];
  ordersData: ItemExtended[];
  keysFields: { VEHICLE: string; ORDER: string };
  getDisabledVehicle: (currentVehicle: VehicleSearchItem) => boolean;
  getDisabledOrder: (currentOrder: ItemExtended) => boolean;
}

export interface UseGenerateRoutesSelectedDataReturn {
  vehicles: SelectedRowsReturn<VehicleSearchItem>;
  orders: SelectedRowsReturn<ItemExtended>;
}

export const useGenerateRoutesSelectedData = ({
  vehiclesData,
  ordersData,
  keysFields,
  getDisabledVehicle,
  getDisabledOrder,
}: UseGenerateRoutesSelectedDataParams): UseGenerateRoutesSelectedDataReturn => {
  const subscription = useSubscription();

  const nonSelectablesVehiclesIds = useMemo(
    () =>
      chain(vehiclesData)
        .filter(getDisabledVehicle)
        .map(keysFields.VEHICLE)
        .value(),
    [vehiclesData, getDisabledVehicle, keysFields.VEHICLE],
  );

  const modeSelectedVehicles = useMemo(
    () => (isPlanStart(subscription) ? 'radio' : 'checkbox'),
    [subscription],
  );

  const selectedVehicles = useSelectedRows<VehicleSearchItem>(
    keysFields.VEHICLE,
    [],
    [],
    nonSelectablesVehiclesIds,
    modeSelectedVehicles,
  );

  const selectablesOrders = useMemo(
    () =>
      chain(ordersData)
        .filter((row) => !getDisabledOrder(row))
        .value(),
    [ordersData, getDisabledOrder],
  );

  const selectablesOrdersIds = useMemo(
    () => chain(selectablesOrders).map(keysFields.ORDER).value(),
    [keysFields.ORDER, selectablesOrders],
  );

  const nonSelectablesOrdersIds = useMemo(
    () =>
      chain(ordersData).filter(getDisabledOrder).map(keysFields.ORDER).value(),
    [getDisabledOrder, keysFields.ORDER, ordersData],
  );

  const selectedOrders = useSelectedRows<ItemExtended>(
    keysFields.ORDER,
    selectablesOrders,
    selectablesOrdersIds,
    nonSelectablesOrdersIds,
  );

  return { vehicles: selectedVehicles, orders: selectedOrders };
};
