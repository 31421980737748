import { styled, ButtonBase } from '@mui/material';

export const ProToPlusDiscountImgButton = styled(ButtonBase)({
  borderRadius: 14,
});

export const ProToPlusDiscountImgImage = styled('img')({
  width: '100%',
  height: 155,
});
