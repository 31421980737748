import { ReactElement, useCallback } from 'react';
import { MenuList, MenuItem, ListItemIcon, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleUser as ProfileIcon,
  faArrowRightFromBracket as LogoutIcon,
} from '@fortawesome/free-solid-svg-icons';

import { useAppDispatch } from '~hooks/index';
import { resetAuth } from '~store/slices/auth/index';
import { resetHome } from '~store/slices/home/index';
import { PATHS } from '~constants/index';
import { Menu, Link } from '~components/index';
import { MenuProps } from '~components/Menu/types';
import { useAccessFeatureModalContext } from '~app/contexts/AccessFeatureModalContext';

const ProfileMenu = ({
  onClose,
  ...restProps
}: Omit<MenuProps, 'children'>): ReactElement => {
  const dispatch = useAppDispatch();
  const { handleClose: handleCloseAccesFeatureModal } =
    useAccessFeatureModalContext();

  const handleLogout = useCallback(() => {
    dispatch(resetAuth());
    dispatch(resetHome());
    handleCloseAccesFeatureModal();
    onClose();
  }, [dispatch, handleCloseAccesFeatureModal, onClose]);

  return (
    <Menu onClose={onClose} {...restProps}>
      <MenuList>
        <Link to={PATHS.PROFILE.BASE} color="inherit" onClick={onClose}>
          <MenuItem>
            <ListItemIcon>
              <FontAwesomeIcon icon={ProfileIcon} />
            </ListItemIcon>

            <Typography>Perfil</Typography>
          </MenuItem>
        </Link>

        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <FontAwesomeIcon icon={LogoutIcon} />
          </ListItemIcon>

          <Typography>Cerrar Sesión</Typography>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default ProfileMenu;
