import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

import { selectAuth, resetAuth } from '~store/slices/auth';
import { resetHome } from '~store/slices/home';
import { deleteAdminUser } from '~services/admin/users';

import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
import { useLazyRequest } from './useLazyRequest';

export const useUserAdminDelete = (
  onSuccessDelete?: () => void,
): {
  loading: boolean;
  handleDeleteUser: (userId: string) => Promise<void>;
} => {
  const dispatch = useAppDispatch();
  const { user: currentLoggedUser } = useAppSelector(selectAuth);

  const { enqueueSnackbar } = useSnackbar();

  const [, loadingDeleteUser, , executeDeleteUser] = useLazyRequest({
    request: deleteAdminUser,
    withPostSuccess: (response) => {
      const userResponse = response.data?.data;
      const userName = `${userResponse?.firstName} ${userResponse?.lastName}`;
      const message = `El usuario ${userName} fue eliminado correctamente`;

      enqueueSnackbar(message, { variant: 'success' });

      if (currentLoggedUser?.userId === userResponse?.userId) {
        dispatch(resetAuth()); // Logout current user
        dispatch(resetHome());
      } else {
        onSuccessDelete?.();
      }
    },
    withPostFailure: () => {
      enqueueSnackbar('No se pudo elimnar el usuario, intente nuevamente', {
        variant: 'error',
      });
    },
  });

  const handleDeleteUser = useCallback(
    async (userId: string) => {
      await executeDeleteUser(userId);
    },
    [executeDeleteUser],
  );

  return { loading: loadingDeleteUser, handleDeleteUser };
};
