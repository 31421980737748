import { ReactElement, useMemo, useCallback } from 'react';
import { Stack, Button } from '@mui/material';
import { Cancel as CancelIcon, Edit as EditIcon } from '@mui/icons-material';
import {
  faLayerGroup as SubscriptionsIcon,
  faHourglassStart as ActiveDateIcon,
  faCalendarTimes as ExpiredDateIcon,
  faArrowsSpin as CyleDateIcon,
  faMoneyBill as MoneyIcon,
  faCreditCard as CreditCardIcon,
} from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { isUndefined, upperCase } from 'lodash';

import { useToggle, useLazyRequest } from '~hooks/index';
import {
  cancelMPPaymentPlanSubscription,
  cancelPaypalPaymentPlanSubscription,
} from '~services/subscription/payment';
import { formatCurrencyValue, formatterDate } from '~utils/index';
import { openFileUrl } from '~utils/file';
import { isCancelledCurrentPlan } from '~utils/plans';
import { MenuActionsDataTable, DialogConfimation } from '~components/index';
import { PATHS } from '~constants/paths';
import {
  PaymentGatewayTypes,
  PaymentFrecuencyTypes,
} from '~globals/types/enums';

import SubscriptionEditPaymentModal from '../EditPaymentModal';

import { SubscriptionPlanCardProps } from './types';
import {
  SubscriptionPlanCardRoot,
  SubscriptionPlanCardHeader,
  SubscriptionPlanCardHeaderStatusChip,
  SubscriptionPlanCardContent,
} from './styles';
import SubscriptionPlanCardRowInfo from './RowInfo';
// eslint-disable-next-line max-len
import SubscriptionAvailableRoutesStateCircularProgress from '../AvailableRoutesState/CircularProgress';

const warningCancelPlanSub =
  'Esta acción cancelara la subscripción del plan. Una vez cancelada no se puede dar vuelta atras';

const SubscriptionPlanCard = ({
  name,
  price,
  statusType,
  activedDate,
  expiredDate,
  cycleDate,
  contractPlanId,
  gatewayType,
  frecuencyType,
  isTrial = false,
  routes,
  payment,
  invoiceFile,
  actions,
  companyPlanId,
}: SubscriptionPlanCardProps): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const isExpired = useMemo(() => {
    const now = moment();
    const expiration = moment(expiredDate);

    return expiration.isBefore(now, 'day');
  }, [expiredDate]);

  const isFree = useMemo(() => !price, [price]);

  const isCancelled = useMemo(
    () => isCancelledCurrentPlan(statusType),
    [statusType],
  );

  const isSinglePayment = useMemo(
    () => frecuencyType === PaymentFrecuencyTypes.SINGLE,
    [frecuencyType],
  );

  const isEnabledNextPayment = useMemo(
    () => !isExpired && !isFree && !isTrial && !isCancelled && !isSinglePayment,
    [isCancelled, isExpired, isFree, isTrial, isSinglePayment],
  );

  const isEnabledInvoice = useMemo(
    () => !isExpired && !isFree && !isTrial,
    [isExpired, isFree, isTrial],
  );

  const planNameText = useMemo(
    () => (isTrial ? `${name} (PRUEBA)` : name),
    [isTrial, name],
  );

  const statusText = useMemo(
    () => (isExpired ? 'Vencido' : 'Activo'),
    [isExpired],
  );

  const cycleDateText = useMemo(() => {
    if (!isSinglePayment && cycleDate) {
      const fromText = formatterDate(cycleDate.from, { format: 'DD/MM/YYYY' });
      const tillText = formatterDate(cycleDate.till, { format: 'DD/MM/YYYY' });

      return `${fromText} al ${tillText}`;
    }

    return undefined;
  }, [isSinglePayment, cycleDate]);

  const paymentTypeText = useMemo(() => {
    if (payment?.method && payment.last4Digits) {
      return `${upperCase(payment.method)} terminada en ${payment.last4Digits}`;
    }

    return undefined;
  }, [payment]);

  const [openConfirmCancelPlanSubDialog, toggleOpenConfirmCancelPlanSubDialog] =
    useToggle(false);

  const messageCancelPlanSub = useMemo(
    () => `La subscripción al plan ${name} será cancelado`,
    [name],
  );

  const isMPGatweay = useMemo(
    () => gatewayType === PaymentGatewayTypes.MERCADOPAGO,
    [gatewayType],
  );

  const cancelPlanSubRequest = useMemo(
    () =>
      isMPGatweay
        ? cancelMPPaymentPlanSubscription
        : cancelPaypalPaymentPlanSubscription,
    [isMPGatweay],
  );

  const [, loadingCancelPlanSub, , executeCancelPlanSub] = useLazyRequest({
    request: cancelPlanSubRequest,
    withPostSuccess: () => {
      actions?.onCancelSuccess?.();

      const message = `La subscripción al plan ${name} fue cancelada correctamente`;

      enqueueSnackbar(message, { variant: 'success' });

      toggleOpenConfirmCancelPlanSubDialog(false);
    },
    withPostFailure: () => {
      actions?.onCancelFailure?.();

      enqueueSnackbar('No se pudo elimnar el pedido, intente nuevamente', {
        variant: 'error',
      });
    },
  });

  const handleCancelPlanSub = useCallback(() => {
    executeCancelPlanSub(contractPlanId);
  }, [executeCancelPlanSub, contractPlanId]);

  const [openEditPaymentModal, toggleEditPaymentModal] = useToggle(false);

  return (
    <>
      <SubscriptionPlanCardRoot>
        <SubscriptionPlanCardHeader>
          <SubscriptionPlanCardRowInfo
            value={planNameText}
            icon={SubscriptionsIcon}
            textColor="textPrimary"
          />

          <Stack spacing={1} direction="row" alignItems="center">
            <SubscriptionPlanCardHeaderStatusChip
              label={statusText}
              variant="outlined"
              size="medium"
              isExpired={isExpired}
            />

            {isCancelled && !isSinglePayment && (
              <SubscriptionPlanCardHeaderStatusChip
                label="Sin renovación"
                variant="outlined"
                size="medium"
                isExpired={false}
                hasRenewal
              />
            )}

            {isEnabledNextPayment && (
              <MenuActionsDataTable
                resource={{
                  path: PATHS.CONFIG.INTEGRATIONS.BASE,
                }}
                items={{
                  show: { enabled: false },
                  edit: { enabled: false },
                }}
                extraItems={[
                  {
                    enabled: isMPGatweay,
                    label: 'Modificar método de pago',
                    Icon: <EditIcon color="success" />,
                    onClick: () => toggleEditPaymentModal(true),
                    divider: true,
                  },
                  {
                    enabled: true,
                    label: 'Cancelar',
                    Icon: <CancelIcon color="error" />,
                    onClick: () => toggleOpenConfirmCancelPlanSubDialog(true),
                  },
                ]}
                optionsButton={{
                  color: 'inherit',
                  variant: 'contained',
                  size: 'small',
                }}
              />
            )}
          </Stack>
        </SubscriptionPlanCardHeader>

        <SubscriptionPlanCardContent>
          <Stack spacing={2}>
            <Stack spacing={0.5}>
              <SubscriptionPlanCardRowInfo
                description="Fecha de alta:"
                value={formatterDate(activedDate, { format: 'DD/MM/YYYY' })}
                icon={ActiveDateIcon}
                textColor="textSecondary"
              />

              <SubscriptionPlanCardRowInfo
                description="Vencimiento:"
                value={formatterDate(expiredDate, { format: 'DD/MM/YYYY' })}
                icon={ExpiredDateIcon}
                textColor="textSecondary"
              />

              {cycleDateText && (
                <SubscriptionPlanCardRowInfo
                  description="Ciclo actual:"
                  value={cycleDateText}
                  icon={CyleDateIcon}
                  textColor="textSecondary"
                />
              )}

              {isEnabledNextPayment && (
                <SubscriptionPlanCardRowInfo
                  description="Próximo pago:"
                  value={formatCurrencyValue(price)}
                  icon={MoneyIcon}
                  textColor="textSecondary"
                />
              )}

              {paymentTypeText && (
                <SubscriptionPlanCardRowInfo
                  description="Método de pago:"
                  value={paymentTypeText}
                  icon={CreditCardIcon}
                  textColor="textSecondary"
                />
              )}
            </Stack>

            <Stack spacing={1} direction="row">
              {isEnabledInvoice && invoiceFile && (
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => openFileUrl(invoiceFile.url, '_blank')}
                  sx={{ minWidth: 100 }}
                >
                  Ver factura
                </Button>
              )}
            </Stack>
          </Stack>

          {!isUndefined(routes) && routes.max > 0 && (
            <SubscriptionAvailableRoutesStateCircularProgress
              currentRoutes={routes.used}
              maxRoutes={routes.max}
              label="Rutas"
              size="medium"
              disabled={isExpired}
            />
          )}
        </SubscriptionPlanCardContent>
      </SubscriptionPlanCardRoot>

      <DialogConfimation
        open={openConfirmCancelPlanSubDialog}
        description={messageCancelPlanSub}
        warningText={warningCancelPlanSub}
        onConfirm={handleCancelPlanSub}
        onCancel={() => toggleOpenConfirmCancelPlanSubDialog(false)}
        loadingConfirm={loadingCancelPlanSub}
      />

      <SubscriptionEditPaymentModal
        companyPlanId={companyPlanId}
        open={openEditPaymentModal}
        onClose={() => toggleEditPaymentModal(false)}
      />
    </>
  );
};

export default SubscriptionPlanCard;
