import { ReactElement } from 'react';

import BusinessProfileUpdateWidgetProvider from './Context';
import BusinessProfileUpdateWidgetCard from './Card';
import BusinessProfileUpdateWidgetDialog from './Dialog';

const BusinessProfileUpdateWidget = (): ReactElement => (
  <BusinessProfileUpdateWidgetProvider>
    <BusinessProfileUpdateWidgetCard />

    <BusinessProfileUpdateWidgetDialog />
  </BusinessProfileUpdateWidgetProvider>
);

export default BusinessProfileUpdateWidget;
