import { ReactElement, useState, useCallback, useMemo } from 'react';
import { Typography, IconButton, Stack, Divider } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { defaultTo } from 'lodash';

import { Menu, LoadingFallback } from '~components/index';
import { MenuProps } from '~components/Menu/types';
import { useLazyRequest, useInfiniteScroll } from '~hooks/index';
import { searchNews } from '~services/news';
import { NewsData } from '~services/news/types';
import { DataPaginateResponse } from '~globals/types';

import NewsMenuItemInfo from './NewsMenuItemInfo';
import {
  NewsMenuContainer,
  NewsMenuHeader,
  NewsMenuContent,
  NewsMenuContentList,
} from './styles';

const defaultSizePerPage = 4;

const NewsMenu = ({
  onClose,
  ...restProps
}: Omit<MenuProps, 'children'>): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const [newsData, setNewsData] = useState<NewsData[]>([]);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [page, setPage] = useState(1);

  const [, loading, error, executeFetchNewsData] = useLazyRequest({
    request: searchNews,
    withPostSuccess: (response) => {
      const {
        data: { pagination, results },
      } = response.data as DataPaginateResponse<NewsData[]>;

      const currentNewsData = [...newsData, ...results];
      setNewsData(currentNewsData);

      const totalSize = defaultTo(pagination?.count, results.length);
      setHasNextPage(currentNewsData.length < totalSize);

      setPage((prevPage) => prevPage + 1);
    },
    withPostFailure: () => {
      enqueueSnackbar('No se pudo cargar los datos', { variant: 'error' });
    },
  });

  const onLoadMore = useCallback(() => {
    executeFetchNewsData({
      pagination: {
        page,
        sizePerPage: defaultSizePerPage,
      },
    });
  }, [executeFetchNewsData, page]);

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore,
    disabled: !!error,
    rootMargin: `0px 0px 350px 0px`,
  });

  const showLoading = useMemo(
    () => loading || hasNextPage,
    [loading, hasNextPage],
  );

  const notActiveRoutes = useMemo(
    () => !loading && !hasNextPage && !newsData.length,
    [loading, hasNextPage, newsData.length],
  );

  return (
    <Menu onClose={onClose} {...restProps}>
      <NewsMenuContainer>
        <NewsMenuHeader>
          <Typography component="h3" variant="subtitle1" fontWeight="medium">
            NOVEDADES Y NOTICIAS
          </Typography>

          <IconButton
            size="small"
            aria-label="Close"
            onClick={onClose}
            color="inherit"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </NewsMenuHeader>

        <NewsMenuContent>
          <NewsMenuContentList ref={rootRef}>
            <Stack spacing={2} divider={<Divider />}>
              {newsData.map((item) => (
                <NewsMenuItemInfo
                  key={`news-${item.id}`}
                  date={item.date}
                  title={item.title}
                  plans={item.plans}
                  description={item.description}
                />
              ))}
            </Stack>

            {showLoading && <LoadingFallback size={50} />}

            {notActiveRoutes && (
              <Stack spacing={1}>
                <Typography
                  variant="h6"
                  textAlign="center"
                  my="auto"
                  fontWeight="bold"
                >
                  No se encontraron novedades
                </Typography>
              </Stack>
            )}

            <div ref={sentryRef} />
          </NewsMenuContentList>
        </NewsMenuContent>
      </NewsMenuContainer>
    </Menu>
  );
};

export default NewsMenu;
