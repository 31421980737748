import {
  FC,
  createContext,
  useContext,
  useCallback,
  useMemo,
  useState,
  useRef,
} from 'react';
import { useSnackbar } from 'notistack';
import { defaultTo } from 'lodash';
import moment from 'moment';
import { TableChangeType } from 'react-bootstrap-table-next';

import { useFiltersValues, useLazyRequest } from '~hooks/index';
import { searchItemsAll } from '~services/item';
import {
  ItemSearchFilter,
  ItemExtended,
  ItemsWithGeoErrorsHead,
} from '~services/item/types';
import {
  DataPaginateResponse,
  PaginationRequestParams,
  Nullable,
  SelectOption,
} from '~globals/types';
import { RouteItemStateType, ItemStateTypes } from '~globals/types/enums';
import { sortOrderPendingByExpirationDate } from '~utils/order';
import { getItemValue } from '~utils/helpers';
import { formatterDate } from '~utils/formatter';
import { CustomAutocompleteRef } from '~components/CustomAutocomplete/types';

import { DialogOrderSearchContextValue } from './types';
import {
  DEFAULT_PAGINATION,
  DEFAULT_FILTERS_STATE,
  DEFAULT_FILTERS_PAYLOAD,
  ITEM_STATE_KEY,
  ROUTE_ITEM_STATE_KEY,
} from './utils';

const DialogOrderSearchContext = createContext<DialogOrderSearchContextValue>({
  filters: {
    filters: DEFAULT_FILTERS_STATE,
    isAppliedFilter: false,
    setFilterByKey: () => {},
    handleChangeInputFilter: () => {},
    clearFilters: () => {},
    parseFiltersRequest: DEFAULT_FILTERS_PAYLOAD,
    refStatesAutocomplete: { current: null },
  },
  table: {
    data: [],
    loading: true,
    totalSize: 0,
    pagination: DEFAULT_PAGINATION,
    handleChangeTable: () => {},
    handleInitialTable: () => {},
  },
});

export const useDialogOrderSearchContext = (): DialogOrderSearchContextValue =>
  useContext(DialogOrderSearchContext);

const DialogOrderSearchProvider: FC = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { filters: currentFilters, ...restFiltersData } = useFiltersValues(
    DEFAULT_FILTERS_STATE,
  );

  const refStatesAutocomplete =
    useRef<CustomAutocompleteRef<SelectOption, true>>(null);

  const parseFilterDate = useCallback(
    (key: 'dateFrom' | 'dateTo' | 'expirationDate') => {
      const date: Nullable<string> = getItemValue(
        currentFilters,
        key,
        (val: Nullable<Date>) => {
          if (!val) return null;

          const formatDate = formatterDate(new Date(val), {
            format: moment.HTML5_FMT.DATETIME_LOCAL_MS,
          });

          return formatDate;
        },
      );

      return date;
    },
    [currentFilters],
  );

  const parseSearch = useCallback(() => {
    const search = getItemValue(
      currentFilters,
      'search',
      (val): Nullable<string> => val || null,
    );

    return search;
  }, [currentFilters]);

  const parseSelectedStates = useCallback(
    (states: string[]): string[][] => states.map((state) => state.split('-')),
    [],
  );

  const filterStatesbyPrefix = useCallback(
    (states: string[][], compratePrefix: string): number[] =>
      states
        .filter(([prefix]) => prefix === compratePrefix)
        .map(([, currentState]) => Number(currentState)),
    [],
  );

  const parseRouteItemStates = useCallback(() => {
    const currentStates: RouteItemStateType[] = getItemValue(
      currentFilters,
      'states',
      (states) => {
        const currentParseStates = parseSelectedStates(states);

        const filterStates = filterStatesbyPrefix(
          currentParseStates,
          ROUTE_ITEM_STATE_KEY,
        );

        const includeNewState = currentParseStates.some(
          ([prefix]) => prefix === ITEM_STATE_KEY,
        );

        if (includeNewState) {
          return [RouteItemStateType.New, ...filterStates];
        }

        return filterStates;
      },
      [],
    );

    return currentStates;
  }, [currentFilters, parseSelectedStates, filterStatesbyPrefix]);

  const parseItemStates = useCallback(() => {
    const currentStates: Nullable<ItemStateTypes[]> = getItemValue(
      currentFilters,
      'states',
      (states) => {
        const currentParseStates = parseSelectedStates(states);

        return filterStatesbyPrefix(currentParseStates, ITEM_STATE_KEY);
      },
      [],
    );

    return currentStates;
  }, [currentFilters, parseSelectedStates, filterStatesbyPrefix]);

  const parseFiltersRequest = useMemo<ItemSearchFilter>(
    () => ({
      dateFrom: parseFilterDate('dateFrom'),
      dateTo: parseFilterDate('dateTo'),
      driverId: null,
      routeCode: parseSearch(),
      maxDeliveredDateTime: parseFilterDate('expirationDate'),
      routeItemStateTypesId: parseRouteItemStates(),
      itemStateTypesId: parseItemStates(),
    }),
    [parseFilterDate, parseSearch, parseRouteItemStates, parseItemStates],
  );

  const [tableData, setTableData] = useState<ItemExtended[]>([]);
  const [tableTotalSize, setTableTotalSize] = useState<number>(0);
  const [tablePagination, setTablePagination] =
    useState<PaginationRequestParams<unknown>['pagination']>(
      DEFAULT_PAGINATION,
    );

  const [, loadingTable, , executeGetTableData] = useLazyRequest({
    request: searchItemsAll,
    transformResponse: (res) => {
      const response = res.data as DataPaginateResponse<
        ItemExtended[],
        null,
        ItemsWithGeoErrorsHead
      >;

      if (response.data.results) {
        sortOrderPendingByExpirationDate(response.data.results);
      }

      return response;
    },
    withPostSuccess: (response) => {
      const {
        data: { pagination, results },
      } = response as unknown as DataPaginateResponse<ItemExtended[]>;

      setTableData(results);
      setTableTotalSize(defaultTo(pagination?.count, results.length));
    },
    withPostFailure: () => {
      enqueueSnackbar('No se pudo cargar los datos', { variant: 'error' });
    },
  });

  const handleInitialTable = useCallback(() => {
    setTableData([]);
    setTableTotalSize(0);
    setTablePagination(DEFAULT_PAGINATION);
  }, []);

  const handleChangeTable = useCallback(
    (
      _type: TableChangeType,
      newState: PaginationRequestParams<ItemSearchFilter>,
    ) => {
      const currentPagination: PaginationRequestParams<unknown>['pagination'] =
        {
          ...tablePagination,
          ...newState.pagination,
          page:
            (newState.pagination?.page as number) === 0
              ? 1
              : newState.pagination?.page,
        };

      setTablePagination(currentPagination);

      executeGetTableData({
        pagination: currentPagination,
        filters: newState.filters,
      });
    },
    [executeGetTableData, tablePagination],
  );

  return (
    <DialogOrderSearchContext.Provider
      value={{
        filters: {
          filters: currentFilters,
          ...restFiltersData,
          parseFiltersRequest,
          refStatesAutocomplete,
        },
        table: {
          data: tableData,
          loading: loadingTable,
          totalSize: tableTotalSize,
          pagination: tablePagination,
          handleChangeTable,
          handleInitialTable,
        },
      }}
    >
      {children}
    </DialogOrderSearchContext.Provider>
  );
};

export default DialogOrderSearchProvider;
