import {
  DialogTitle,
  Divider,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  DialogCustomerFeedbackActions,
  DialogCustomerFeedbackCloseIcon,
  DialogCustomerFeedbackContainer,
  DialogCustomerFeedbackContent,
} from './styles';

import {
  faComments as CommentsIcon,
  faClose as CloseIcon,
} from '@fortawesome/free-solid-svg-icons';
import { useCallback, useState } from 'react';
import { useLazyRequest } from '~app/hooks';
import { sendUserFeedback } from '~services/admin/feedback';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';

interface DialogCustomerFeedbackProps {
  open: boolean;
  onClose: () => void;
}

const DialogCustomerFeedback = ({
  open,
  onClose,
}: DialogCustomerFeedbackProps): React.ReactElement => {
  const [message, setMessage] = useState<string>();
  const { enqueueSnackbar } = useSnackbar();

  const handleOnClose = useCallback(() => {
    setMessage(undefined);
    onClose();
  }, [onClose]);

  const [, isSubmitting, , execSendUserFeedback] = useLazyRequest({
    request: sendUserFeedback,
    withPostSuccess: () => {
      enqueueSnackbar('Mensaje enviado satisfactoriamente', {
        variant: 'success',
      });
      handleOnClose();
    },
    withPostFailure: () => {
      enqueueSnackbar('Ha ocurrido un error, intente nuevamente', {
        variant: 'error',
      });
      handleOnClose();
    },
  });

  const handleChangeMessage = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setMessage(e.target.value);
    },
    [],
  );

  const handleSubmitFeedback = useCallback(() => {
    if (!message) return;
    execSendUserFeedback({ notes: message });
  }, [execSendUserFeedback, message]);

  return (
    <DialogCustomerFeedbackContainer open={open} onClose={handleOnClose}>
      <DialogTitle>
        <Stack direction="row" alignItems="center" gap={2}>
          <FontAwesomeIcon icon={CommentsIcon} />
          Ayudanos a mejorar!
        </Stack>
      </DialogTitle>
      <DialogCustomerFeedbackCloseIcon onClick={handleOnClose}>
        <FontAwesomeIcon icon={CloseIcon} size="xs" />
      </DialogCustomerFeedbackCloseIcon>
      <Divider />
      <DialogCustomerFeedbackContent>
        <Typography variant="caption" lineHeight={1.2}>
          Si tienes un comentario, idea o sugerencia para poder incorporar a
          nuestra plataforma, te invitamos a completar y enviar el siguiente
          formulario. Te agradecemos por tu tiempo.
        </Typography>
        <TextField
          multiline
          rows={12}
          fullWidth
          sx={{ mt: 2 }}
          value={message}
          onChange={handleChangeMessage}
        />
      </DialogCustomerFeedbackContent>
      <Divider />
      <DialogCustomerFeedbackActions>
        <Stack direction="row" alignItems="center" gap={0.5}>
          <Link href="http://help.routix.io/" target="_blank">
            <Typography fontSize={12} fontWeight={500}>
              Haz click aquí
            </Typography>
          </Link>
          <Typography fontSize={12} fontWeight={500}>
            para ingresar al centro de ayuda
          </Typography>
        </Stack>
        <LoadingButton
          disabled={!Boolean(message)}
          variant="contained"
          color="primary"
          onClick={handleSubmitFeedback}
          loading={isSubmitting}
        >
          Enviar
        </LoadingButton>
      </DialogCustomerFeedbackActions>
    </DialogCustomerFeedbackContainer>
  );
};

export default DialogCustomerFeedback;
