import { ReactElement, useCallback } from 'react';

import { isCarrierPartnerCompany } from '~utils/partnerCompany';

import BusinessProfileTabsForm from '~components/BusinessProfileTabsForm';
import { BusinesProfileTabsFormData } from '~components/BusinessProfileTabsForm/types';

import { useInitialSetupWizardContext } from '../../InitialSetupWizarContext';
import InitialSetupWizardStepContainer from '../../StepContainer';
import { InitialSetupWizardModalStepContent } from '../../styles';
import StepTitleWithProgress from '../../StepTitleWithProgress';
import { getProgressValue } from '../../utils';

const InitialSetupWizardBusinessProfile = (): ReactElement => {
  const {
    step: { handleNextStep },
    companyType: { setSelected: setSelectedCompanyTypes },
  } = useInitialSetupWizardContext();

  const onSuccess = useCallback(
    (data: BusinesProfileTabsFormData) => {
      setSelectedCompanyTypes(data.companyTypeId);

      handleNextStep(
        isCarrierPartnerCompany(data.companyTypeId)
          ? 'loadVehicle'
          : 'linkAssociatedCompany',
      );
    },
    [handleNextStep, setSelectedCompanyTypes],
  );

  return (
    <InitialSetupWizardStepContainer
      stepId="businessProfile"
      minHeight={550}
      maxWidth={600}
    >
      <StepTitleWithProgress
        progress={getProgressValue(true, 1)}
        title="Ajusta la interfaz de trabajo"
        // eslint-disable-next-line max-len
        description="Define el perfil de tu negocio, según tus respuestas, ajustaremos nuestra plataforma, comunicación y funcionalidades para brindarte el mejor soporte posible."
      />

      <InitialSetupWizardModalStepContent>
        <BusinessProfileTabsForm
          type="create"
          takeUserCompanyTypeId={false}
          onSuccess={onSuccess}
        />
      </InitialSetupWizardModalStepContent>
    </InitialSetupWizardStepContainer>
  );
};

export default InitialSetupWizardBusinessProfile;
