import { FC, createContext, useContext } from 'react';

import { useToggle, useToggleMenu } from '~hooks/index';

import { HeaderActions } from './types';

const HeaderActionsContext = createContext<HeaderActions>({
  dialogOrderSearch: {
    open: false,
    handleToggleOpenDialog: () => {},
  },
  menuFirstSteps: {
    anchorElRefMenu: { current: null },
    open: false,
    handleToggleOpenMenu: () => {},
  },
  menuActiveRoutes: {
    anchorElRefMenu: { current: null },
    open: false,
    handleToggleOpenMenu: () => {},
  },
  menuProfile: {
    anchorElRefMenu: { current: null },
    open: false,
    handleToggleOpenMenu: () => {},
  },
  menuNews: {
    anchorElRefMenu: { current: null },
    open: false,
    handleToggleOpenMenu: () => {},
  },
});

export const useHeaderActions = (): HeaderActions =>
  useContext(HeaderActionsContext);

const HeaderActionsProvider: FC = ({ children }) => {
  const [openDialogOrderSearch, toggleDialogOrderSearch] = useToggle();

  const [
    anchorElMenuFirstSteps,
    openMenuFirstSteps,
    ,
    handleToggleMenuFirstSteps,
  ] = useToggleMenu();

  const [
    anchorElMenuActiveRoutes,
    openMenuActiveRoutes,
    ,
    handleToggleActiveRoutes,
  ] = useToggleMenu();

  const [anchorElMenuProfile, openMenuProfile, , handleToggleMenuProfile] =
    useToggleMenu();

  const [anchorElMenuNews, openMenuNews, , handleToggleNews] = useToggleMenu();

  return (
    <HeaderActionsContext.Provider
      value={{
        dialogOrderSearch: {
          open: openDialogOrderSearch,
          handleToggleOpenDialog: toggleDialogOrderSearch,
        },
        menuFirstSteps: {
          anchorElRefMenu: anchorElMenuFirstSteps,
          open: openMenuFirstSteps,
          handleToggleOpenMenu: handleToggleMenuFirstSteps,
        },
        menuActiveRoutes: {
          anchorElRefMenu: anchorElMenuActiveRoutes,
          open: openMenuActiveRoutes,
          handleToggleOpenMenu: handleToggleActiveRoutes,
        },
        menuProfile: {
          anchorElRefMenu: anchorElMenuProfile,
          open: openMenuProfile,
          handleToggleOpenMenu: handleToggleMenuProfile,
        },
        menuNews: {
          anchorElRefMenu: anchorElMenuNews,
          open: openMenuNews,
          handleToggleOpenMenu: handleToggleNews,
        },
      }}
    >
      {children}
    </HeaderActionsContext.Provider>
  );
};

export default HeaderActionsProvider;
