import { ReactElement, useMemo, useState, useCallback } from 'react';
import {
  CircularProgress,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Alert,
  Stack,
  Typography,
  Link,
  TextField,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useFormik, FormikHelpers } from 'formik';

import { useRequest, useUtcOffset, useUpdateRouteState } from '~hooks/index';
import { getRoute } from '~services/route';
import { ApiTransactionRoutes } from '~services/apiTransaction/types';
import { Nullable } from '~globals/types';
import { RouteStateTypes } from '~globals/types/enums';
import { hasError } from '~utils/index';

import {
  DialogFinalizedRouteProps,
  DialogFinalizedRouteContentProps,
  DialogFinalizedRouteContentData,
} from './types';
import { FIELDS_NAME, initialValues, validationSchema } from './utils';
import {
  DialogFinalizedRouteContainer,
  DialogFinalizedRouteLoadingContainer,
  DialogFinalizedRouteActions,
} from './styles';

const DialogFinalizedRouteContent = ({
  onClose,
  routeId,
  routeCode,
  scheduledDateTime,
}: DialogFinalizedRouteContentProps): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const formatterDateUtcOffset = useUtcOffset();

  const title = useMemo(() => {
    const parseScheduledDateTime = formatterDateUtcOffset(
      scheduledDateTime,
      'DD/MM/YYYY HH:mm',
    );

    return `Finalizar ruta ${routeCode} (${parseScheduledDateTime})`;
  }, [formatterDateUtcOffset, routeCode, scheduledDateTime]);

  const { loading: loadingFinalizedRoute, handleUpdateRouteState } =
    useUpdateRouteState({
      showSnackBar: false,
      onSuccess: () => {
        enqueueSnackbar(`La ruta ${routeCode} fue finalizada correctamente`, {
          variant: 'success',
        });

        onClose();
      },
      onError: () => {
        enqueueSnackbar(
          `No se pude finalizar la ruta ${routeCode}, intente nuevamente`,
          { variant: 'error' },
        );

        onClose();
      },
    });

  const onSubmit = useCallback(
    async (
      values: typeof initialValues,
      { setSubmitting }: FormikHelpers<typeof initialValues>,
    ) => {
      await handleUpdateRouteState({
        routeId,
        routeStateTypeId: RouteStateTypes.Finalized,
        rejectedNote: values[FIELDS_NAME.NOTES],
      });

      setSubmitting(false);
    },
    [handleUpdateRouteState, routeId],
  );

  const {
    errors,
    touched,
    getFieldProps,
    submitForm,
    isSubmitting,
    dirty,
    isValid,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: onSubmit,
  });

  return (
    <>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent dividers>
        <Stack spacing={5}>
          <Stack spacing={1}>
            <DialogContentText color="text.primary">
              ¿Estás seguro de querer finalizar la ruta de forma manual?
            </DialogContentText>

            <TextField
              label="Motivo de finalización"
              fullWidth
              multiline
              rows={3}
              InputLabelProps={{ shrink: true }}
              autoComplete="off"
              error={hasError(touched, errors, FIELDS_NAME.NOTES)}
              {...getFieldProps(FIELDS_NAME.NOTES)}
            />
          </Stack>

          <Stack spacing={1}>
            <Alert variant="outlined" severity="warning">
              <Stack spacing={2}>
                <Typography variant="inherit" color="inherit">
                  Al finalizar manualmente una ruta, todas las paradas que no
                  posean evidencia cargada pasaran ha estado <b>SIN VISITAR</b>.
                </Typography>

                <Typography variant="inherit" color="inherit" fontWeight="bold">
                  Procede con cuidado, esta acción puede implicar el envío de
                  notificaciones a clientes.
                </Typography>
              </Stack>
            </Alert>

            <Alert
              severity="info"
              variant="standard"
              icon={false}
              sx={(currentTheme) => ({
                border: `1px solid ${currentTheme.palette.info.light}`,
              })}
            >
              Para una mejor experiencia de uso, te recomendamos utilizar la App
              de Routix para conductores.{' '}
              <Link
                href="https://routix.tawk.help/article/asignación-y-aceptación-de-rutas"
                color="primary"
                underline="hover"
                fontWeight="bold"
                target="_blank"
                rel="noopener noreferrer"
              >
                Necesito ayuda sobre esto
              </Link>
            </Alert>
          </Stack>
        </Stack>
      </DialogContent>

      <DialogFinalizedRouteActions>
        <Button color="secondary" onClick={onClose}>
          Cerrar
        </Button>

        <LoadingButton
          variant="contained"
          color="error"
          onClick={submitForm}
          loading={loadingFinalizedRoute || isSubmitting}
          disabled={!dirty || !isValid}
        >
          Finalizar ruta
        </LoadingButton>
      </DialogFinalizedRouteActions>
    </>
  );
};

const DialogFinalizedRoute = ({
  open,
  onClose,
  routeId,
}: DialogFinalizedRouteProps): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] =
    useState<Nullable<DialogFinalizedRouteContentData>>(null);

  const [, loadingGetRoute] = useRequest(
    {
      request: getRoute,
      payload: routeId,
      withPostSuccess: (response) => {
        const { routeCode, scheduledDateTime } = response.data
          ?.data as ApiTransactionRoutes;

        setData({
          routeCode: routeCode.toUpperCase(),
          scheduledDateTime,
        });
      },
      withPostFailure: () => {
        enqueueSnackbar('Ha ocurrido un error, intente nuevamente', {
          variant: 'error',
        });
      },
    },
    [],
  );

  return (
    <DialogFinalizedRouteContainer open={open} maxWidth="sm">
      {loadingGetRoute && (
        <DialogFinalizedRouteLoadingContainer>
          <CircularProgress color="primary" disableShrink size={80} />
        </DialogFinalizedRouteLoadingContainer>
      )}

      {!loadingGetRoute && data && (
        <DialogFinalizedRouteContent
          onClose={onClose}
          routeId={routeId}
          {...data}
        />
      )}
    </DialogFinalizedRouteContainer>
  );
};

export default DialogFinalizedRoute;
