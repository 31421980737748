import { ReactElement, useMemo, useState } from 'react';
import { DialogTitle, Button, CircularProgress } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { useAppSelector, useEnumerationData } from '~hooks/index';
import { ENUMS_ENPOINTS } from '~services/enumerations';
import { selectAuth } from '~store/slices/auth';
import { checkIsArg } from '~utils/country';
import { getStringifyUrlQueryString } from '~utils/router';
import { Nullable, BillingData } from '~globals/types/commons';
import { PATHS } from '~constants/paths';

import SubscriptionPaymentModalProvider, {
  SubscriptionPaymentModalContextActiveFormPanelsId,
} from './SubscriptionPaymentModalContext';
import SubscriptionPaymentModalForm from './Form';
import SubscriptionPaymentModalDetails from './Details';
import SubscriptionPaymentModalProcessAlert from './ProcessAlert';
import { SubscriptionPaymentModalProps } from './types';
import {
  SubscriptionPaymentModalRoot,
  SubscriptionPaymentModalLoadingContainer,
  SubscriptionPaymentModalContent,
  SubscriptionPaymentModalContentForm,
  SubscriptionPaymentModalActions,
} from './styles';
import { getPaymentTypeMultiplier } from './utils';

const SubscriptionPaymentModal = ({
  open,
  onClose,
  data: {
    name,
    currentPrice,
    paymentType,
    providersPlansId,
    isSinglePayment,
    isPack,
    contractPlanIdTest,
    onlyRequestPaymentButton,
    discount,
  },
}: SubscriptionPaymentModalProps): ReactElement => {
  const { user } = useAppSelector(selectAuth);

  const totalPrice = useMemo(() => {
    const totalUSD = currentPrice * getPaymentTypeMultiplier(paymentType);
    const totalARS = totalUSD * 1000; // @TODO: Change for quotation enpoint
    return { USD: totalUSD, ARS: totalARS };
  }, [currentPrice, paymentType]);

  const [countries, loadingCountries] = useEnumerationData({
    resource: ENUMS_ENPOINTS.getCountries,
    extractorKeyValue: 'countryId',
  });

  const [taxTypes, loadingTaxTypes] = useEnumerationData({
    resource: ENUMS_ENPOINTS.getTaxTypes,
    extractorKeyValue: 'taxTypeId',
  });

  const [invoiceTypes, loadingInvoceTypes] = useEnumerationData({
    resource: ENUMS_ENPOINTS.getInvoiceTypes,
    extractorKeyValue: 'invoiceTypeId',
  });

  const [billingData, setBillingData] = useState<Nullable<BillingData>>(null);

  const [activeForm, setActiveForm] =
    useState<SubscriptionPaymentModalContextActiveFormPanelsId>('billingData');

  const [isProcessPayment, setIsProcessPayment] = useState(false);

  const loading = useMemo(
    () => loadingCountries || loadingTaxTypes || loadingInvoceTypes,
    [loadingCountries, loadingTaxTypes, loadingInvoceTypes],
  );

  const title = useMemo(() => {
    const prefix = isPack ? 'paquete' : 'suscripción';

    return `Pago de ${prefix}`;
  }, [isPack]);

  return (
    <SubscriptionPaymentModalRoot open={open}>
      <SubscriptionPaymentModalProvider
        value={{
          isArg: checkIsArg(user?.company.countryId),
          paymentType,
          providersPlansId,
          isSinglePayment,
          itemData: { title: name, price: totalPrice },
          selectsData: { countries, taxTypes, invoiceTypes },
          billingData: { data: billingData, setData: setBillingData },
          form: { activeForm, setActiveForm },
          processPayment: {
            isProcess: isProcessPayment,
            setIsProcess: setIsProcessPayment,
          },
          isPack,
          contractPlanIdTest,
          onlyRequestPaymentButton,
          discount,
        }}
      >
        {loading && (
          <SubscriptionPaymentModalLoadingContainer>
            <CircularProgress color="primary" disableShrink size={80} />
          </SubscriptionPaymentModalLoadingContainer>
        )}

        {!loading && (
          <>
            <DialogTitle>{title}</DialogTitle>

            <SubscriptionPaymentModalContent dividers>
              {!isProcessPayment && (
                <SubscriptionPaymentModalContentForm>
                  <SubscriptionPaymentModalDetails />

                  <SubscriptionPaymentModalForm />
                </SubscriptionPaymentModalContentForm>
              )}

              {isProcessPayment && <SubscriptionPaymentModalProcessAlert />}
            </SubscriptionPaymentModalContent>

            <SubscriptionPaymentModalActions>
              <Button color="secondary" onClick={onClose}>
                Cerrar
              </Button>

              {isProcessPayment && (
                <Button
                  variant="contained"
                  color="primary"
                  component={RouterLink}
                  to={getStringifyUrlQueryString({
                    url: PATHS.CONFIG.SUBSCRIPTIONS.BASE,
                    query: {
                      currentTab: 'subscriptions',
                    },
                  })}
                  onClick={onClose}
                >
                  Ir a mis suscripciones
                </Button>
              )}
            </SubscriptionPaymentModalActions>
          </>
        )}
      </SubscriptionPaymentModalProvider>
    </SubscriptionPaymentModalRoot>
  );
};

export default SubscriptionPaymentModal;
