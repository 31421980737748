import API from '~config/api';
import {
  ApiPromise,
  DataResponse,
  DataPaginateResponse,
  PaginationRequestParams,
  ErrorResponse,
} from '~globals/types';

import { NewsCheckResponse, NewsData } from './types';

const NEWS_ENPOINTS = {
  checkNews: '/api/v1/Core/News/Check',
  searchNews: '/api/v1/Core/News/Search',
};

export const checkNews = (): ApiPromise<
  DataResponse<NewsCheckResponse>,
  ErrorResponse
> =>
  API.get<DataResponse<NewsCheckResponse>, ErrorResponse>(
    NEWS_ENPOINTS.checkNews,
  );

export const searchNews = ({
  pagination: { page, sizePerPage },
}: PaginationRequestParams<undefined>): ApiPromise<
  DataPaginateResponse<NewsData[]>,
  ErrorResponse
> =>
  API.post<DataPaginateResponse<NewsData[]>, ErrorResponse>(
    NEWS_ENPOINTS.searchNews,
    {
      pagination: {
        offset: page,
        size: sizePerPage,
      },
    },
  );
