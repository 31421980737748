import { ReactElement, forwardRef, useCallback, useMemo } from 'react';
import { useSnackbar, SnackbarContent } from 'notistack';
import { Typography, IconButton, Collapse } from '@mui/material';
import {
  ExpandMore as ExpanMoreIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import { useToggle, useUtcOffset } from '~hooks/index';

import { AlertSnackbarContentProps } from './types';
import { typeIconVariants } from './utils';
import {
  AlertSnackbarContentCard,
  AlertSnackbarContentCardActions,
  AlertSnackbarContentCardActionsTitle,
  AlertSnackbarContentCardActionsButtons,
  AlertSnackbarContentCardExpandIconWrapper,
  AlertSnackbarContentCollapsePaper,
  AlertSnackbarContentButtonLink,
} from './styles';

const AlertSnackbarContent = forwardRef<
  HTMLDivElement,
  AlertSnackbarContentProps
>(function AlertSnackbarContent(inProps, ref): ReactElement {
  const formatterDateUtcOffset = useUtcOffset();

  const {
    id,
    messageDateTime,
    title,
    message,
    type,
    link,
    initialExpanded,
    buttonAction,
  } = inProps;
  const { closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [expanded, toogleExpanded] = useToggle(initialExpanded);

  const onClickDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [closeSnackbar, id]);

  const IconComponent = useMemo(() => typeIconVariants[type].icon, [type]);

  const handleRedirectLink = useCallback(
    (path: string) => {
      if (buttonAction) buttonAction();
      navigate(path);

      setTimeout(() => {
        onClickDismiss();
      }, 500);
    },
    [buttonAction, navigate, onClickDismiss],
  );

  return (
    <SnackbarContent ref={ref}>
      <AlertSnackbarContentCard type={type}>
        <AlertSnackbarContentCardActions>
          <AlertSnackbarContentCardActionsTitle>
            <IconComponent style={{ fontSize: 20, marginInlineEnd: 8 }} />

            <Typography variant="inherit">{title}</Typography>
          </AlertSnackbarContentCardActionsTitle>

          <AlertSnackbarContentCardActionsButtons>
            <AlertSnackbarContentCardExpandIconWrapper
              aria-label="Show More"
              onClick={() => toogleExpanded()}
              color="inherit"
              className={classNames({ expanded })}
            >
              <ExpanMoreIcon />
            </AlertSnackbarContentCardExpandIconWrapper>

            <IconButton
              aria-label="Close"
              onClick={onClickDismiss}
              color="inherit"
            >
              <CloseIcon />
            </IconButton>
          </AlertSnackbarContentCardActionsButtons>
        </AlertSnackbarContentCardActions>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <AlertSnackbarContentCollapsePaper>
            <Typography variant="caption" component="p" fontWeight="bold">
              {message}
            </Typography>

            {messageDateTime && (
              <Typography
                variant="caption"
                component="p"
                gutterBottom
                color="text.secondary"
              >
                {formatterDateUtcOffset(messageDateTime, 'DD/MM/YYYY HH:mm')}
              </Typography>
            )}

            {link && !isEmpty(link.path) && !isEmpty(link.message) && (
              <AlertSnackbarContentButtonLink
                size="small"
                variant="outlined"
                alertType={type}
                onClick={() => handleRedirectLink(link.path)}
              >
                {link.message}
              </AlertSnackbarContentButtonLink>
            )}
          </AlertSnackbarContentCollapsePaper>
        </Collapse>
      </AlertSnackbarContentCard>
    </SnackbarContent>
  );
});

export default AlertSnackbarContent;
