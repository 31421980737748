import { ReactElement, useCallback, useMemo, useState } from 'react';
import { Stack, Card, Tooltip } from '@mui/material';
import { red } from '@mui/material/colors';
import {
  ColumnDescription,
  TableChangeHandler,
} from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarXmark as CalendarXmarkIcon } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';

import {
  DataTable,
  OrderClientInfo,
  OrderRouteInfo,
  SkillsListInfo,
  DescriptionWithIconBox,
  ExpirationDateInfo,
  CapabilitiesInfo,
  OrderPendingStateChip,
  OrderOnAgendaStateChip,
  OrderFinalizedStateChip,
  DialogFeedbackStop,
  DialogSetFeedbackStop,
  AlertSnackbarContent,
  DialogConfimation,
  DialogCancelPartnerItem,
} from '~components/index';
import NoRecordsFoundMessage from '~components/DataTable/NoRecordsFoundMessage';
import {
  useAppSelector,
  useAppDispatch,
  useUtcOffset,
  useToggle,
  useExportTickets,
  useExportTicketsMl,
  useToggleDialogConfirmation,
  useLazyRequest,
  useDownloadEvidencesItemPDF,
} from '~hooks/index';
import { selectAuth } from '~store/slices/auth/index';
import { setActiveTab } from '~app/store/slices/orders';
import { markAsPendingItems, deleteItems } from '~services/item';
import { ItemExtended } from '~services/item/types';
import { Nullable } from '~globals/types';
import {
  ItemImportStateTypes,
  NotificationAlertType,
} from '~globals/types/enums';
import { isPendingOrder, isOnAgendaOrder, isDelayedOrder } from '~utils/order';
import { isCurrentLoggedCompany } from '~utils/partnerCompany';
import { PATHS } from '~constants/paths';
import { sourceImportType } from '~constants/sourceImportType';

import { useDialogOrderSearchContext } from './Context';
import DialogOrderSearchTableActionsMenu from './TableActionsMenu';
import { ORDER_KEY_ID } from './utils';
import {
  DialogOrderSearchDataTableContainer,
  DialogOrderSearchContactInfo,
  DialogOrderSearchSourceTypeContainer,
  DialogOrderSearchSourceTypeText,
  DialogOrderSearchSourceTypeImage,
  DialogOrderSearchSourceFlexTypo,
} from './styles';

const DialogOrderSearchTable = (): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  const formatterDateUtcOffset = useUtcOffset();

  const {
    filters: { parseFiltersRequest },
    table: {
      data,
      loading: loadingSearchOrders,
      totalSize,
      pagination,
      handleChangeTable,
    },
  } = useDialogOrderSearchContext();

  const handleAlertButtonAction = useCallback(() => {
    dispatch(setActiveTab('pending'));
  }, [dispatch]);

  const handleShowSuccessAlert = useCallback(
    (title: string, message: string) => {
      enqueueSnackbar(title, {
        variant: 'success',
        content: (key, currentTitle) => (
          <AlertSnackbarContent
            id={key}
            title={currentTitle}
            message={message}
            type={NotificationAlertType.Success}
            link={{
              message: 'Ver pedidos pendientes',
              path: PATHS.ORDERS.BASE,
            }}
            buttonAction={handleAlertButtonAction}
            initialExpanded={true}
          />
        ),
      });
    },
    [enqueueSnackbar, handleAlertButtonAction],
  );

  const [selecteRouteItemFeedback, setSelecteRouteItemFeedback] =
    useState<Nullable<string>>(null);
  const [selectedIsCollectFeedback, setSelectedIsCollectFeedback] =
    useState<Nullable<boolean>>(null);

  const [openDialogFeedback, toggleOpenDialogFeedback] = useToggle();

  const handleOpenDialogFeedback = useCallback(
    (routeItemId: string, isCollect: Nullable<boolean>) => {
      setSelecteRouteItemFeedback(routeItemId);
      setSelectedIsCollectFeedback(isCollect);
      toggleOpenDialogFeedback(true);
    },
    [toggleOpenDialogFeedback],
  );

  const handleCloseDialogFeedback = useCallback(() => {
    setTimeout(() => {
      setSelecteRouteItemFeedback(null);
      setSelectedIsCollectFeedback(null);
    }, 1000);

    toggleOpenDialogFeedback(false);
  }, [toggleOpenDialogFeedback]);

  const { loading: loadingExportTicket, onExportTickets } = useExportTickets();

  const handleExportTicket = useCallback(
    async (trackingCode: string) => {
      await onExportTickets({ trackingCodes: [trackingCode] });
    },
    [onExportTickets],
  );

  const { loading: loadingExportTicketMl, onExportTicketsMl } =
    useExportTicketsMl();

  const handleExportTicketMl = useCallback(
    async (id: string) => {
      await onExportTicketsMl([id]);
    },
    [onExportTicketsMl],
  );

  const [
    openDialogFinalizedStop,
    selectedItemFinalizedStop,
    handleOpenDialogFinalizedStop,
    handleCloseDialogFinalizedStop,
  ] = useToggleDialogConfirmation<Nullable<ItemExtended>>(null);

  const [
    openDialogConfirmationMarkAsPending,
    selectedItemIdMarkAsPending,
    handleOpenDialogConfirmationMarkAsPending,
    handleCloseDialogConfirmationMarkAsPending,
  ] = useToggleDialogConfirmation<Nullable<string>>(null);

  const [, loadingMarkAsPendingOrders, , executeMarkAsPendingOrders] =
    useLazyRequest({
      request: markAsPendingItems,
      withPostSuccess: () => {
        handleCloseDialogConfirmationMarkAsPending();

        handleShowSuccessAlert(
          'Destino marcado correctamente',
          'El destino seleccionado ahora está disponible en la sección de pedidos pendientes.',
        );
      },
      withPostFailure: () => {
        enqueueSnackbar('No se pudo realizar los cambios, intente nuevamente', {
          variant: 'error',
        });
      },
    });

  const handleMarkAsPendingSelectedOrder = useCallback(async () => {
    if (selectedItemIdMarkAsPending) {
      executeMarkAsPendingOrders([selectedItemIdMarkAsPending]);
    }
  }, [selectedItemIdMarkAsPending, executeMarkAsPendingOrders]);

  const {
    loading: loadingDowloadEvidencesOrderPDF,
    onDownloadPDF: handleDowloadEvidencesOrderPDF,
  } = useDownloadEvidencesItemPDF();

  const [
    openDialogModifyEvidence,
    selectedItemModifyEvidence,
    handleOpenDialogModifyEvidence,
    handleCloseDialogModifyEvidence,
  ] = useToggleDialogConfirmation<Nullable<ItemExtended>>(null);

  const [
    openDialogCancelOrder,
    selectedCancelOrder,
    handleOpenDialogCancelOrder,
    handleCloseDialogCancelOrder,
  ] = useToggleDialogConfirmation<Nullable<ItemExtended>>(null);

  const [
    openConfirmDeleteOrder,
    selectedOrderDelete,
    handleOpenConfirmDeleteOrder,
    handleCloseConfirmDeleteOrder,
  ] = useToggleDialogConfirmation<Nullable<ItemExtended>>(null);

  const messageDialogConfimationDeleteOrder = useMemo(
    () => `El pedido ${selectedOrderDelete?.trackingCode} será eliminado`,
    [selectedOrderDelete],
  );

  const [, loadingDeleteOrder, , executeDeleteOrder] = useLazyRequest({
    request: deleteItems,
    withPostSuccess: () => {
      const trackingCode = selectedOrderDelete?.trackingCode;
      const message = `El pedido ${trackingCode} fue eliminado correctamente`;

      enqueueSnackbar(message, { variant: 'success' });

      handleCloseConfirmDeleteOrder();

      // reloadDataAndResetSelectedRows();
    },
    withPostFailure: () => {
      enqueueSnackbar('No se pudo elimnar el pedido, intente nuevamente', {
        variant: 'error',
      });
    },
  });

  const handleDeleteOrder = useCallback(() => {
    if (selectedOrderDelete)
      executeDeleteOrder([selectedOrderDelete[ORDER_KEY_ID]]);
  }, [executeDeleteOrder, selectedOrderDelete]);

  const columns = useMemo<ColumnDescription<ItemExtended>[]>(
    () => [
      {
        dataField: 'title',
        text: 'Nombre de contacto',
        classes: 'truncated-two-lines',
        formatter: (_cell, row) => {
          const hasError = isPendingOrder(row)
            ? row.itemImportStateTypeId === ItemImportStateTypes.Error
            : false;

          return (
            <OrderClientInfo
              name={row.title}
              itemTypeId={row.itemTypeId}
              trackingCode={row.trackingCode}
              hasError={hasError}
              duplicated={row.duplicated}
            />
          );
        },
        headerStyle: { width: 212 },
      },
      {
        dataField: 'address',
        text: 'Dirección',
        classes: 'truncated-two-lines',
        formatter: (cell) => <span>{cell}</span>,
      },
      {
        dataField: 'addressNotes',
        text: 'Notas de dirección',
        classes: 'truncated-two-lines',
        formatter: (cell) => (
          <Tooltip title={cell} placement="bottom-start">
            <span style={{ maxLines: 1 }}>{cell}</span>
          </Tooltip>
        ),
        hidden: true,
      },
      {
        dataField: 'referenceId',
        text: 'Nro. de Orden',
        classes: 'truncated-two-lines',
        formatter: (cell) => <span>{cell}</span>,
        hidden: true,
      },
      {
        dataField: 'tags',
        text: 'Grupo',
        classes: 'truncated-two-lines',
        formatter: (cell) => <span>{cell}</span>,
        hidden: true,
      },
      {
        dataField: 'routeCode',
        text: 'Ruta',
        classes: 'truncated-two-lines',
        formatter: (_cell, row) => {
          if (isPendingOrder(row)) return null;

          return (
            <OrderRouteInfo
              apiTransactionId={row.apiTransactionId as string}
              routeId={row.routeId as string}
              routeCode={row.routeCode}
              carrierCompanyId={row.carrierCompanyId}
              carrierCompanyName={row.carrierCompanyName}
            />
          );
        },
        headerStyle: { width: 180 },
      },
      {
        dataField: 'skillsNeeded',
        text: 'Cargas especiales',
        classes: 'truncated-two-lines',
        formatter: (cell) => <SkillsListInfo skills={cell} />,
        headerStyle: { width: 100 },
        align: 'center',
        hidden: true,
      },
      {
        dataField: 'estimatedArrivalDateTime',
        text: 'ETA',
        classes: 'truncated-two-lines',
        formatter: (_cell, row) => {
          if (isPendingOrder(row) || !row.estimatedArrivalDateTime) return null;

          const formatDate = formatterDateUtcOffset(
            row.estimatedArrivalDateTime,
            'DD/MM/YYYY HH:mm',
          );

          if (
            isOnAgendaOrder(row) &&
            isDelayedOrder(row.estimatedArrivalDateTime)
          ) {
            return (
              <DescriptionWithIconBox
                icon={<FontAwesomeIcon icon={CalendarXmarkIcon} />}
                iconTooltip="Este pedido está demorado"
                iconColor={red[500]}
                description={formatDate}
                size={14}
              />
            );
          }

          return <span>{formatDate}</span>;
        },
        headerStyle: { width: 170 },
      },
      {
        dataField: 'realArrivalDateTime',
        text: 'Entrega',
        classes: 'truncated-two-lines',
        formatter: (_cell, row) => {
          if (isPendingOrder(row) || !row.realArrivalDateTime) return null;

          const formatDate = formatterDateUtcOffset(
            row.realArrivalDateTime,
            'DD/MM/YYYY HH:mm',
          );

          return <span>{formatDate}</span>;
        },
        headerStyle: { width: 170 },
      },
      {
        dataField: 'carrierCompanyName',
        text: 'Empresa',
        classes: 'truncated-two-lines',
        formatter: (_cell, row) => {
          const companyName = isCurrentLoggedCompany(
            row.carrierCompanyId,
            user?.companyId ?? null,
          )
            ? row.giverCompanyName
            : row.carrierCompanyName;

          return <span>{companyName}</span>;
        },
        headerStyle: { width: 150 },
        hidden: true,
      },
      {
        dataField: 'collectedBy',
        text: 'Colectado Por',
        classes: 'truncated-two-lines',
        formatter: (cell) => <span>{cell}</span>,
        hidden: true,
      },
      {
        dataField: 'maxDeliveryDateTime',
        text: 'Fecha de vencimiento',
        classes: 'truncated-two-lines',
        formatter: (cell) => <ExpirationDateInfo expirationDate={cell} />,
        headerStyle: { width: 150 },
        hidden: true,
      },
      {
        dataField: 'contactName',
        text: 'Datos de contacto',
        classes: 'truncated-two-lines',
        formatter: (_cell, row) => (
          <DialogOrderSearchContactInfo>
            <span>{row.contactName}</span>
            <span>{row.contactIdentification}</span>
          </DialogOrderSearchContactInfo>
        ),
        hidden: true,
      },
      {
        dataField: 'contactEmail',
        text: 'Correo electrónico',
        classes: 'truncated-two-lines',
        formatter: (cell) => <span>{cell}</span>,
        hidden: true,
      },
      {
        dataField: 'contactPhone',
        text: 'Telefono',
        classes: 'truncated-two-lines',
        formatter: (cell) => <span>{cell}</span>,
        hidden: true,
      },
      {
        dataField: 'contactMobile',
        text: 'Celular / Whatsapp',
        classes: 'truncated-two-lines',
        formatter: (cell) => <span>{cell}</span>,
        hidden: true,
      },
      {
        dataField: 'unit1',
        text: 'Capacidades',
        classes: 'truncated-two-lines',
        formatter: (_cell, row) => (
          <CapabilitiesInfo
            capacity1={row.unit1 * row.amount}
            capacity2={row.unit2 * row.amount}
            numberOfPackages={row.numberOfPackages}
            timeWindow={{
              allDay: row.timeWindowAllDay,
              timeFrom: row.timeWindowFrom1,
              timeTo: row.timeWindowTo1,
              timeFrom2: row.timeWindowFrom2,
              timeTo2: row.timeWindowTo2,
            }}
            maximumVisits={null}
            fontSize={12}
            fontWeight="bold"
          />
        ),
        headerStyle: { width: 200 },
        hidden: true,
      },
      {
        dataField: 'customField1',
        text: 'Campo personalizado 1',
        classes: 'truncated-two-lines',
        formatter: (cell) => <span>{cell}</span>,
        hidden: true,
      },
      {
        dataField: 'customField2',
        text: 'Campo personalizado 2',
        classes: 'truncated-two-lines',
        formatter: (cell) => <span>{cell}</span>,
        hidden: true,
      },
      {
        dataField: 'customField3',
        text: 'Campo personalizado 3',
        classes: 'truncated-two-lines',
        formatter: (cell) => <span>{cell}</span>,
        hidden: true,
      },
      {
        dataField: 'quantityArticles',
        text: 'Cantidad de artículos',
        classes: 'truncated-two-lines',
        formatter: (cell) => <span>{cell}</span>,
        hidden: true,
        headerAlign: 'center',
        align: 'center',
      },
      {
        dataField: 'store',
        classes: 'truncated-two-lines',
        formatter: (cell) => <span>{cell}</span>,
        text: 'Tienda',
        hidden: true,
      },
      {
        dataField: 'zipCode',
        classes: 'truncated-two-lines',
        text: 'Código postal',
        formatter: (cell) => <span>{cell}</span>,
        hidden: true,
      },
      {
        dataField: 'sourceImportTypeId',
        text: 'Medio de importación',
        classes: 'truncated-two-lines',
        hidden: true,
        formatter: (_cell, row) => {
          const typeId =
            row.sourceImportTypeId !== null &&
            row.sourceImportTypeId < sourceImportType.length
              ? row.sourceImportTypeId
              : 1;

          return (
            <DialogOrderSearchSourceTypeContainer>
              <DialogOrderSearchSourceTypeText>
                <DialogOrderSearchSourceTypeImage
                  src={sourceImportType[typeId].image.url}
                  alt={sourceImportType[typeId].image.alt}
                />

                {typeId !== 5 && sourceImportType[typeId].name}
              </DialogOrderSearchSourceTypeText>

              {typeId === 5 && (
                <DialogOrderSearchSourceFlexTypo>
                  {sourceImportType[typeId].name}
                </DialogOrderSearchSourceFlexTypo>
              )}
            </DialogOrderSearchSourceTypeContainer>
          );
        },
      },
      {
        dataField: 'routeItemFeedbackNotes',
        text: 'Comentarios de la visita',
        classes: 'truncated-two-lines',
        formatter: (cell, row) => {
          if (isPendingOrder(row) || isOnAgendaOrder(row)) return null;

          return (
            <Tooltip title={cell} placement="bottom-start">
              <span style={{ maxLines: 1 }}>{cell}</span>
            </Tooltip>
          );
        },
        hidden: true,
      },
      {
        dataField: 'routeItemStateTypeId',
        text: 'Estado',
        classes: 'truncated-two-lines',
        formatter: (cell, row) => {
          if (isPendingOrder(row)) {
            return (
              <OrderPendingStateChip
                carrierCompanyId={row.carrierCompanyId}
                collectItemStateTypeId={row.collectItemStateTypeId}
              />
            );
          }

          if (isOnAgendaOrder(row)) {
            return <OrderOnAgendaStateChip stateId={cell} />;
          }

          return <OrderFinalizedStateChip stateId={cell} />;
        },
        headerStyle: { width: 160 },
        headerAlign: 'center',
        align: 'center',
      },
      {
        dataField: 'actions',
        isDummyField: true,
        text: '',
        classes: 'truncated-two-lines',
        formatter: (_cell, row) => (
          <DialogOrderSearchTableActionsMenu
            data={row}
            actions={{
              handleOpenDialogFeedback,
              handleExportTicket,
              handleExportTicketMl,
              handleOpenDialogFinalizedStop,
              handleOpenDialogConfirmationMarkAsPending,
              handleDowloadEvidencesOrderPDF,
              handleOpenDialogModifyEvidence,
              handleOpenDialogCancelOrder,
              handleOpenConfirmDeleteOrder,
            }}
            loadings={{
              loadingExportTicket,
              loadingExportTicketMl,
              loadingDowloadEvidencesOrderPDF,
            }}
          />
        ),
        headerStyle: { width: 60 },
      },
    ],
    [
      formatterDateUtcOffset,
      user,
      handleOpenDialogFeedback,
      handleExportTicket,
      loadingExportTicket,
      handleExportTicketMl,
      loadingExportTicketMl,
      handleOpenDialogFinalizedStop,
      handleOpenDialogConfirmationMarkAsPending,
      handleDowloadEvidencesOrderPDF,
      loadingDowloadEvidencesOrderPDF,
      handleOpenDialogModifyEvidence,
      handleOpenDialogCancelOrder,
      handleOpenConfirmDeleteOrder,
    ],
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onTableChange = useCallback<TableChangeHandler<any>>(
    (type, newState) => {
      handleChangeTable(type, {
        pagination: newState,
        filters: parseFiltersRequest,
      });
    },
    [handleChangeTable, parseFiltersRequest],
  );

  return (
    <Stack>
      <Card variant="outlined" sx={{ display: 'grid' }}>
        <DialogOrderSearchDataTableContainer>
          <DataTable
            loading={loadingSearchOrders}
            data={data}
            columns={columns}
            keyField={ORDER_KEY_ID}
            pagination={{
              options: {
                sizePerPage: pagination.sizePerPage,
                page: pagination.page,
                totalSize,
              },
            }}
            remote={{
              pagination: true,
            }}
            onTableChange={onTableChange}
            noDataIndicationComponent={() => <NoRecordsFoundMessage />}
            hideableStorageKey="Search-Global-Orders-Data-Table"
            toggleHideableColumns
            excludeHideableFieldColumns={['title', 'routeItemStateTypeId']}
          />
        </DialogOrderSearchDataTableContainer>
      </Card>

      {openDialogFeedback && selecteRouteItemFeedback && (
        <DialogFeedbackStop
          open={openDialogFeedback}
          onClose={handleCloseDialogFeedback}
          stopId={selecteRouteItemFeedback}
          enabledFeebackPanel
          isCollect={!selectedIsCollectFeedback}
        />
      )}

      {openDialogFinalizedStop && selectedItemFinalizedStop && (
        <DialogSetFeedbackStop
          open={openDialogFinalizedStop}
          onClose={handleCloseDialogFinalizedStop}
          routeItemId={selectedItemFinalizedStop.routeItemId ?? ''}
          routeId={selectedItemFinalizedStop.routeId ?? ''}
          itemId={selectedItemFinalizedStop.itemId}
        />
      )}

      <DialogConfimation
        open={openDialogConfirmationMarkAsPending}
        description="El destinos seleccionado será marcados como pendiente"
        onConfirm={handleMarkAsPendingSelectedOrder}
        onCancel={handleCloseDialogConfirmationMarkAsPending}
        loadingConfirm={loadingMarkAsPendingOrders}
      />

      {openDialogModifyEvidence && selectedItemModifyEvidence && (
        <DialogSetFeedbackStop
          open={openDialogModifyEvidence}
          onClose={handleCloseDialogModifyEvidence}
          routeItemId={selectedItemModifyEvidence.routeItemId ?? ''}
          routeId={selectedItemModifyEvidence.routeId ?? ''}
        />
      )}

      {selectedCancelOrder && (
        <DialogCancelPartnerItem
          open={openDialogCancelOrder}
          onClose={handleCloseDialogCancelOrder}
          partnerCompany={selectedCancelOrder}
          // onCancelSuccess={reloadDataAndResetSelectedRows}
        />
      )}

      <DialogConfimation
        open={openConfirmDeleteOrder}
        description={messageDialogConfimationDeleteOrder}
        onConfirm={handleDeleteOrder}
        onCancel={handleCloseConfirmDeleteOrder}
        loadingConfirm={loadingDeleteOrder}
      />
    </Stack>
  );
};

export default DialogOrderSearchTable;
