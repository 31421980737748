import { ReactElement, useState, useCallback, useMemo } from 'react';
import {
  Stack,
  TextField,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Switch,
  Collapse,
  Alert,
  Link,
} from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { ColumnDescription } from 'react-bootstrap-table-next';
import moment from 'moment';
import { isNull } from 'lodash';
import classNames from 'classnames';

import {
  DataTable,
  VehicleInfo,
  SkillsListInfo,
  CapabilitiesInfo,
  IsAvailableChip,
  OrderClientInfo,
  NewFeatureBagde,
  OrderPendingStateChip,
  Subscription,
  OrderReceiveStateChip,
  SelectWithScopeField,
} from '~components/index';
import {
  useRequest,
  usePrevious,
  useToggle,
  useAppSelector,
  useUtcOffset,
  useSubscription,
} from '~hooks/index';
import { selectAuth } from '~store/slices/auth';
import { VehicleSearchItem } from '~services/vehicle/types';
import { getWarehouses } from '~services/warehouse';
import { ItemExtended } from '~services/item/types';
import { WarehouseExtended, WarehouseHead } from '~services/warehouse/types';
import { hasError } from '~utils/index';
import { pluralize } from '~utils/commons';
import { getStringifyUrlQueryString } from '~utils/router';
import { isPlanStart } from '~utils/plans';
import { DataPaginateResponse } from '~globals/types';
import { routeCalculationModesOptions } from '~constants/routeCalculationModes';
import { DEFAULT_UUIID_EMPTY } from '~constants/commons';
import { PATHS } from '~constants/paths';

import { useDialogGenerateRoutesContext } from '../../DialogGenerateRoutesContext';
import {
  TABLE_KEY_FIELDS,
  getDisabledVehicle,
  getDisabledOrderByType,
  isRouteCollect,
} from '../../utils';
import {
  classes,
  DialogGenerateRoutesCard,
  DialogGenerateRoutesCardContent,
  DialogGenerateRoutesCardHeader,
  DialogGenerateRoutesCardHeaderText,
  DialogGenerateRoutesCardHeaderIndicators,
  DialogGenerateRoutesCardDataTableContainer,
} from '../../styles';

import { FIELDS_NAME, getInitialValues } from './utils';

const DialogGenerateRoutesFormTab = (): ReactElement => {
  const { user } = useAppSelector(selectAuth);
  const formatterDateUtcOffset = useUtcOffset();
  const subscription = useSubscription();

  const {
    touched,
    errors,
    values,
    getFieldProps,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<ReturnType<typeof getInitialValues>>();

  const {
    type,
    form: {
      vehiclesTable: {
        data: vehicles,
        loading: loadingRequestVehicles,
        totalSize: totalSizeVehicles,
        queryParams: { pagination: paginationVehicles },
        handleChangeTable: handleChangeTableVehicles,
      },
      selectedData: {
        vehicles: {
          selectedRowsIds: vehiclesId,
          selectRowsProps: selectRowsPropsVehicles,
          restarSelectedRows: restarSelectedRowsVehicles,
        },
        orders: {
          selectedRowsIds: selectedOrdesId,
          selectRowsProps: selectRowsPropsOrders,
        },
      },
      chargeIndicators: {
        vehicles: indicatorsVehiclesCharge,
        orders: indicatorsOrdersCharge,
      },
    },
    ordersInfo,
    onClose,
  } = useDialogGenerateRoutesContext();

  const [warehouses, setWarehouses] = useState<WarehouseExtended[]>([]);

  const isRouteCollectType = useMemo(() => isRouteCollect(type), [type]);

  useRequest({
    request: getWarehouses,
    payload: null,
    withPostSuccess: (response) => {
      const {
        data: { results },
        head: { lastWarehouseId },
      } = response.data as DataPaginateResponse<
        WarehouseExtended[],
        null,
        WarehouseHead
      >;

      setWarehouses(results);

      if (lastWarehouseId && lastWarehouseId !== DEFAULT_UUIID_EMPTY) {
        setFieldValue(FIELDS_NAME.STARTING_POINT, lastWarehouseId);

        if (isRouteCollectType) {
          setFieldValue(FIELDS_NAME.ARRIVAL_POINT, lastWarehouseId);
        }
      } else if (results.length > 0) {
        setFieldValue(FIELDS_NAME.STARTING_POINT, results[0].warehouseId);

        if (isRouteCollectType) {
          setFieldValue(FIELDS_NAME.STARTING_POINT, results[0].warehouseId);
        }
      }
    },
  });

  const handleChangeScheduleDate = useCallback(
    (value: Date | null): void => {
      setFieldValue(FIELDS_NAME.SCHEDULE_DATE, value);
    },
    [setFieldValue],
  );

  const [scheduleDateVal, setScheduleDateVal] = useState(
    values[FIELDS_NAME.SCHEDULE_DATE],
  );
  const prevSchedulaDateVal = usePrevious(scheduleDateVal);

  const handleChangeTableByScheduleDate = useCallback(
    (dateVal: Date | null) => {
      setScheduleDateVal(dateVal);
      const isValidCurrentDate = moment(dateVal).isValid();

      const isEqualDates =
        isNull(prevSchedulaDateVal) && isNull(dateVal)
          ? true
          : moment(prevSchedulaDateVal).isSame(dateVal);

      if (isValidCurrentDate && !isEqualDates) {
        restarSelectedRowsVehicles();

        handleChangeTableVehicles('filter', {
          pagination: {
            page: 1,
            sizePerPage: 25,
          },
          filters: {
            enabled: null,
            query: null,
            scheduleDateTime: formatterDateUtcOffset(
              dateVal,
              moment.HTML5_FMT.DATETIME_LOCAL_MS,
              true,
            ),
          },
        });
      }
    },
    [
      prevSchedulaDateVal,
      restarSelectedRowsVehicles,
      handleChangeTableVehicles,
      formatterDateUtcOffset,
    ],
  );

  const handleAcceptScheduleDate = useCallback(
    (value: Date | null) => handleChangeTableByScheduleDate(value),
    [handleChangeTableByScheduleDate],
  );

  const handleBlurScheduleDate = useCallback((): void => {
    setFieldTouched(FIELDS_NAME.SCHEDULE_DATE, true);
    const newScheduleDateVal = values[FIELDS_NAME.SCHEDULE_DATE];

    handleChangeTableByScheduleDate(newScheduleDateVal);
  }, [setFieldTouched, values, handleChangeTableByScheduleDate]);

  const [expandedOrdes, toogleExpandedOrders] = useToggle(false);
  const [expandedVehicles, toogleExpandedVehicles] = useToggle(true);

  const getSelectedEntity = useCallback(
    (selecteds: Array<number | string>, entity: string) => {
      const countSelecteds = selecteds.length;
      const entityPluralize = pluralize({
        singular: entity,
        count: countSelecteds,
        includeCount: true,
      });

      const selectedText = pluralize({
        singular: 'seleccionado',
        count: countSelecteds,
        includeCount: false,
      });

      return `(${entityPluralize} ${selectedText})`;
    },
    [],
  );

  const columnsVehicles = useMemo<ColumnDescription<VehicleSearchItem>[]>(
    () => [
      {
        dataField: 'name',
        text: 'Vehículo',
        formatter: (cell, row) => (
          <VehicleInfo
            vehicle={cell}
            vehicleCode={row.referenceCode ?? ''}
            color={row.color}
            fontWeight="bold"
            useCaption
          />
        ),
      },
      {
        dataField: 'skillsList',
        text: 'Cargas esp.',
        formatter: (cell) => <SkillsListInfo skills={cell} />,
        headerStyle: { width: 200 },
        headerAlign: 'center',
        align: 'center',
      },
      {
        dataField: 'capacity1',
        text: 'Capacidades',
        formatter: (_cell, row) => (
          <CapabilitiesInfo
            capacity1={row.capacity1}
            capacity2={row.capacity2}
            maximumVisits={row.maximumVisits}
            timeWindow={{ timeFrom: row.timeFrom, timeTo: row.timeTo }}
            fontSize={12}
            fontWeight="bold"
          />
        ),
        headerStyle: { width: 200 },
      },
      {
        dataField: 'isAvailable',
        text: 'Estado',
        formatter: (_cell, row) => (
          <IsAvailableChip
            isAvailable={row.isAvailable}
            isOpenForWork={row.isOpenForWork}
            size="medium"
            variant="filled"
          />
        ),
        headerStyle: { width: 200 },
        headerAlign: 'center',
        align: 'center',
      },
    ],
    [],
  );

  const columnsOrders = useMemo<ColumnDescription<ItemExtended>[]>(
    () => [
      {
        dataField: 'title',
        text: 'Nombre de contacto',
        headerStyle: { width: 180 },
        formatter: (_cell, row) => (
          <OrderClientInfo
            name={row.title}
            itemTypeId={row.itemTypeId}
            trackingCode={row.trackingCode}
          />
        ),
      },
      {
        dataField: 'tags',
        text: 'Grupo',
        classes: 'truncated-two-lines',
        formatter: (cell) => <span>{cell}</span>,
        headerStyle: { width: 180 },
      },
      {
        dataField: 'skillsNeeded',
        text: 'Cargas especiales',
        classes: 'truncated-two-lines',
        formatter: (cell) => <SkillsListInfo skills={cell} />,
        headerStyle: { width: 100 },
        align: 'center',
      },
      {
        dataField: 'collectItemStateTypeId',
        isDummyField: true,
        text: 'Estado',
        classes: 'truncated-two-lines',
        formatter: (_cell, row) => {
          if (isRouteCollectType) {
            return (
              <OrderReceiveStateChip
                collectItemStateTypeId={row.collectItemStateTypeId}
              />
            );
          }

          return (
            <OrderPendingStateChip
              carrierCompanyId={row.carrierCompanyId}
              collectItemStateTypeId={row.collectItemStateTypeId}
            />
          );
        },
        headerStyle: { width: 160 },
        headerAlign: 'center',
        align: 'center',
      },
      {
        dataField: 'unit1',
        text: 'Capacidades',
        formatter: (_cell, row) => (
          <CapabilitiesInfo
            capacity1={row.unit1 * row.amount}
            capacity2={row.unit2 * row.amount}
            numberOfPackages={row.numberOfPackages}
            maximumVisits={null}
            fontSize={12}
            fontWeight="bold"
          />
        ),
        headerStyle: { width: 120 },
      },
    ],
    [isRouteCollectType],
  );

  const getDisabledVehicleClasses = useCallback(
    (row: VehicleSearchItem): string =>
      classNames({ 'table-row-disabled': getDisabledVehicle(row) }),
    [],
  );

  const getDisabledOrderClasses = useCallback(
    (row: ItemExtended): string =>
      classNames({
        'table-row-disabled': getDisabledOrderByType(type, row, user),
      }),
    [type, user],
  );

  const arrivalPointLabel = useMemo(() => {
    let label = 'Punto de llegada';

    if (!isRouteCollectType) {
      label += ' (opcional)';
    }

    return label;
  }, [isRouteCollectType]);

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2}>
        <TextField
          label="Nombre de plan (opcional)"
          fullWidth
          autoComplete="off"
          error={hasError(touched, errors, FIELDS_NAME.NAME)}
          {...getFieldProps(FIELDS_NAME.NAME)}
        />

        <Subscription.DataRetentionMin.DateTimePicker
          label="Fecha de agenda"
          value={values[FIELDS_NAME.SCHEDULE_DATE]}
          onChange={handleChangeScheduleDate}
          onAccept={handleAcceptScheduleDate}
          renderInput={(props) => (
            <TextField
              {...props}
              fullWidth
              sx={{ width: '50%' }}
              onBlur={handleBlurScheduleDate}
              error={hasError(touched, errors, FIELDS_NAME.SCHEDULE_DATE)}
            />
          )}
        />
      </Stack>

      <Stack direction="row" spacing={2}>
        <TextField
          label="Punto de partida"
          fullWidth
          autoComplete="off"
          error={hasError(touched, errors, FIELDS_NAME.STARTING_POINT)}
          {...getFieldProps(FIELDS_NAME.STARTING_POINT)}
          select
        >
          {warehouses.map((warehouse) => (
            <MenuItem
              key={`starting-point-${warehouse.warehouseId}`}
              value={warehouse.warehouseId}
            >
              {warehouse.title}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          label={arrivalPointLabel}
          fullWidth
          autoComplete="off"
          error={hasError(touched, errors, FIELDS_NAME.ARRIVAL_POINT)}
          {...getFieldProps(FIELDS_NAME.ARRIVAL_POINT)}
          select
        >
          {!isRouteCollectType && <MenuItem value="">Sin especificar</MenuItem>}

          {warehouses.map((warehouse) => (
            <MenuItem
              key={`arrival-point-${warehouse.warehouseId}`}
              value={warehouse.warehouseId}
            >
              {warehouse.title}
            </MenuItem>
          ))}
        </TextField>

        <SelectWithScopeField
          label="Modo de Generación"
          options={routeCalculationModesOptions}
          fullWidth
          autoComplete="off"
          error={hasError(touched, errors, FIELDS_NAME.CALCULATION_MODE)}
          {...getFieldProps(FIELDS_NAME.CALCULATION_MODE)}
        />
      </Stack>

      <Stack>
        <DialogGenerateRoutesCard variant="outlined">
          <DialogGenerateRoutesCardContent>
            <DialogGenerateRoutesCardHeader
              onClick={() => toogleExpandedOrders()}
              className={classNames({
                [classes.expanded]: expandedOrdes,
              })}
              disableRipple
              disableTouchRipple
            >
              <DialogGenerateRoutesCardHeaderText
                primary="PEDIDOS A PROGRAMAR"
                secondary={getSelectedEntity(selectedOrdesId, 'pedido')}
              />

              <DialogGenerateRoutesCardHeaderIndicators
                capacity1={indicatorsOrdersCharge.capacity1}
                capacity2={indicatorsOrdersCharge.capacity2}
                numberOfPackages={indicatorsOrdersCharge.numberOfPackages}
                maximumVisits={null}
                fontSize={14}
                fontWeight="bold"
                autoFlow="row"
                rows={1}
                columns={3}
              />

              <KeyboardArrowDown className={classes.expandIcon} />
            </DialogGenerateRoutesCardHeader>

            <Collapse
              in={expandedOrdes}
              timeout="auto"
              unmountOnExit
              sx={{ height: '100%' }}
            >
              <DialogGenerateRoutesCardDataTableContainer>
                <DataTable
                  loading={false}
                  data={ordersInfo}
                  columns={columnsOrders}
                  keyField={TABLE_KEY_FIELDS.ORDER}
                  selectRow={selectRowsPropsOrders}
                  rowClasses={getDisabledOrderClasses}
                  condensed
                />
              </DialogGenerateRoutesCardDataTableContainer>
            </Collapse>
          </DialogGenerateRoutesCardContent>
        </DialogGenerateRoutesCard>
      </Stack>

      <Stack>
        <DialogGenerateRoutesCard variant="outlined">
          <DialogGenerateRoutesCardContent>
            <DialogGenerateRoutesCardHeader
              onClick={() => toogleExpandedVehicles()}
              className={classNames({
                [classes.expanded]: expandedVehicles,
              })}
              disableRipple
              disableTouchRipple
            >
              <DialogGenerateRoutesCardHeaderText
                primary="VEHÍCULOS A PROGRAMAR"
                secondary={getSelectedEntity(vehiclesId, 'vehículo')}
              />

              <DialogGenerateRoutesCardHeaderIndicators
                capacity1={indicatorsVehiclesCharge.capacity1}
                capacity2={indicatorsVehiclesCharge.capacity2}
                maximumVisits={null}
                fontSize={14}
                fontWeight="bold"
                autoFlow="row"
                rows={1}
                columns={2}
              />

              <KeyboardArrowDown className={classes.expandIcon} />
            </DialogGenerateRoutesCardHeader>

            <Collapse
              in={expandedVehicles}
              timeout="auto"
              unmountOnExit
              sx={{ height: '100%' }}
            >
              <DialogGenerateRoutesCardDataTableContainer>
                <DataTable
                  loading={loadingRequestVehicles}
                  data={vehicles}
                  columns={columnsVehicles}
                  keyField={TABLE_KEY_FIELDS.VEHICLE}
                  pagination={{
                    options: {
                      sizePerPage: paginationVehicles.sizePerPage,
                      page: paginationVehicles.page,
                      totalSize: totalSizeVehicles,
                      showTotal: false,
                      hideSizePerPage: true,
                    },
                  }}
                  remote={{
                    pagination: true,
                  }}
                  onTableChange={(tableType, newState) =>
                    handleChangeTableVehicles(tableType, {
                      pagination: newState,
                    })
                  }
                  selectRow={selectRowsPropsVehicles}
                  rowClasses={getDisabledVehicleClasses}
                  condensed
                />
              </DialogGenerateRoutesCardDataTableContainer>
            </Collapse>
          </DialogGenerateRoutesCardContent>
        </DialogGenerateRoutesCard>
      </Stack>

      {isPlanStart(subscription) && (
        <Alert
          severity="info"
          variant="standard"
          icon={false}
          sx={(currentTheme) => ({
            border: `1px solid ${currentTheme.palette.info.light}`,
          })}
        >
          ¿Necesitas programar varios vehículos a la vez?.{' '}
          <Link
            color="primary"
            underline="hover"
            fontWeight="bold"
            component={RouterLink}
            to={getStringifyUrlQueryString({
              url: PATHS.CONFIG.SUBSCRIPTIONS.BASE,
              query: {
                currentTab: 'plans-packages',
              },
            })}
            onClick={onClose}
          >
            ¡Prueba nuestros planes superiores y simplifica el ruteo de toda tu
            flota!
          </Link>
        </Alert>
      )}

      <Stack direction="row">
        <FormGroup row sx={{ alignItems: 'center' }}>
          <FormControlLabel
            control={
              <Switch
                {...getFieldProps(FIELDS_NAME.LOAD_BALACING_VEHICLES)}
                checked={values[FIELDS_NAME.LOAD_BALACING_VEHICLES]}
              />
            }
            label="Distribuir paradas entre todos los vehículos seleccionados."
            sx={{ margin: 0 }}
          />

          <NewFeatureBagde activeForVersion="1.13.0" />
        </FormGroup>
      </Stack>

      <Stack direction="row">
        <FormGroup row sx={{ alignItems: 'center' }}>
          <FormControlLabel
            control={
              <Switch
                {...getFieldProps(FIELDS_NAME.MULTIPLE_ROUTES_PER_VEHICLE)}
                checked={values[FIELDS_NAME.MULTIPLE_ROUTES_PER_VEHICLE]}
              />
            }
            label="Permitir generar más de una ruta por vehículo."
            sx={{ margin: 0 }}
          />

          <NewFeatureBagde activeForVersion="1.13.0" />
        </FormGroup>
      </Stack>
    </Stack>
  );
};

export default DialogGenerateRoutesFormTab;
