import { chain } from 'lodash';

import { Roles, PlanFeaturesTypes } from '~globals/types/enums';

import { AccessFeatureCommonProps } from '../AccessFeature/types';

interface RoleInfo {
  roleId: Roles;
  label: string;
  scopeDescription: string;
  validSubscriptionFeature?: AccessFeatureCommonProps;
}

export const ROLES_FIELD_INFO: RoleInfo[] = [
  {
    roleId: Roles.Admin,
    label: 'Administrador',
    scopeDescription:
      // eslint-disable-next-line max-len
      'Nivel de permiso más alto. Puede administrar la configuración y preferencias de la cuenta. Tiene acceso a reportes y estadísticas y maneja la gestión de vehículos, depositos, usuarios, choferes.',
  },
  {
    roleId: Roles.Operator,
    label: 'Operador',
    scopeDescription:
      // eslint-disable-next-line max-len
      'Puede crear/editar/eliminar pedidos, rutas y contactos frecuentes. No tiene permisos para cambiar la configuración de la cuenta.',
  },
  {
    roleId: Roles.CreatorOrder,
    label: 'Creador de pedidos',
    scopeDescription:
      // eslint-disable-next-line max-len
      'Solo puede dar de alta pedidos. No tiene acceso a ningún otro módulo del sistema.',
    validSubscriptionFeature: {
      validFeature: PlanFeaturesTypes.LimitPaid,
    },
  },
  {
    roleId: Roles.Reader,
    label: 'Lector',
    scopeDescription:
      // eslint-disable-next-line max-len
      'Puede ver todos los pedidos, rutas y contactos frecuentes, incluidos todos sus datos, pero no puede crear/editar/eliminar nada.',
  },
];

export const getRoleFieldLabel = (roleId: Roles): string =>
  chain(ROLES_FIELD_INFO).find({ roleId }).get('label', '').value();
