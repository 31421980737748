import { ReactElement, useMemo } from 'react';
import { useTheme, Fade } from '@mui/material';

import { toAbsoluteUrl } from '~utils/assetsHelpers';

import { ProToPlusBannerProps } from './types';
import {
  ProToPlusBannerRoot,
  ProToPlusBannerBackDrop,
  ProToPlusBannerContent,
  ProToPlusBannerIconButton,
  ProToPlusBannerButton,
  ProToPlusBannerImg,
} from './styles';

const ProToPlusBanner = ({
  open,
  onClose,
}: ProToPlusBannerProps): ReactElement => {
  const theme = useTheme();

  const transitionDuration = useMemo(
    () => ({
      enter: theme.transitions.duration.enteringScreen,
      exit: theme.transitions.duration.leavingScreen,
    }),
    [theme],
  );

  return (
    <ProToPlusBannerRoot
      open={open}
      onClose={onClose}
      closeAfterTransition
      disableEscapeKeyDown={false}
      BackdropComponent={ProToPlusBannerBackDrop}
      BackdropProps={{ transitionDuration }}
    >
      <Fade
        appear
        in={open}
        timeout={transitionDuration}
        role="presentation"
        unmountOnExit
      >
        <ProToPlusBannerContent>
          <ProToPlusBannerIconButton onClick={onClose}>
            X
          </ProToPlusBannerIconButton>

          <ProToPlusBannerButton onClick={onClose}>
            ¡A DISFURTAR!
          </ProToPlusBannerButton>

          <ProToPlusBannerImg
            src={toAbsoluteUrl('/images/upgrade-free-plan-plus.png')}
            alt="Upgrade Free Plan Plus"
          />
        </ProToPlusBannerContent>
      </Fade>
    </ProToPlusBannerRoot>
  );
};

export default ProToPlusBanner;
