import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrdersTabsState, OrdersPanelsId } from './types';
import { RootState } from '~app/store/types';

const initialState: OrdersTabsState = {
  activeTab: 'pending',
};

const orderTabsSlice = createSlice({
  name: 'orderTabs',
  initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<OrdersPanelsId>) => {
      state.activeTab = action.payload;
    },
  },
});

export const { setActiveTab } = orderTabsSlice.actions;

export const selectOrdersTabs = (state: RootState): OrdersTabsState =>
  state.orders;

export default orderTabsSlice.reducer;
