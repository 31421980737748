import { styled, Box, Typography } from '@mui/material';

import { classes as classesDescriptionWithIconBox } from '../DescriptionWithIconBox/styles';

const fontSize = 10;

export const OrderClientInfoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.75),
}));

export const OrderClientInfoTitle = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  lineHeight: 'normal',
});

export const OrderClientInfoOperationContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: theme.spacing(0.75),

  [`& .${classesDescriptionWithIconBox.container}`]: {
    color: theme.palette.text.secondary,
    fontSize,
    fontWeight: theme.typography.fontWeightBold,

    [`& .${classesDescriptionWithIconBox.iconContainer} svg`]: {
      fontSize: fontSize + 2,
    },
  },
}));

export const OrderClientInfoDuplicatedChip = styled('div')(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: theme.typography.pxToRem(8),
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.error.main,
  border: `1px solid ${theme.palette.error.main}`,
  padding: theme.spacing(0, 0.25),
  borderRadius: 14 / 2,
  height: 14,
  width: 34,
}));
