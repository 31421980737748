import { ReactElement, useCallback, useMemo } from 'react';
import {
  Stack,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from '@mui/material';
import { useFormikContext } from 'formik';

import { ORDER_CENTRALIZATION_SHIPMENT_OPTIONS } from '~constants/businessProfiles';

import { useBusinessProfileTabsFormContext } from '../../BusinesProfileTabsFormContext';
import { FIELDS_NAMES, initialValues } from '../../utils';

const BusinessProfileOrderCentralizationShipment = (): ReactElement => {
  const { handleNextTab, handleGoBack } = useBusinessProfileTabsFormContext();

  const { values, getFieldProps, setFieldValue } =
    useFormikContext<typeof initialValues>();

  const orderCentralizationShipmentValue = useMemo(
    () => values[FIELDS_NAMES.ORDER_CENTRALIZATION_SHIPMENT],
    [values],
  );

  const onGoBack = useCallback(() => {
    setFieldValue(FIELDS_NAMES.ORDER_CENTRALIZATION_SHIPMENT, '');
    handleGoBack('orderCentralizationShipment');
  }, [handleGoBack, setFieldValue]);

  const onNextTab = useCallback(() => {
    handleNextTab('descriptionConfirm');
  }, [handleNextTab]);

  const isDisabledNextButton = useMemo(
    () => !orderCentralizationShipmentValue,
    [orderCentralizationShipmentValue],
  );

  return (
    <Stack spacing={2} flex={1} justifyContent="space-between">
      <Stack spacing={1}>
        <Typography variant="subtitle1" fontWeight="bold">
          Respecto a la centralización de las órdenes:
        </Typography>

        <Typography variant="subtitle2">
          ¿A dónde se envían las órdenes que se encuentran en su almacén?
        </Typography>

        <RadioGroup
          {...getFieldProps(FIELDS_NAMES.ORDER_CENTRALIZATION_SHIPMENT)}
        >
          {ORDER_CENTRALIZATION_SHIPMENT_OPTIONS.map((option) => (
            <FormControlLabel
              key={`order-centralization-shipment-${option.value}`}
              value={option.value}
              control={<Radio />}
              label={option.label}
            />
          ))}
        </RadioGroup>
      </Stack>

      <Stack direction="row" spacing={2} justifyContent="flex-end">
        <Button variant="outlined" color="secondary" onClick={onGoBack}>
          Regresar
        </Button>

        <Button
          variant="outlined"
          color="primary"
          onClick={onNextTab}
          disabled={isDisabledNextButton}
        >
          Continuar
        </Button>
      </Stack>
    </Stack>
  );
};

export default BusinessProfileOrderCentralizationShipment;
