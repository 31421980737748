import { Tooltip } from '@mui/material';

import {
  Home as HomeIcon,
  Vehicle as RoutesIcon,
  Order as OrdersIcon,
  Report as ReportIcon,
  Settings as SettingsIcon,
  Help as HelpIcon,
  Received as ReceivedIcon,
} from '~components/Icons';
import { Subscription } from '~components/index';
import { PATHS } from '~constants/index';
import { Roles, PartnerCompanyTypes } from '~globals/types/enums';

import { LateralMenuItem } from './types';

export const MENU_PRINCIPAL: LateralMenuItem[] = [
  {
    data: {
      title: 'Escritorio',
      path: PATHS.HOME.BASE,
      icon: HomeIcon,
    },
    tooltipText:
      'Visualiza el día a día de tu empresa, con estadísticas en tiempo real.',
    permissions: {
      roles: [Roles.Admin, Roles.Operator, Roles.Reader],
      companyType: [PartnerCompanyTypes.Carrier, PartnerCompanyTypes.Giver],
      validateCrossCompanyEnabled: false,
    },
  },
  {
    data: {
      title: 'Colecta',
      path: PATHS.RECEIVED.BASE,
      icon: ReceivedIcon,
      chips: {
        activeVersion: '1.16.0',
      },
    },
    tooltipText:
      'Gestiona los pedidos que te envían tus vendedores o dadores de carga.',
    permissions: {
      roles: [Roles.Admin, Roles.Operator, Roles.Reader],
      companyType: [PartnerCompanyTypes.Carrier],
      validateCrossCompanyEnabled: true,
    },
  },
  {
    data: {
      title: 'Pedidos',
      path: PATHS.ORDERS.BASE,
      icon: OrdersIcon,
    },
    tooltipText:
      'Aquí podrás gestionar tus pedidos, generar rutas y hacer seguimiento de visitas.',
    permissions: {
      roles: [Roles.Admin, Roles.Operator, Roles.Reader],
      companyType: [PartnerCompanyTypes.Carrier, PartnerCompanyTypes.Giver],
      validateCrossCompanyEnabled: false,
    },
  },
  {
    data: {
      title: 'Rutas',
      path: PATHS.ROUTES.BASE,
      icon: (props) => (
        <Subscription.AvailableRoutesState.Badge {...props}>
          <RoutesIcon />
        </Subscription.AvailableRoutesState.Badge>
      ),
    },
    tooltipText:
      'Haz el seguimiento de todas tus rutas, recorridos y unidades en viaje desde un solo lugar.',
    permissions: {
      roles: [Roles.Admin, Roles.Operator, Roles.Reader],
      companyType: [PartnerCompanyTypes.Carrier],
      validateCrossCompanyEnabled: false,
    },
    subItem: () => (
      <Tooltip
        title="Estas son tus rutas disponibles para este ciclo."
        arrow
        placement="right"
      >
        <span>
          <Subscription.AvailableRoutesState.Info />
        </span>
      </Tooltip>
    ),
  },
];

export const MENU_SECONDARIES: LateralMenuItem[] = [
  {
    data: {
      title: 'Reportes',
      path: PATHS.REPORTS.BASE,
      icon: ReportIcon,
    },
    tooltipText: 'Obtén métricas personalizadas del desempeño de tu empresa.',
    permissions: {
      roles: [Roles.Admin],
      companyType: [PartnerCompanyTypes.Carrier, PartnerCompanyTypes.Giver],
      validateCrossCompanyEnabled: false,
    },
  },
  {
    data: {
      title: 'Configuración',
      path: PATHS.CONFIG.BASE,
      icon: SettingsIcon,
    },
    tooltipText:
      'Gestiona preferencias de uso, entidades y personaliza la plataforma a tu medida.',
    permissions: {
      roles: [Roles.Admin],
      companyType: [PartnerCompanyTypes.Carrier, PartnerCompanyTypes.Giver],
      validateCrossCompanyEnabled: false,
    },
  },
  {
    data: {
      title: 'Ayuda',
      path: 'http://help.routix.io/',
      target: '_blank',
      icon: HelpIcon,
    },
    tooltipText:
      'Recorre el centro de ayuda online para obtener más información de la herramienta.',
    permissions: {
      roles: [Roles.Admin, Roles.Operator, Roles.Reader, Roles.CreatorOrder],
      companyType: [PartnerCompanyTypes.Carrier, PartnerCompanyTypes.Giver],
      validateCrossCompanyEnabled: false,
    },
  },
];
