import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

import { useLazyRequest } from '~hooks/index';
import { exportTicketsMl } from '~services/item';
import { formatterDate } from '~utils/formatter';
import { downloadFileByRequest } from '~utils/download';

interface ExportTicketsParams {
  onSuccess?: () => void;
  onError?: () => void;
}

interface ExportTicketsReturn {
  loading: boolean;
  onExportTicketsMl: (params: string[]) => Promise<void>;
}

export const useExportTicketsMl = (
  options?: ExportTicketsParams,
): ExportTicketsReturn => {
  const { enqueueSnackbar } = useSnackbar();

  const [, loadingExportTickets, , executeExportTicketsMl] = useLazyRequest({
    request: exportTicketsMl,
    withPostSuccess: (response) => {
      if (response.headers && response.data) {
        const currentDate = formatterDate(new Date(), { format: 'YYYYMMDD' });

        const defaultFileName = `etiquetas-${currentDate}.pdf`;

        options?.onSuccess?.();

        downloadFileByRequest(response.headers, response.data, defaultFileName);

        enqueueSnackbar(`Se han descargado las etiquetas`, {
          variant: 'success',
        });
      } else {
        enqueueSnackbar(
          'No se pudo descargar las etiquetas, intente nuevamente',
          { variant: 'error' },
        );
      }
    },
    withPostFailure: () => {
      options?.onError?.();

      enqueueSnackbar(
        'No se pudo descargar las etiquetas, intente nuevamente',
        { variant: 'error' },
      );
    },
  });

  const onExportTicketsMl = useCallback(
    async (params: string[]) => {
      await executeExportTicketsMl(params);
    },
    [executeExportTicketsMl],
  );

  return { loading: loadingExportTickets, onExportTicketsMl };
};
