import API from '~config/api';
import { ApiPromise, ErrorResponse } from '~globals/types';
import { SendMailUserFeedbackRequest } from './types';

export const COMPANY_FEEDBACK = {
  sendUserFeedbackUrl: 'api/v1/Account/SendMailUserFeedback',
};

export const sendUserFeedback = (
  body: SendMailUserFeedbackRequest,
): ApiPromise<unknown, ErrorResponse> =>
  API.post(COMPANY_FEEDBACK.sendUserFeedbackUrl, body);
