import { ReactElement, useMemo, useState, useCallback } from 'react';
import {
  CircularProgress,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Alert,
  Stack,
  Typography,
  Link,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';

import { useRequest, useUtcOffset, useUpdateRouteState } from '~hooks/index';
import { getRoute } from '~services/route';
import { ApiTransactionRoutes } from '~services/apiTransaction/types';
import { Nullable } from '~globals/types';
import { RouteStateTypes } from '~globals/types/enums';

import {
  DialogStartRouteProps,
  DialogStartRouteContentProps,
  DialogStartRouteContentData,
} from './types';
import {
  DialogStartRouteContainer,
  DialogStartRouteLoadingContainer,
  DialogStartRouteActions,
} from './styles';

const DialogStartRouteContent = ({
  onClose,
  routeId,
  routeCode,
  scheduledDateTime,
}: DialogStartRouteContentProps): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const formatterDateUtcOffset = useUtcOffset();

  const title = useMemo(() => {
    const parseScheduledDateTime = formatterDateUtcOffset(
      scheduledDateTime,
      'DD/MM/YYYY HH:mm',
    );

    return `Iniciar ruta ${routeCode} (${parseScheduledDateTime})`;
  }, [formatterDateUtcOffset, routeCode, scheduledDateTime]);

  const { loading: loadingStartRoute, handleUpdateRouteState } =
    useUpdateRouteState({
      showSnackBar: false,
      onSuccess: () => {
        enqueueSnackbar(`La ruta ${routeCode} fue iniciada correctamente`, {
          variant: 'success',
        });

        onClose();
      },
      onError: () => {
        enqueueSnackbar(
          `No se pude iniciar la ruta ${routeCode}, intente nuevamente`,
          { variant: 'error' },
        );

        onClose();
      },
    });

  const handleStartRoute = useCallback(() => {
    handleUpdateRouteState({
      routeId,
      routeStateTypeId: RouteStateTypes.InProgress,
      rejectedNote: null,
    });
  }, [handleUpdateRouteState, routeId]);

  return (
    <>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent dividers>
        <Stack spacing={1}>
          <DialogContentText color="text.primary">
            ¿Estás seguro de querer iniciar la ruta de forma manual?
          </DialogContentText>

          <Stack spacing={1}>
            <Alert variant="outlined" severity="warning">
              <Stack spacing={2}>
                <Typography variant="inherit" color="inherit">
                  Al iniciar manualmente una ruta todas sus paradas pasarán ha
                  estado <b>EN PROCESO</b>.
                </Typography>

                <Typography variant="inherit" color="inherit" fontWeight="bold">
                  Procede con cuidado, esta acción puede implicar el envío de
                  notificaciones a clientes.
                </Typography>
              </Stack>
            </Alert>

            <Alert
              severity="info"
              variant="standard"
              icon={false}
              sx={(currentTheme) => ({
                border: `1px solid ${currentTheme.palette.info.light}`,
              })}
            >
              Para una mejor experiencia de uso, te recomendamos utilizar la App
              de Routix para conductores.{' '}
              <Link
                href="https://routix.tawk.help/article/asignación-y-aceptación-de-rutas"
                color="primary"
                underline="hover"
                fontWeight="bold"
                target="_blank"
                rel="noopener noreferrer"
              >
                Necesito ayuda sobre esto
              </Link>
            </Alert>
          </Stack>
        </Stack>
      </DialogContent>

      <DialogStartRouteActions>
        <Button color="secondary" onClick={onClose}>
          Cerrar
        </Button>

        <LoadingButton
          variant="contained"
          color="primary"
          loading={loadingStartRoute}
          onClick={handleStartRoute}
        >
          Iniciar ruta
        </LoadingButton>
      </DialogStartRouteActions>
    </>
  );
};

const DialogStartRoute = ({
  open,
  onClose,
  routeId,
}: DialogStartRouteProps): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState<Nullable<DialogStartRouteContentData>>(null);

  const [, loadingGetRoute] = useRequest(
    {
      request: getRoute,
      payload: routeId,
      withPostSuccess: (response) => {
        const { routeCode, scheduledDateTime } = response.data
          ?.data as ApiTransactionRoutes;

        setData({
          routeCode: routeCode.toUpperCase(),
          scheduledDateTime,
        });
      },
      withPostFailure: () => {
        enqueueSnackbar('Ha ocurrido un error, intente nuevamente', {
          variant: 'error',
        });
      },
    },
    [],
  );

  return (
    <DialogStartRouteContainer open={open} maxWidth="sm">
      {loadingGetRoute && (
        <DialogStartRouteLoadingContainer>
          <CircularProgress color="primary" disableShrink size={80} />
        </DialogStartRouteLoadingContainer>
      )}

      {!loadingGetRoute && data && (
        <DialogStartRouteContent
          onClose={onClose}
          routeId={routeId}
          {...data}
        />
      )}
    </DialogStartRouteContainer>
  );
};

export default DialogStartRoute;
