import {
  styled,
  Dialog,
  dialogClasses,
  DialogContent,
  DialogActions,
  Button,
  Chip,
  chipClasses,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const DialogOrderSearchContainer = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    maxWidth: 1200,
    width: '100%',
    minHeight: 740,
  },
});

export const DialogOrderSearchTitle = styled('div')(({ theme }) => ({
  flex: 'none',
  padding: theme.spacing(2, 3),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const DialogOrderSearchContent = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  paddingBottom: theme.spacing(4),
}));

export const DialogOrderSearchFiltersInputs = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  justifyContent: 'flex-end',
  flexWrap: 'wrap',
  flex: 1,
}));

export const DialogOrderSearchDataTableContainer = styled('div')({
  overflowY: 'auto',
  maxHeight: 450,
  minHeight: 450,

  '& .data-table-container': {
    display: 'flex',
    flexDirection: 'column',

    '& ._loading_overlay_wrapper': {
      height: 'auto',
      flex: 1,
    },
  },
});

export const DialogOrderSearchActions = styled(DialogActions)({
  justifyContent: 'space-between',
});

export const DialogOrderSearchIconButton = styled(Button)(({ theme }) => ({
  height: 45,
  minWidth: 0,
  padding: theme.spacing(0, 1.5),
}));

export const DialogOrderSearchIconButtonIcon = styled(FontAwesomeIcon)(
  ({ theme }) => ({
    fontSize: theme.typography.pxToRem(17),
  }),
);

export const DialogOrderSearchContactInfo = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const DialogOrderSearchSourceTypeContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 4,
});

export const DialogOrderSearchSourceTypeText = styled('span')({
  fontSize: 12,
  color: grey['400'],
});

export const DialogOrderSearchSourceTypeImage = styled('img')({
  width: 26,
  height: 19,
  marginRight: 4,
});

export const DialogOrderSearchSourceFlexTypo = styled('span')({
  fontSize: 14,
  color: '#00a650',
  fontStyle: 'italic',
  fontWeight: 'bold',
});

export const DialogOrderSearchFilterChip = styled(Chip)(({ theme }) => ({
  [`& .${chipClasses.label}`]: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: 80,
  },
  [`& .${chipClasses.icon}`]: {
    fontSize: theme.typography.pxToRem(14),
  },
}));
