import { styled, Typography, Link as MuiLink } from '@mui/material';

import { LateralMenuLogoLink } from '../styles';

export const LateralMenuListItemRoot = styled('li')({
  padding: '6px 5px',
});

export const LateralMenuListItemLink = styled(LateralMenuLogoLink)(
  ({ theme }) => ({
    alignItems: 'center',
    marginTop: 0,
    color: theme.palette.tertiary.main,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
    transition: theme.transitions.create('color', {
      duration: theme.transitions.duration.enteringScreen,
    }),

    '&.linkActive': {
      color: theme.palette.common.white,
    },

    '&:hover:not(.linkActive)': {
      color: theme.palette.secondary.light,
    },
  }),
);

export const LateralMenuListItemLinkTarget = styled(MuiLink)(({ theme }) => ({
  alignItems: 'center',
  color: theme.palette.tertiary.main,
  display: 'flex',
  fontSize: theme.typography.pxToRem(14),
  fontWeight: theme.typography.fontWeightMedium,

  '&:hover:not(.linkActive)': {
    color: theme.palette.secondary.light,
  },
}));

export const LateralMenuListItemLinkClick = styled('div')(({ theme }) => ({
  alignItems: 'center',
  color: theme.palette.tertiary.main,
  display: 'flex',
  fontSize: theme.typography.pxToRem(14),
  fontWeight: theme.typography.fontWeightMedium,

  '&:hover:not(.linkActive)': {
    color: theme.palette.secondary.light,
    cursor: 'pointer',
  },
}));

export const LateralMenuListItemIconText = styled(Typography)(({ theme }) => ({
  opacity: 0,
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.enteringScreen,
  }),
  marginLeft: theme.spacing(2),
}));

export const LateralMenuListSubItemContainer = styled('div')(({ theme }) => ({
  opacity: 0,
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.enteringScreen,
  }),
  paddingLeft: theme.spacing(5),
  paddingTop: theme.spacing(0.75),
}));
