import { Children, ReactElement } from 'react';
import { PluggableList } from 'unified';
import { Components } from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import rehypeRaw from 'rehype-raw';
import rehypeUnwrapImages from 'rehype-unwrap-images';
import rehypeExternalLinks from 'rehype-external-links';
import {
  Box,
  Link,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import {
  Remove as RemoveIcon,
  Check as ChekIcon,
  Close as CloseIcon,
} from '@mui/icons-material';

export const REMARK_PLUGINS: PluggableList = [remarkGfm, remarkBreaks];

export const REHYPE_PLUGINS: PluggableList = [
  rehypeRaw,
  rehypeUnwrapImages,
  [rehypeExternalLinks, { rel: ['noopener', 'noreferrer'], target: '_blank' }],
];

export const CUSTOM_COMPONENTS: Components = {
  img: ({ ref, node, ...props }) => (
    <Box {...props} component="img" sx={{ width: '100%', height: 200 }} />
  ),
  a: ({ ref, node, ...props }) => (
    <Link
      {...props}
      color="primary"
      variant="body2"
      fontWeight="bold"
      underline="hover"
    />
  ),
  p: ({ ref, node, ...props }) => (
    <Typography {...props} component="p" variant="body2" />
  ),
  strong: ({ ref, node, ...props }) => (
    <Typography {...props} component="b" variant="body2" fontWeight="bold" />
  ),
  ul: ({ ref, node, ...props }) => <List {...props} disablePadding dense />,
  ol: ({ ref, node, ...props }) => <List {...props} disablePadding dense />,
  li: ({ ref, node, children, ...props }) => {
    let childrenOutput = Children.toArray(children);
    let Icon = RemoveIcon;
    let colorIcon = '#5618b6';

    const checkInput = childrenOutput.find(
      // @ts-expect-error hide this
      (child) => child.type === 'input',
    ) as ReactElement | null;

    if (checkInput) {
      // @ts-expect-error hide this
      childrenOutput = childrenOutput.filter((child) => child.type !== 'input');
      Icon = checkInput.props.checked ? ChekIcon : CloseIcon;
      colorIcon = '#5618b6';
    }

    return (
      <ListItem
        {...props}
        disablePadding
        disableGutters
        dense
        sx={{ alignItems: 'flex-start', gap: 0.5 }}
      >
        <ListItemIcon sx={{ minWidth: 'auto', marginTop: '2px' }}>
          <Icon
            sx={(currenTheme) => ({
              fontSize: currenTheme.typography.pxToRem(17),
              color: currenTheme.palette.primary.main,
            })}
            htmlColor={colorIcon}
          />
        </ListItemIcon>

        <ListItemText sx={{ margin: 0 }} primary={childrenOutput} />
      </ListItem>
    );
  },
};
