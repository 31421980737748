import moment from 'moment';

import { AuthUserSubscription } from '~services/auth/types';
import { PartnerCompanyTypes } from '~globals/types/enums';
import { Nullable } from '~globals/types';
import { PLANS_PRICE_DATA } from '~constants/plansData';
import { SubscriptionPaymentModalData } from '~components/Subscription/PaymentModal/types';

import { isCarrierPartnerCompany } from './partnerCompany';
import { isPlanPro } from './plans';

const AVAILABLE_TO_DATE = moment('2025-01-01').toDate();

export const isAvailableToDateProToPlus = (): boolean => {
  const now = moment().startOf('day');

  return now.isSameOrBefore(AVAILABLE_TO_DATE, 'day');
};

const EXCLUDE_COMPANIES_IDS_TO_UPGRADE = [
  'f08a3a23-1ae8-42c2-8d59-3a0c7addc944',
  '8e6ef67b-f5c7-4633-a853-3e38020f0014',
];

export const isAvailableProToPlusBanner = (
  user: Nullable<AuthUserSubscription>,
): boolean => {
  if (!user) return false;

  const isCarrier = isCarrierPartnerCompany(
    user.company.companyTypeId as PartnerCompanyTypes,
  );

  const hasProPlan = isPlanPro(user.suscription);

  const isAvailableDate = isAvailableToDateProToPlus();

  return isCarrier && hasProPlan && isAvailableDate;
};

export const isAvailableCompanyUpgradeToProToPlus = (
  companyId: string,
): boolean => !EXCLUDE_COMPANIES_IDS_TO_UPGRADE.includes(companyId);

export const isAvailableProToPlusUpgradePopup = (
  user: Nullable<AuthUserSubscription>,
): boolean => {
  if (!user) return false;

  const isAvailableToUpgrade = isAvailableCompanyUpgradeToProToPlus(
    user.companyId as string,
  );

  return isAvailableProToPlusBanner(user) && isAvailableToUpgrade;
};

export const getProToPlusUpdgradeData = ():
  | SubscriptionPaymentModalData
  | undefined => {
  const currentDataPlanPlus = PLANS_PRICE_DATA.find(
    (plan) => plan.name === 'PLUS',
  );

  if (!currentDataPlanPlus) return undefined;

  return {
    name: `PLAN ${currentDataPlanPlus.name}`,
    currentPrice: currentDataPlanPlus.currentPrice?.monthly ?? 119,
    discount: 50,
    paymentType: 'monthly',
    providersPlansId: currentDataPlanPlus.providersPlansId,
    isSinglePayment: false,
    isPack: false,
    onlyRequestPaymentButton: true,
  };
};
