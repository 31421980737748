import { ReactElement, useMemo } from 'react';
import {
  styled,
  List,
  Collapse,
  Stack,
  Typography,
  Button,
} from '@mui/material';
import {
  KeyboardArrowDown,
  DoneAll as SelectAllIcon,
  DeleteForever as TrashIcon,
} from '@mui/icons-material';
import classNames from 'classnames';

import { useToggle } from '~hooks/index';

import {
  classes,
  LayersControlContainer,
  LayersControlListItemButton,
  LayersControlListItemText,
} from './LayersControl';

interface SelectionOrdersControlProps {
  orders: { capacities: { primary: number; secondary: number } }[];
  totalOrdersCount: number;
  onSelectAll: () => void;
  onClear: () => void;
}

const SelectionOrdersControlContainer = styled(LayersControlContainer)({
  right: 'auto',
  left: 0,
  width: 200,
});

const SelectionOrdersControl = ({
  orders,
  totalOrdersCount,
  onSelectAll,
  onClear,
}: SelectionOrdersControlProps): ReactElement => {
  const [expanded, toogleExpanded] = useToggle(true);

  const total = useMemo(
    () =>
      orders.reduce(
        (acc, order) => {
          acc.primary += order.capacities.primary;
          acc.secondary += order.capacities.secondary;

          return acc;
        },
        { primary: 0, secondary: 0 },
      ),
    [orders],
  );

  return (
    <SelectionOrdersControlContainer>
      <List disablePadding>
        <LayersControlListItemButton
          onClick={() => toogleExpanded()}
          className={classNames({ [classes.expanded]: expanded })}
        >
          <LayersControlListItemText
            primary="Detalles de selección"
            primaryTypographyProps={{
              variant: 'caption',
              fontWeight: 'bold',
            }}
          />

          <KeyboardArrowDown className={classes.expandIcon} />
        </LayersControlListItemButton>

        <Collapse in={expanded} timeout="auto">
          <Stack spacing={1} padding={1}>
            <Stack>
              <Typography variant="caption">
                Pedidos seleccionados: <b>{orders.length}</b>
              </Typography>

              <Typography variant="caption">
                Carga primaria: <b>{total.primary}</b>
              </Typography>

              <Typography variant="caption">
                Carga secundaria: <b>{total.secondary}</b>
              </Typography>
            </Stack>

            <Button
              variant="text"
              color="primary"
              onClick={onSelectAll}
              disabled={orders.length >= totalOrdersCount}
              startIcon={<SelectAllIcon />}
              size="small"
            >
              Seleccionar todo
            </Button>

            <Button
              variant="text"
              color="error"
              onClick={onClear}
              disabled={!orders.length}
              startIcon={<TrashIcon />}
              size="small"
            >
              Limpiar selección
            </Button>
          </Stack>
        </Collapse>
      </List>
    </SelectionOrdersControlContainer>
  );
};

export default SelectionOrdersControl;
