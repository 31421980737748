import { ReactElement, useCallback, useMemo } from 'react';
import {
  Stack,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
} from '@mui/material';
import { useFormikContext } from 'formik';

import { ORDER_DISTRIBUTION_SHIPMENT_OPTIONS } from '~constants/businessProfiles';

import { useBusinessProfileTabsFormContext } from '../../BusinesProfileTabsFormContext';
import { FIELDS_NAMES, initialValues } from '../../utils';

const BusinessProfileOrderDistributionShipment = (): ReactElement => {
  const { handleNextTab, handleGoBack } = useBusinessProfileTabsFormContext();

  const { values, getFieldProps, setFieldValue } =
    useFormikContext<typeof initialValues>();

  const orderDistributionShipmentValue = useMemo(
    () => values[FIELDS_NAMES.ORDER_DISTRIBUTION_SHIPMENT],
    [values],
  );

  const onGoBack = useCallback(() => {
    setFieldValue(FIELDS_NAMES.ORDER_DISTRIBUTION_SHIPMENT, []);
    handleGoBack('orderDistributionShipment');
  }, [handleGoBack, setFieldValue]);

  const onNextTab = useCallback(() => {
    handleNextTab('descriptionConfirm');
  }, [handleNextTab]);

  const isDisabledNextButton = useMemo(
    () => !orderDistributionShipmentValue.length,
    [orderDistributionShipmentValue],
  );

  return (
    <Stack spacing={2} flex={1} justifyContent="space-between">
      <Stack spacing={1}>
        <Typography variant="subtitle1" fontWeight="bold">
          Respecto a la distribución de órdenes:
        </Typography>

        <Typography variant="subtitle2">¿A quién envía sus órdenes?</Typography>

        <FormGroup>
          {ORDER_DISTRIBUTION_SHIPMENT_OPTIONS.map((option) => (
            <FormControlLabel
              key={`order-distribution-shipment-${option.value}`}
              control={
                <Checkbox
                  {...getFieldProps({
                    name: FIELDS_NAMES.ORDER_DISTRIBUTION_SHIPMENT,
                    type: 'checkbox',
                    value: option.value,
                  })}
                />
              }
              label={option.label}
            />
          ))}
        </FormGroup>
      </Stack>

      <Stack direction="row" spacing={2} justifyContent="flex-end">
        <Button variant="outlined" color="secondary" onClick={onGoBack}>
          Regresar
        </Button>

        <Button
          variant="outlined"
          color="primary"
          onClick={onNextTab}
          disabled={isDisabledNextButton}
        >
          Continuar
        </Button>
      </Stack>
    </Stack>
  );
};

export default BusinessProfileOrderDistributionShipment;
