import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { RootState } from '../../types';
import { HomeState } from './types';

const initialState: HomeState = {
  skipInitialConfig: false,
  checkNewsDate: null,
};

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setSkipInitialConfig: (state) => {
      state.skipInitialConfig = !state.skipInitialConfig;
    },
    setCheckNewsDate: (state, action: PayloadAction<string>) => {
      state.checkNewsDate = action.payload;
    },
    resetHome: () => initialState,
  },
});

export const { setSkipInitialConfig, resetHome, setCheckNewsDate } =
  homeSlice.actions;

const persistHomeConfig: PersistConfig<HomeState> = {
  key: 'routix-home',
  storage,
};

const persitHomeReducer = persistReducer(persistHomeConfig, homeSlice.reducer);

export const selectHome = (state: RootState): HomeState => state.home;

export default persitHomeReducer;
