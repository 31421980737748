import { FC, createContext, useContext } from 'react';

import { useToggle } from '~hooks/index';

interface PopupsContextValue {
  open: boolean;
  toggleOpen: (nextValue?: boolean) => void;
}

const PopupsContext = createContext<PopupsContextValue>({
  open: false,
  toggleOpen: () => {},
});

export const usePopupsContext = (): PopupsContextValue =>
  useContext(PopupsContext);

const PopupsProvider: FC = ({ children }) => {
  const [open, toggleOpen] = useToggle(false);

  return (
    <PopupsContext.Provider value={{ open, toggleOpen }}>
      {children}
    </PopupsContext.Provider>
  );
};

export default PopupsProvider;
