import { create } from 'apisauce';

import { camelCaseSerializer } from '~utils/serializer';
import store from '~store/index';
import { resetAuth } from '~store/slices/auth';
import { resetHome } from '~store/slices/home';

export const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const API = create({
  baseURL: BASE_URL,
  // timeout: 15000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

API.addRequestTransform((config) => {
  if (config.data && !(config.data instanceof FormData))
    config.data = camelCaseSerializer.serialize(config.data);

  // if (config.params)
  //   config.params = camelCaseSerializer.serialize(config.params);

  const { auth } = store.getState();

  if (auth?.token) {
    config.headers.Authorization = `Bearer ${auth.token}`;
  }
});

API.addResponseTransform((response) => {
  if (response.data && !(response.data instanceof Blob))
    response.data = camelCaseSerializer.serialize(response.data);

  if (response.status === 401 && !response.config?.url?.includes('auth')) {
    store.dispatch(resetAuth());
    store.dispatch(resetHome());
  }
});

export default API;
