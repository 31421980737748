import { ReactElement } from 'react';

import OperationInfo from '../OperationInfo';
import DescriptionWithErrorTooltip from '../DescriptionWithErrorTooltip';
import {
  classes,
  DescriptionWithIconBoxText,
} from '../DescriptionWithIconBox/styles';

import { OrderClientInfoProps } from './types';
import {
  OrderClientInfoContainer,
  OrderClientInfoDuplicatedChip,
  // OrderClientInfoTitle,
  OrderClientInfoOperationContainer,
} from './styles';

const OrderClientInfo = ({
  name,
  trackingCode,
  itemTypeId,
  hasError,
  duplicated,
}: OrderClientInfoProps): ReactElement => {
  return (
    <OrderClientInfoContainer>
      {hasError && (
        <DescriptionWithErrorTooltip
          description={name}
          errorMessage="Este pedido presenta errores en la carga"
        />
      )}

      {!hasError && (
        <DescriptionWithIconBoxText
          variant="body2"
          className={classes.descriptionText}
        >
          {name}
        </DescriptionWithIconBoxText>
      )}

      {itemTypeId === undefined && (
        <DescriptionWithIconBoxText
          className={classes.descriptionText}
          sx={{ fontSize: 10 }}
          fontWeight="bold"
          color="text.secondary"
        >
          {trackingCode}
        </DescriptionWithIconBoxText>
      )}

      {itemTypeId?.toString() && (
        <OrderClientInfoOperationContainer>
          <OperationInfo
            typeId={itemTypeId}
            description={trackingCode}
            iconPosition="right"
          />
          {duplicated && (
            <OrderClientInfoDuplicatedChip>
              <p>COPIA</p>
            </OrderClientInfoDuplicatedChip>
          )}
        </OrderClientInfoOperationContainer>
      )}
    </OrderClientInfoContainer>
  );
};

export default OrderClientInfo;
