import ProToPlusBanner from './Banner';
import ProToPlusDiscountChip from './DiscountChip';
import ProToPlusDiscountImg from './DiscountImg';
import ProToPlusPopup from './Popup';

const ProToPlusComponents = {
  Banner: ProToPlusBanner,
  DiscountChip: ProToPlusDiscountChip,
  DiscountImg: ProToPlusDiscountImg,
  Popup: ProToPlusPopup,
};

export default ProToPlusComponents;
