import { ReactElement, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { getPlanGeneralInfo } from '~utils/plans';
import { selectAuth } from '~store/slices/auth/index';
import { isAdminUser } from '~utils/user';
import { useAppSelector } from '~hooks/index';
import { getStringifyUrlQueryString } from '~utils/router';
import { PATHS } from '~constants/paths';
import { useHeaderActions } from '~contexts/HeaderActionsContext';

import { NewsMenuPlanInfoChipRoot } from './styles';

interface NewsMenuPlanInfoChipProps {
  name: string;
}

const NewsMenuPlanInfoChip = ({
  name,
}: NewsMenuPlanInfoChipProps): ReactElement => {
  const navigate = useNavigate();

  const { user } = useAppSelector(selectAuth);

  const { menuNews } = useHeaderActions();

  const color = useMemo(() => {
    const currentPlanInfo = getPlanGeneralInfo(name);

    return currentPlanInfo?.highlightColor ?? '#00b0ff';
  }, [name]);

  const onClick = useMemo(() => {
    if (!isAdminUser(user)) return undefined;

    const url = getStringifyUrlQueryString({
      url: PATHS.CONFIG.SUBSCRIPTIONS.BASE,
      query: {
        currentTab: 'plans-packages',
      },
    });

    return () => {
      navigate(url);

      menuNews.handleToggleOpenMenu(false);
    };
  }, [menuNews, navigate, user]);

  return (
    <NewsMenuPlanInfoChipRoot
      label={name}
      variant="filled"
      customColor={color}
      onClick={onClick}
    />
  );
};

export default NewsMenuPlanInfoChip;
