import {
  styled,
  Modal,
  Backdrop,
  Box,
  IconButton,
  Button,
} from '@mui/material';

export const ProToPlusBannerRoot = styled(Modal)({
  '@media print': {
    position: 'absolute',
  },
});

export const ProToPlusBannerBackDrop = styled(Backdrop)({
  zIndex: -1,
  background: 'rgba(60, 16, 127, 0.5)',
  backdropFilter: 'blur(8px)',
});

export const ProToPlusBannerContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 'auto',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: theme.shadows[24],
  height: '100%',
  maxHeight: 'calc(100% - 64px)',
  outline: 'none',
  width: '100%',
  maxWidth: 725,

  '@media print': {
    height: 'auto',
  },
}));

export const ProToPlusBannerIconButton = styled(IconButton)({
  position: 'absolute',
  right: 14,
  width: 30,
  height: 30,
  top: 5,
  color: 'transparent',
});

export const ProToPlusBannerButton = styled(Button)({
  position: 'absolute',
  bottom: 53,
  left: 'calc(50% - 1px)',
  right: '50%',
  transform: 'translateX(-50%)',
  width: 296,
  height: 70,
  borderRadius: '20px',
  borderWidth: 5,
  fontWeight: 'bold',
  color: 'transparent',
  borderColor: 'transparent',

  '&:hover': {
    backgroundColor: 'rgba(60, 16, 127, 0.5)',
    color: 'transparent',
    borderColor: 'transparent',
  },
});

export const ProToPlusBannerImg = styled('img')({
  width: '100%',
  height: '100%',
});
