import { ReactElement, useCallback } from 'react';

import { toAbsoluteUrl } from '~utils/assetsHelpers';
import { useSubscriptionPaymentModalContext } from '~contexts/SubscriptionPaymentModalContext';
import { getProToPlusUpdgradeData } from '~utils/proToPlus';

import { ProToPlusDiscountImgProps } from './types';
import {
  ProToPlusDiscountImgButton,
  ProToPlusDiscountImgImage,
} from './styles';

const ProToPlusDiscountImg = ({
  onClick,
}: ProToPlusDiscountImgProps): ReactElement => {
  const { handleOpen: handleOpenSubscriptionPaymentModal } =
    useSubscriptionPaymentModalContext();

  const handleClick = useCallback(() => {
    const currentDataPlanPlus = getProToPlusUpdgradeData();

    if (currentDataPlanPlus) {
      handleOpenSubscriptionPaymentModal(currentDataPlanPlus);
    }

    onClick?.();
  }, [handleOpenSubscriptionPaymentModal, onClick]);

  return (
    <ProToPlusDiscountImgButton onClick={handleClick}>
      <ProToPlusDiscountImgImage
        src={toAbsoluteUrl('/images/discount-plan-plus.png')}
        alt="Discount Plan Plus"
      />
    </ProToPlusDiscountImgButton>
  );
};

export default ProToPlusDiscountImg;
