import { ReactElement, MouseEvent, useMemo, useCallback } from 'react';
import {
  Button,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import {
  MoreVert as MoreIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBarcode as BardCodeIcon,
  faClone as DuplicateIcon,
} from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';

import {
  useToggleMenu,
  useExportTickets,
  useExportTicketsMl,
  useSubscriptionFeatures,
  useToggle,
  useLazyRequest,
} from '~hooks/index';
import { useAccessFeatureModalContext } from '~contexts/AccessFeatureModalContext';
import { deleteItems, duplicateItems } from '~services/item';
import {
  NewFeatureBagde,
  AccessFeature,
  DialogOrdersMultipeEdition,
  DialogConfimation,
} from '~components/index';
import { AccessFeatureCommonProps } from '~components/AccessFeature/types';
import { PlanFeaturesTypes } from '~globals/types/enums';
import { getFeatureAvailableToDate } from '~utils/feature';

import {
  ActionOrderMenuProps,
  ActionOrderMenuOptionItem,
  ActionOrderMenuOptionItemOnClick,
} from './types';
import ActionsOrderMenuIconWithLoading from './IconWithLoading';
import { ActionOrderMenuContainerMenu } from './styles';

const ActionOrderMenu = ({
  enabledOptions = {
    exportTickets: true,
    multipleEdition: false,
    multipleDelete: false,
    duplicate: false,
  },
  selectedIds,
  reloadOrders,
}: ActionOrderMenuProps): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const [anchorElMenu, openMenu, , handleToggleMenu] = useToggleMenu();

  const handleOpenCurrentDialog = useCallback(
    (toggleOpenCurrentDialog: (nextValue?: boolean | undefined) => void) => {
      handleToggleMenu(false);

      setTimeout(() => {
        toggleOpenCurrentDialog(true);
      }, 300);
    },
    [handleToggleMenu],
  );
  const { handleOpen: handleOpenAccesFeatureModal } =
    useAccessFeatureModalContext();
  const { hasAccessToFeature } = useSubscriptionFeatures();

  const hasValidFeature = useCallback(
    (validSubscriptionFeature: AccessFeatureCommonProps) =>
      hasAccessToFeature(
        validSubscriptionFeature.validFeature,
        validSubscriptionFeature.availableToDate,
      ),
    [hasAccessToFeature],
  );

  const handleValidateFeature = useCallback(
    (
        callback: ActionOrderMenuOptionItemOnClick,
        validSubscriptionFeature?: AccessFeatureCommonProps,
      ) =>
      async (event: MouseEvent<HTMLLIElement>) => {
        event.preventDefault();

        if (
          validSubscriptionFeature &&
          !hasValidFeature(validSubscriptionFeature)
        ) {
          handleToggleMenu(false);
          handleOpenAccesFeatureModal({
            validFeature: validSubscriptionFeature.validFeature,
            validateAvailableRoutesState: false,
          });
          return;
        }

        await callback();
      },
    [handleOpenAccesFeatureModal, handleToggleMenu, hasValidFeature],
  );

  const isEmptySelectedIds = useMemo(
    () => selectedIds.length === 0,
    [selectedIds.length],
  );

  const { loading: loadingExportTickets, onExportTickets } = useExportTickets({
    onSuccess: () => handleToggleMenu(false),
  });

  const handleExportTickets = useCallback(async () => {
    if (!isEmptySelectedIds) {
      await onExportTickets({
        itemIds: selectedIds,
      });
    }
  }, [isEmptySelectedIds, selectedIds, onExportTickets]);

  const { loading: loadingExportTicketsMl, onExportTicketsMl } =
    useExportTicketsMl({
      onSuccess: () => handleToggleMenu(false),
    });

  const handleExportTicketsMl = useCallback(async () => {
    if (!isEmptySelectedIds) {
      await onExportTicketsMl(selectedIds);
    }
  }, [isEmptySelectedIds, onExportTicketsMl, selectedIds]);

  const [openDialogMultipleEdition, toggleDialogMultipleEdition] = useToggle();

  const [openConfirmDeleteOrders, toggleOpenConfirmDeleteOrders] = useToggle();

  const [openConfirmDuplicateOrders, toggleOpenConfirmDuplicateOrders] =
    useToggle();

  const actions = useMemo<ActionOrderMenuOptionItem[]>(() => {
    const items: ActionOrderMenuOptionItem[] = [
      {
        label: 'Exportar etiquetas',
        Icon: <FontAwesomeIcon icon={BardCodeIcon} />,
        loading: loadingExportTickets,
        enabled: Boolean(enabledOptions.exportTickets),
        disabled: isEmptySelectedIds,
        onClick: handleExportTickets,
        validSubscriptionFeature: {
          validFeature: PlanFeaturesTypes.MgmGenerateTickets,
          availableToDate: getFeatureAvailableToDate(
            PlanFeaturesTypes.MgmGenerateTickets,
          ),
        },
      },
      {
        label: 'Exportar etiquetas de Mercado Libre',
        Icon: <FontAwesomeIcon icon={BardCodeIcon} />,
        loading: loadingExportTicketsMl,
        enabled: Boolean(enabledOptions.exportTickets),
        disabled: isEmptySelectedIds,
        onClick: handleExportTicketsMl,
        validSubscriptionFeature: {
          validFeature: PlanFeaturesTypes.MgmGenerateTickets,
          availableToDate: getFeatureAvailableToDate(
            PlanFeaturesTypes.MgmGenerateTickets,
          ),
        },
      },
      {
        label: 'Edición múltiple',
        Icon: <EditIcon color="success" />,
        enabled: Boolean(enabledOptions.multipleEdition),
        disabled: isEmptySelectedIds,
        onClick: () => handleOpenCurrentDialog(toggleDialogMultipleEdition),
        validSubscriptionFeature: {
          validFeature: PlanFeaturesTypes.LimitNotPro,
        },
        newActiveForVersion: '1.18.6',
      },
      {
        label: 'Repetir destinos',
        Icon: <FontAwesomeIcon icon={DuplicateIcon} />,
        enabled: Boolean(enabledOptions.duplicate),
        disabled: isEmptySelectedIds,
        onClick: () =>
          handleOpenCurrentDialog(toggleOpenConfirmDuplicateOrders),
      },
      {
        label: 'Eliminar pedidos',
        Icon: <DeleteIcon color="error" />,
        enabled: Boolean(enabledOptions.multipleDelete),
        disabled: isEmptySelectedIds,
        onClick: () => handleOpenCurrentDialog(toggleOpenConfirmDeleteOrders),
      },
    ];

    return items.filter((item) => item.enabled);
  }, [
    enabledOptions,
    loadingExportTickets,
    isEmptySelectedIds,
    handleExportTickets,
    handleOpenCurrentDialog,
    toggleDialogMultipleEdition,
    toggleOpenConfirmDeleteOrders,
    toggleOpenConfirmDuplicateOrders,
    handleExportTicketsMl,
    loadingExportTicketsMl,
  ]);

  const [, loadingDeleteOrders, , executeDeleteOrders] = useLazyRequest({
    request: deleteItems,
    withPostSuccess: () => {
      enqueueSnackbar('Pedidos eliminados correctamente', {
        variant: 'success',
      });

      toggleOpenConfirmDeleteOrders(false);

      reloadOrders();
    },
    withPostFailure: () => {
      enqueueSnackbar('No se pudo elimnar los pedidos, intente nuevamente', {
        variant: 'error',
      });
    },
  });

  const handleDeleteOrders = useCallback(async () => {
    if (!isEmptySelectedIds) {
      await executeDeleteOrders(selectedIds);
    }
  }, [executeDeleteOrders, isEmptySelectedIds, selectedIds]);

  const [, loadingDuplicateOrders, , executeDuplicateOrders] = useLazyRequest({
    request: duplicateItems,
    withPostSuccess: () => {
      enqueueSnackbar('Destinos repetidos correctamente', {
        variant: 'success',
      });

      toggleOpenConfirmDuplicateOrders();

      reloadOrders();
    },
    withPostFailure: () => {
      enqueueSnackbar('No se pudo repetir los destinos, intente nuevamente', {
        variant: 'error',
      });
    },
  });

  const handleDuplicateOrders = useCallback(() => {
    if (!isEmptySelectedIds) {
      executeDuplicateOrders(selectedIds);
    }
  }, [executeDuplicateOrders, isEmptySelectedIds, selectedIds]);

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        startIcon={<MoreIcon />}
        endIcon={<KeyboardArrowDownIcon />}
        ref={anchorElMenu}
        aria-controls={openMenu ? 'actions-orders-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? 'true' : undefined}
        onClick={() => handleToggleMenu(true)}
      >
        Acciones
      </Button>

      <ActionOrderMenuContainerMenu
        id="actions-orders-menu"
        anchorEl={anchorElMenu.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        keepMounted
        open={openMenu}
        onClose={() => handleToggleMenu(false)}
        elevation={0}
      >
        {actions.map((actionItem, actionIndex) => (
          <div key={`menu-actions-order-item-${actionItem.label}`}>
            <MenuItem
              onClick={handleValidateFeature(
                actionItem.onClick,
                actionItem.validSubscriptionFeature,
              )}
              disabled={
                Boolean(actionItem.disabled) || Boolean(actionItem.loading)
              }
            >
              <ListItemIcon>
                <ActionsOrderMenuIconWithLoading
                  loading={Boolean(actionItem.loading)}
                >
                  {actionItem.Icon}
                </ActionsOrderMenuIconWithLoading>
              </ListItemIcon>

              <ListItemText primary={actionItem.label} />

              {actionItem.newActiveForVersion && (
                <NewFeatureBagde
                  activeForVersion={actionItem.newActiveForVersion}
                />
              )}

              {actionItem.validSubscriptionFeature && (
                <AccessFeature.NextPlanChip
                  validFeature={
                    actionItem.validSubscriptionFeature.validFeature
                  }
                  availableToDate={
                    actionItem.validSubscriptionFeature.availableToDate
                  }
                />
              )}
            </MenuItem>

            {actionIndex < actions.length - 1 && <Divider />}
          </div>
        ))}
      </ActionOrderMenuContainerMenu>

      {openDialogMultipleEdition && (
        <DialogOrdersMultipeEdition
          open={openDialogMultipleEdition}
          onClose={() => toggleDialogMultipleEdition(false)}
          selectedIds={selectedIds}
          onSuccessSubmit={reloadOrders}
        />
      )}

      <DialogConfimation
        open={openConfirmDeleteOrders}
        description="Los pedidos seleccionados serán eliminados"
        onConfirm={handleDeleteOrders}
        onCancel={() => toggleOpenConfirmDeleteOrders(false)}
        loadingConfirm={loadingDeleteOrders}
      />

      <DialogConfimation
        open={openConfirmDuplicateOrders}
        description="Los destinos seleccionados serán repetidos"
        onConfirm={handleDuplicateOrders}
        onCancel={() => toggleOpenConfirmDuplicateOrders()}
        loadingConfirm={loadingDuplicateOrders}
      />
    </>
  );
};

export default ActionOrderMenu;
