import { ReactElement } from 'react';

import { toAbsoluteUrl } from '~utils/assetsHelpers';
import { PATHS } from '~constants/index';
import { useAppSelector } from '~hooks/index';
import { selectAuth } from '~store/slices/auth/index';
import { isOnlyCreatorOrder } from '~utils/user';

import {
  classes,
  LateralMenuContainer,
  LateralMenuLogoWrapper,
  LateralMenuLogoLink,
  LateralMenuLogoMinial,
  LateralMenuLogoWhite,
  LateralMenuContentWrapper,
  LateralMenuList,
  LateralMenuListDivider,
} from './styles';
import LateralMenuListItem from './ListItem';

import { MENU_PRINCIPAL, MENU_SECONDARIES } from './items';
import { CustomerFeedbackItem } from './CustomerFeedbackItem';

const LateralMenu = (): ReactElement => {
  const { user } = useAppSelector(selectAuth);

  return (
    <LateralMenuContainer>
      <LateralMenuLogoWrapper className={classes.logoWrapper}>
        <LateralMenuLogoLink to={PATHS.HOME.BASE}>
          <LateralMenuLogoMinial
            src={toAbsoluteUrl('/logo_minimal.svg')}
            alt="Routix Logo Minimal"
            className={classes.logoMinial}
          />

          <LateralMenuLogoWhite
            src={toAbsoluteUrl('/logo_bw.png')}
            alt="Routix Logo White"
            className={classes.logoWhite}
          />
        </LateralMenuLogoLink>
      </LateralMenuLogoWrapper>

      <LateralMenuContentWrapper className={classes.contentWrapper}>
        {!isOnlyCreatorOrder(user) && (
          <>
            <LateralMenuList>
              {MENU_PRINCIPAL.map((principalItem) => (
                <LateralMenuListItem
                  key={`menu-items-principal-${principalItem.data.title}`}
                  {...principalItem}
                />
              ))}
            </LateralMenuList>

            <LateralMenuListDivider />
          </>
        )}

        <LateralMenuList>
          {MENU_SECONDARIES.map((secondaryItem) => (
            <LateralMenuListItem
              key={`menu-items-principal-${secondaryItem.data.title}`}
              {...secondaryItem}
            />
          ))}
        </LateralMenuList>

        <LateralMenuListDivider />

        <LateralMenuList>
          <CustomerFeedbackItem />
        </LateralMenuList>
      </LateralMenuContentWrapper>
    </LateralMenuContainer>
  );
};

export default LateralMenu;
