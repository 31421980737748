import {
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent,
  IconButton,
} from '@mui/material';
import styled from 'styled-components';

export const DialogCustomerFeedbackContainer = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    width: '100%',
  },
});

export const DialogCustomerFeedbackContent = styled(DialogContent)({
  display: 'flex',
  flexDirection: 'column',
});

export const DialogCustomerFeedbackCloseIcon = styled(IconButton)({
  position: 'absolute',
  right: 8,
  top: 12,
});

export const DialogCustomerFeedbackActions = styled(DialogActions)({
  justifyContent: 'space-between',
});
