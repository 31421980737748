import { ReactElement, useMemo } from 'react';

import { Nullable } from '~globals/types/commons';
import { useAppSelector } from '~hooks/index';
import { selectAuth } from '~store/slices/auth/index';
import { validateRoles } from '~utils/user';
import { validatePartnerCompanyType } from '~utils/partnerCompany';
import { PartnerCompanyTypes } from '~globals/types/enums';
import { NewFeatureBagde, AccessFeature } from '~components/index';

import { LateralMenuItem } from '../types';

import { classes, LateralMenuTooltip } from '../styles';

import {
  LateralMenuListItemRoot,
  LateralMenuListItemLink,
  LateralMenuListItemLinkTarget,
  LateralMenuListItemIconText,
  LateralMenuListSubItemContainer,
} from './styles';

const LateralMenuListItem = ({
  data,
  tooltipText,
  permissions,
  subItem: SubItemComponent,
}: LateralMenuItem): Nullable<ReactElement> => {
  const { user } = useAppSelector(selectAuth);

  const LinkComponent = useMemo(
    () =>
      data.target ? LateralMenuListItemLinkTarget : LateralMenuListItemLink,
    [data.target],
  );

  const IconComponent = useMemo(() => data.icon, [data.icon]);

  const isEnabled = useMemo(() => {
    const isValidRole = validateRoles(user, permissions.roles);

    const isValidCompanyType = validatePartnerCompanyType(
      user?.company.companyTypeId as PartnerCompanyTypes,
      permissions.companyType,
    );

    const isEnabledCrossCompany = permissions.validateCrossCompanyEnabled
      ? Boolean(user?.company.crossCompanyEnabled)
      : true;

    return isValidRole && isValidCompanyType && isEnabledCrossCompany;
  }, [permissions, user]);

  if (!isEnabled) return null;

  return (
    <>
      <LateralMenuTooltip title={tooltipText}>
        <LateralMenuListItemRoot>
          <LinkComponent
            href={data.path}
            target={data.target}
            to={data.path}
            color="inherit"
          >
            <IconComponent className={classes.icon} />

            <LateralMenuListItemIconText
              variant="inherit"
              color="inherit"
              className={classes.iconText}
            >
              {data.title}
            </LateralMenuListItemIconText>

            {data.chips?.activeVersion && (
              <NewFeatureBagde
                activeForVersion={data.chips.activeVersion}
                sx={{ backgroundColor: 'common.white' }}
              />
            )}

            {data.chips?.validFeature && (
              <AccessFeature.NextPlanChip
                validFeature={data.chips.validFeature}
              />
            )}
          </LinkComponent>
        </LateralMenuListItemRoot>
      </LateralMenuTooltip>

      {SubItemComponent && (
        <LateralMenuListSubItemContainer className={classes.subItem}>
          <SubItemComponent />
        </LateralMenuListSubItemContainer>
      )}
    </>
  );
};

export default LateralMenuListItem;
