import {
  ReactElement,
  ChangeEvent,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { useFormik, FormikHelpers } from 'formik';
import { Stack, Typography, TextField, MenuItem } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentsDollar as CommentDollarIcon } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';
import { get } from 'lodash';

// import { PhoneField } from '~components/index';
// import { PhoneFieldData } from '~components/PhoneField/types';
import { hasError } from '~utils/formHelpers';
import { getInvoiceTypeByTaxType, getTaxIdLabel } from '~utils/billing';
import { toAbsoluteUrl } from '~utils/assetsHelpers';
import { useAppSelector, useLazyRequest } from '~hooks/index';
import { requestPayment } from '~services/subscription/payment';
import { selectAuth } from '~store/slices/auth';
import { Nullable } from '~globals/types';
import { SubscriptionPaymentProviders } from '~globals/types/commons';

import { useSubscriptionPaymentModalContext } from '../../../SubscriptionPaymentModalContext';
import { SubscriptionPaymentModalPaypalButton } from '../../../PaymentButton';

import { SubscriptionPaymentModalFormProviderButton } from '../../styles';

import {
  FIELDS_NAME,
  getInitialValues,
  getValidationSchema,
  parseBillingData,
} from './utils';
import { SubscriptionPaymentModalFormBillingDataLoadingButton } from './styles';

const SubscriptionPaymentModalFormBillingData = (): ReactElement => {
  const { user } = useAppSelector(selectAuth);
  const { enqueueSnackbar } = useSnackbar();

  const [currentProvider, setCurrentProvider] =
    useState<Nullable<SubscriptionPaymentProviders>>(null);

  const {
    isArg,
    selectsData: { countries, taxTypes, invoiceTypes },
    paymentType,
    providersPlansId,
    billingData: { setData: setBillingData },
    form: { setActiveForm },
    processPayment: { setIsProcess: setIsProcessPayment },
    onlyRequestPaymentButton,
  } = useSubscriptionPaymentModalContext();

  const onSubmit = useCallback(
    async (
      values: ReturnType<typeof getInitialValues>,
      { setSubmitting }: FormikHelpers<ReturnType<typeof getInitialValues>>,
    ) => {
      setBillingData(parseBillingData(values));

      if (currentProvider) setActiveForm(currentProvider);

      setSubmitting(false);
    },
    [setBillingData, setActiveForm, currentProvider],
  );

  const {
    values,
    errors,
    touched,
    getFieldProps,
    setFieldValue,
    // setFieldTouched,
    submitForm,
    isSubmitting,
    isValid,
    validateForm,
  } = useFormik({
    initialValues: getInitialValues(user?.company),
    validationSchema: getValidationSchema(isArg),
    onSubmit,
    validateOnMount: true,
  });

  const handleChangeTaxType = useCallback(
    async (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const { value } = event.target;
      const invoiceType = getInvoiceTypeByTaxType(Number(value));

      await setFieldValue(FIELDS_NAME.TAX_TYPE_ID, value, false);
      await setFieldValue(
        FIELDS_NAME.INVOICE_TYPE_ID,
        invoiceType.toString(),
        false,
      );

      await validateForm();
    },
    [setFieldValue, validateForm],
  );

  useEffect(() => {
    setBillingData(parseBillingData(values));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const [, loadingRequestPayment, , executeRequestPayment] = useLazyRequest({
    request: requestPayment,
    withPostSuccess: () => {
      setIsProcessPayment(true);
    },
    withPostFailure: () => {
      enqueueSnackbar('Ha ocurrido un error, intente nuevamente', {
        variant: 'error',
      });
    },
  });

  const handleRequestPayment = useCallback(async () => {
    const billingData = parseBillingData(values);

    if (billingData) {
      const contractPlanId = get(providersPlansId, [
        'mercadopago',
        paymentType,
      ]);

      if (contractPlanId) {
        await executeRequestPayment({ contractPlanId, billingData });
      }
    }
  }, [executeRequestPayment, paymentType, providersPlansId, values]);

  // const handleChangePhone = useCallback(
  //   (dataPhone: PhoneFieldData) => {
  //     setFieldValue(FIELDS_NAME.PHONE, dataPhone.phone);
  //   },
  //   [setFieldValue],
  // );

  // const handleBlurPhone = useCallback(() => {
  //   setFieldTouched(FIELDS_NAME.PHONE, true);
  // }, [setFieldTouched]);

  const submitByProvider = useCallback(
    async (provider: SubscriptionPaymentProviders) => {
      setCurrentProvider(provider);
      await submitForm();
    },
    [submitForm],
  );

  return (
    <Stack spacing={1}>
      <Stack spacing={2}>
        <Typography fontWeight="bold">Datos de facturación</Typography>

        <Stack spacing={1}>
          <Stack spacing={1} direction="row">
            <TextField
              label="Razón social"
              fullWidth
              autoComplete="off"
              error={hasError(touched, errors, FIELDS_NAME.BUSINESS_NAME)}
              {...getFieldProps(FIELDS_NAME.BUSINESS_NAME)}
            />

            <TextField
              label={getTaxIdLabel(isArg)}
              fullWidth
              autoComplete="off"
              error={hasError(touched, errors, FIELDS_NAME.TAX_ID_NUMBER)}
              {...getFieldProps(FIELDS_NAME.TAX_ID_NUMBER)}
            />
          </Stack>

          <Stack spacing={1} direction="row">
            <TextField
              label="País"
              fullWidth
              autoComplete="off"
              error={hasError(touched, errors, FIELDS_NAME.COUNTRY_ID)}
              {...getFieldProps(FIELDS_NAME.COUNTRY_ID)}
              select
            >
              {countries.map((country) => (
                <MenuItem
                  key={`country-${country.value}`}
                  value={String(country.value)}
                >
                  {country.label}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              label="Teléfono"
              placeholder="Cod de área sin 0 + Nro sin 15"
              fullWidth
              autoComplete="off"
              error={hasError(touched, errors, FIELDS_NAME.PHONE)}
              {...getFieldProps(FIELDS_NAME.PHONE)}
              helperText="Cod de área sin 0 + Nro sin 15"
            />

            {/* <PhoneField
              label="Teléfono"
              placeholder="Teléfono"
              fullWidth
              autoComplete="off"
              value={values[FIELDS_NAME.PHONE]}
              onChange={handleChangePhone}
              onBlur={handleBlurPhone}
              error={hasError(touched, errors, FIELDS_NAME.PHONE)}
            /> */}
          </Stack>

          <TextField
            label="Domicilio de facturación"
            fullWidth
            multiline
            rows={3}
            error={hasError(touched, errors, FIELDS_NAME.ADDRESS)}
            {...getFieldProps(FIELDS_NAME.ADDRESS)}
          />

          {isArg && (
            <Stack spacing={1} direction="row">
              <TextField
                label="Condición de IVA"
                fullWidth
                autoComplete="off"
                error={hasError(touched, errors, FIELDS_NAME.TAX_TYPE_ID)}
                {...getFieldProps(FIELDS_NAME.TAX_TYPE_ID)}
                onChange={handleChangeTaxType}
                select
              >
                {taxTypes.map((taxType) => (
                  <MenuItem
                    key={`tax-type-${taxType.value}`}
                    value={String(taxType.value)}
                  >
                    {taxType.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                label="Tipo de comprobante"
                fullWidth
                autoComplete="off"
                error={hasError(touched, errors, FIELDS_NAME.INVOICE_TYPE_ID)}
                {...getFieldProps(FIELDS_NAME.INVOICE_TYPE_ID)}
                select
                disabled
              >
                {invoiceTypes.map((invoiceType) => (
                  <MenuItem
                    key={`invoice-type-${invoiceType.value}`}
                    value={String(invoiceType.value)}
                  >
                    {invoiceType.label}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
          )}
        </Stack>
      </Stack>

      <Stack>
        <Typography fontWeight="bold">Medios de pago</Typography>

        {!onlyRequestPaymentButton && (
          <Stack spacing={2} direction="row" justifyContent="center">
            {isArg && (
              <SubscriptionPaymentModalFormProviderButton
                variant="contained"
                provider="mercadopago"
                startIcon={
                  <img
                    src={toAbsoluteUrl('/icons/icon_mercadopago.png')}
                    alt="Fuel Icon"
                  />
                }
                onClick={() => submitByProvider('mercadopago')}
                disabled={!isValid}
              >
                Pagar con Mercado Pago
              </SubscriptionPaymentModalFormProviderButton>
            )}

            <div>
              <SubscriptionPaymentModalPaypalButton
                onClick={submitForm}
                disabled={!isValid}
              />
            </div>
          </Stack>
        )}

        <Stack direction="row" justifyContent="center">
          <SubscriptionPaymentModalFormBillingDataLoadingButton
            variant="outlined"
            color="primary"
            startIcon={<FontAwesomeIcon icon={CommentDollarIcon} />}
            onClick={handleRequestPayment}
            loading={loadingRequestPayment || isSubmitting}
            disabled={!isValid}
          >
            Hablar con un representante
          </SubscriptionPaymentModalFormBillingDataLoadingButton>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SubscriptionPaymentModalFormBillingData;
