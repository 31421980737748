import {
  styled,
  Divider,
  badgeClasses,
  Tooltip,
  TooltipProps,
  tooltipClasses,
} from '@mui/material';
import Link from '../Link';

export const classes = {
  logoWrapper: 'LateralMenuLogoWrapper-root',
  logoMinial: 'LateralMenuLogoMinial-root',
  logoWhite: 'LateralMenuLogoWhite-root',
  icon: 'LateralMenuListItemIcon-root',
  iconText: 'LateralMenuListItemIconText-root',
  contentWrapper: 'LateralMenuContentWrapper-root',
  subItem: 'LateralMenuListSubItem-root',
};

const backgroundColor = '#181818';

export const LateralMenuContainer = styled('nav')(({ theme }) => ({
  backgroundColor,
  color: theme.palette.tertiary.main,
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  width: theme.maxWidths.lateralMenu,
  zIndex: theme.zIndex.drawer,
  transition: theme.transitions.create('width', {
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflow: 'hidden',

  '&:hover': {
    width: theme.maxWidths.lateralMenuExpanded,

    [`& .${classes.logoWrapper}`]: {
      backgroundColor: theme.palette.common.black,
    },

    [`& .${classes.logoMinial}`]: {
      display: 'none',
    },

    [`& .${classes.logoWhite}`]: {
      display: 'inline-block',
    },

    [`& .${classes.iconText}`]: {
      opacity: 1,
    },

    [`& .${classes.subItem}`]: {
      opacity: 1,
    },

    [`& .${classes.icon} .${badgeClasses.badge}`]: {
      visibility: 'hidden',
    },
  },
}));

export const LateralMenuLogoWrapper = styled('div')(({ theme }) => ({
  height: 95,
  display: 'flex',
  justifyContent: 'space-between',
  position: 'relative',
  width: theme.maxWidths.lateralMenuExpanded,
  paddingLeft: theme.spacing(0.75),
  paddingRight: theme.spacing(0.75),
  paddingTop: theme.spacing(3.25),
  backgroundColor,
  transition: theme.transitions.create('background-color', {
    duration: theme.transitions.duration.enteringScreen,
  }),
}));

export const LateralMenuLogoLink = styled(Link)({
  display: 'flex',
  flex: 1,
});

export const LateralMenuLogoMinial = styled('img')({
  height: 45,
  display: 'inline-block',
});

export const LateralMenuLogoWhite = styled('img')({
  height: 40,
  display: 'none',
  filter: 'invert(100%)',
  marginLeft: 26,
});

export const LateralMenuContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: theme.spacing(2, 1.5),
}));

export const LateralMenuList = styled('ul')(({ theme }) => ({
  listStyle: 'none',
  margin: 0,
  padding: 0,
  width: `calc(${theme.maxWidths.lateralMenuExpanded}px - ${theme.spacing(3)})`,
}));

export const LateralMenuListDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.secondary.main,
  marginTop: theme.spacing(1.25),
  marginBottom: theme.spacing(2.5),
}));

export const LateralMenuTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      arrow
      placement="right"
      classes={{ popper: className }}
    />
  ),
)({
  [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
    {
      marginLeft: 0,
    },
});
