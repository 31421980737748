import { forEach } from 'lodash';

import API from '~config/api';
import {
  ApiPromise,
  DataResponse,
  ErrorResponse,
  DataPaginateResponse,
  PaginationRequestParams,
  FileObj,
} from '~globals/types';

import {
  ItemCreateBody,
  ItemCreateResponse,
  ItemExtended,
  ItemFilter,
  ItemFilterExport,
  ItemCreateExtended,
  ItemsImporter,
  ItemFilesFilters,
  ItemFileBase,
  ItemFilesAssociate,
  ItemImportFromIntegrationsBody,
  ItemImportFromIntegrationsResponse,
  ItemEvidencesPDFParams,
  ItemTask,
  ItemByAssociateCompanyFilter,
  ItemExportTicketsBody,
  ItemMap,
  ItemInfo,
  ItemGeoBase,
  ItemGeoExtended,
  ItemsWithGeoErrorsHead,
  ItemEditionMultipleBody,
} from './types';

export const ITEM_ENPOINTS = {
  createItem: '/api/v1/Route/item',
  searchItems: '/api/v1/Route/item/search',
  searchItemsAll: '/api/v1/Route/Item/searchAll',
  importItemsFromCSV: '/api/v1/Route/Item/ImportFromCSV',
  importItemsFromIntegrations: '/api/v1/Route/Integrations/Import',
  getItemById: (itemId: string): string => `/api/v1/Route/Item/${itemId}`,
  getItemInfo: (itemId: string): string => `/api/v1/Route/Item/Info/${itemId}`,
  getItemByIds: '/api/v1/Route/Item/GetFromArray',
  updateItem: '/api/v1/Route/Item',
  deleteItems: '/api/v1/Route/Item',
  associateFilesToItem: '/api/v1/Route/Item/Doc',
  searchItemFiles: '/api/v1/Route/Item/ItemFiles/search',
  removeItemFiles: '/api/v1/Route/ItemFile',
  getEvidencesItemPDF: (trackingCode: string): string =>
    `/api/v1/Report/EvidenceItemPDF/${trackingCode}`,
  addItemTaskInItem: '/api/v1/Route/ItemTask',
  updateItemTaskInItem: '/api/v1/Route/ItemTask',
  removeItemTaskInItem: (itemTaskId: string): string =>
    `/api/v1/Route/ItemTask/${itemTaskId}`,
  duplicateItems: '/api/v1/Route/Item/Duplicate',
  markAsPendingItems: '/api/v1/Route/Item/Renew',
  searchItemByAssociateCompany: '/api/v1/Route/Item/searchByAssociatedCompany',
  exportTickets: '/api/v1/Report/TicketPDF',
  exportTicketsMl: '/api/v1/Route/Item/PrintFlexLabel',
  getItemsWithGeoErrors: '/api/v1/Route/Item/WithGeoErrors',
  updateItemsWithGeoErrors: '/api/v1/Route/Item/UpdateItemsWithGeoErrors',
  editMultipleItems: '/api/v1/Route/Item/EditMultiple',
};

export const createItem = (
  body: ItemCreateBody,
): ApiPromise<DataResponse<ItemCreateResponse>, ErrorResponse> =>
  API.post<DataResponse<ItemCreateResponse>, ErrorResponse>(
    ITEM_ENPOINTS.createItem,
    body,
  );

export const importItemsFromCSV = (
  files: FileObj[],
): ApiPromise<DataResponse<ItemsImporter>, ErrorResponse> => {
  const formData = new FormData();

  forEach(files, (item) => {
    formData.append('formFile', item.file, item.file.name);
  });

  return API.post<DataResponse<ItemsImporter>, ErrorResponse>(
    ITEM_ENPOINTS.importItemsFromCSV,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'multipart/form-data',
      },
    },
  );
};

export const importItemsFromIntegrations = (
  body: ItemImportFromIntegrationsBody,
): ApiPromise<
  DataResponse<ItemImportFromIntegrationsResponse>,
  ErrorResponse
> =>
  API.post<DataResponse<ItemImportFromIntegrationsResponse>, ErrorResponse>(
    ITEM_ENPOINTS.importItemsFromIntegrations,
    body,
  );

export const searchItems = ({
  pagination: { page, sizePerPage },
  filters,
}: PaginationRequestParams<ItemFilter>): ApiPromise<
  DataPaginateResponse<ItemExtended[], null, ItemsWithGeoErrorsHead>,
  ErrorResponse
> =>
  API.post<
    DataPaginateResponse<ItemExtended[], null, ItemsWithGeoErrorsHead>,
    ErrorResponse
  >(ITEM_ENPOINTS.searchItems, {
    ...filters,
    pagination: { offset: page, size: sizePerPage },
  });

export const searchItemsAll = ({
  pagination: { page, sizePerPage },
  filters,
}: PaginationRequestParams<ItemFilter>): ApiPromise<
  DataPaginateResponse<ItemExtended[], null, ItemsWithGeoErrorsHead>,
  ErrorResponse
> =>
  API.post<
    DataPaginateResponse<ItemExtended[], null, ItemsWithGeoErrorsHead>,
    ErrorResponse
  >(ITEM_ENPOINTS.searchItemsAll, {
    ...filters,
    pagination: { offset: page, size: sizePerPage },
  });

export const searchItemsMap = (
  filters: ItemFilter,
): ApiPromise<DataPaginateResponse<ItemMap[]>, ErrorResponse> =>
  API.post<DataPaginateResponse<ItemMap[]>, ErrorResponse>(
    ITEM_ENPOINTS.searchItems,
    {
      ...filters,
      mapMode: true,
      pagination: { offset: 1, size: -1 },
    },
  );

export const searchItemsToExport = ({
  pagination: { page, sizePerPage },
  filters,
}: PaginationRequestParams<ItemFilterExport>): ApiPromise<
  Blob,
  ErrorResponse
> =>
  API.post<Blob, ErrorResponse>(
    ITEM_ENPOINTS.searchItems,
    { ...filters, pagination: { offset: page, size: sizePerPage } },
    { responseType: 'blob', timeout: 120000 },
  );

export const getItemById = (
  itemId: string,
): ApiPromise<DataResponse<ItemCreateExtended>, ErrorResponse> =>
  API.get<DataResponse<ItemCreateExtended>, ErrorResponse>(
    ITEM_ENPOINTS.getItemById(itemId),
  );

export const getItemByIds = (
  itemIds: string[],
): ApiPromise<DataPaginateResponse<ItemExtended[]>, ErrorResponse> =>
  API.post<DataPaginateResponse<ItemExtended[]>, ErrorResponse>(
    ITEM_ENPOINTS.getItemByIds,
    { itemIds },
  );

export const updateItem = (
  data: ItemCreateExtended,
): ApiPromise<DataResponse<ItemCreateExtended>, ErrorResponse> =>
  API.put<DataResponse<ItemCreateExtended>, ErrorResponse>(
    ITEM_ENPOINTS.updateItem,
    data,
  );

export const deleteItems = (
  itemdsId: string[],
): ApiPromise<DataResponse<null>, ErrorResponse> =>
  API.delete<DataResponse<null>, ErrorResponse>(
    ITEM_ENPOINTS.deleteItems,
    {},
    { data: itemdsId },
  );

export const associateFilesToItem = (
  body: ItemFilesAssociate,
): ApiPromise<DataResponse<null>, ErrorResponse> =>
  API.post<DataResponse<null>, ErrorResponse>(
    ITEM_ENPOINTS.associateFilesToItem,
    body,
  );

export const searchItemFiles = ({
  pagination: { page, sizePerPage },
  filters,
}: PaginationRequestParams<ItemFilesFilters>): ApiPromise<
  DataPaginateResponse<ItemFileBase[]>,
  ErrorResponse
> =>
  API.post<DataPaginateResponse<ItemFileBase[]>, ErrorResponse>(
    ITEM_ENPOINTS.searchItemFiles,
    { ...filters, pagination: { offset: page, size: sizePerPage } },
  );

export const removeItemsFile = (
  itemFilesId: string[],
): ApiPromise<DataResponse<null>, ErrorResponse> =>
  API.delete<DataResponse<null>, ErrorResponse>(
    ITEM_ENPOINTS.removeItemFiles,
    {},
    { data: itemFilesId },
  );

export const getEvidencesItemPDF = ({
  trackingCode,
  includeMap = true,
}: ItemEvidencesPDFParams): ApiPromise<Blob, ErrorResponse> =>
  API.get<Blob, ErrorResponse>(
    ITEM_ENPOINTS.getEvidencesItemPDF(trackingCode),
    { includeMap: Number(includeMap) },
    { responseType: 'blob', timeout: 120000 },
  );

export const addItemTaskInItem = (
  data: ItemTask,
): ApiPromise<DataResponse<ItemTask>, ErrorResponse> =>
  API.post<DataResponse<ItemTask>, ErrorResponse>(
    ITEM_ENPOINTS.addItemTaskInItem,
    data,
  );

export const updateItemTaskInItem = (
  data: ItemTask,
): ApiPromise<DataResponse<ItemTask>, ErrorResponse> =>
  API.put<DataResponse<ItemTask>, ErrorResponse>(
    ITEM_ENPOINTS.updateItemTaskInItem,
    data,
  );

export const removeItemTaskInItem = (
  itemTaskId: string,
): ApiPromise<DataResponse<ItemTask>, ErrorResponse> =>
  API.delete<DataResponse<ItemTask>, ErrorResponse>(
    ITEM_ENPOINTS.removeItemTaskInItem(itemTaskId),
  );

export const duplicateItems = (
  itemsIds: string[],
): ApiPromise<DataResponse<null>, ErrorResponse> =>
  API.post<DataResponse<null>, ErrorResponse>(
    ITEM_ENPOINTS.duplicateItems,
    itemsIds,
  );

export const markAsPendingItems = (
  itemsIds: string[],
): ApiPromise<DataResponse<null>, ErrorResponse> =>
  API.post<DataResponse<null>, ErrorResponse>(
    ITEM_ENPOINTS.markAsPendingItems,
    itemsIds,
  );

export const searchItemByAssociateCompany = (
  filters: ItemByAssociateCompanyFilter,
): ApiPromise<DataResponse<ItemExtended>, ErrorResponse> =>
  API.post<DataResponse<ItemExtended>, ErrorResponse>(
    ITEM_ENPOINTS.searchItemByAssociateCompany,
    filters,
  );

export const exportTickets = (
  body: ItemExportTicketsBody,
): ApiPromise<Blob, ErrorResponse> =>
  API.post<Blob, ErrorResponse>(ITEM_ENPOINTS.exportTickets, body, {
    responseType: 'blob',
    timeout: 120000,
  });

export const exportTicketsMl = (
  body: string[],
): ApiPromise<Blob, ErrorResponse> =>
  API.post<Blob, ErrorResponse>(ITEM_ENPOINTS.exportTicketsMl, body, {
    responseType: 'blob',
    timeout: 120000,
  });

export const getItemInfo = (
  itemId: string,
): ApiPromise<DataResponse<ItemInfo>, ErrorResponse> =>
  API.get<DataResponse<ItemInfo>, ErrorResponse>(
    ITEM_ENPOINTS.getItemInfo(itemId),
  );

export const getItemsWithGeoErrors = (): ApiPromise<
  DataPaginateResponse<ItemGeoExtended[]>,
  ErrorResponse
> =>
  API.get<DataPaginateResponse<ItemGeoExtended[]>, ErrorResponse>(
    ITEM_ENPOINTS.getItemsWithGeoErrors,
  );

export const updatetItemsWithGeoErrors = (
  items: ItemGeoBase[],
): ApiPromise<DataResponse<null>, ErrorResponse> =>
  API.put<DataResponse<null>, ErrorResponse>(
    ITEM_ENPOINTS.updateItemsWithGeoErrors,
    { items },
  );

export const editMultipleItems = (
  body: ItemEditionMultipleBody,
): ApiPromise<DataResponse<null>, ErrorResponse> =>
  API.put<DataResponse<null>, ErrorResponse>(
    ITEM_ENPOINTS.editMultipleItems,
    body,
  );
