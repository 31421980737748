import { ReactElement, useCallback, useMemo } from 'react';
import {
  Stack,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from '@mui/material';
import { useFormikContext } from 'formik';

import { ORDER_MANAGEMENT_COLLECT_OPTIONS } from '~constants/businessProfiles';
import { BusinessProfileOrderManagementCollectTypes } from '~globals/types/enums';

import { useBusinessProfileTabsFormContext } from '../../BusinesProfileTabsFormContext';
import { BusinessProfileTabsFormContextPanelsId } from '../../types';
import { FIELDS_NAMES, initialValues } from '../../utils';

const BusinessProfileOrderManagementCollect = (): ReactElement => {
  const { handleNextTab, handleGoBack } = useBusinessProfileTabsFormContext();

  const { values, getFieldProps, setFieldValue } =
    useFormikContext<typeof initialValues>();

  const orderManagementCollectValue = useMemo(
    () => values[FIELDS_NAMES.ORDER_MANAGEMENT_COLLECT],
    [values],
  );

  const onGoBack = useCallback(() => {
    setFieldValue(FIELDS_NAMES.ORDER_MANAGEMENT_COLLECT, '');
    handleGoBack('orderManagementCollect');
  }, [handleGoBack, setFieldValue]);

  const onNextTab = useCallback(() => {
    let nextTab: BusinessProfileTabsFormContextPanelsId =
      'orderCentralizationShipment';

    if (
      orderManagementCollectValue ===
      BusinessProfileOrderManagementCollectTypes.Collect
    )
      nextTab = 'orderCollectedTransfer';

    handleNextTab(nextTab);
  }, [orderManagementCollectValue, handleNextTab]);

  const isDisabledNextButton = useMemo(
    () => !orderManagementCollectValue,
    [orderManagementCollectValue],
  );

  return (
    <Stack spacing={2} flex={1} justifyContent="space-between">
      <Stack spacing={1}>
        <Typography variant="subtitle1" fontWeight="bold">
          Respecto a la gestión de las órdenes:
        </Typography>

        <Typography variant="subtitle2">
          ¿Cómo opera su empresa en relación con la recolección de órdenes?
        </Typography>

        <RadioGroup {...getFieldProps(FIELDS_NAMES.ORDER_MANAGEMENT_COLLECT)}>
          {ORDER_MANAGEMENT_COLLECT_OPTIONS.map((option) => (
            <FormControlLabel
              key={`order-management-collect-${option.value}`}
              value={option.value}
              control={<Radio />}
              label={option.label}
            />
          ))}
        </RadioGroup>
      </Stack>

      <Stack direction="row" spacing={2} justifyContent="flex-end">
        <Button variant="outlined" color="secondary" onClick={onGoBack}>
          Regresar
        </Button>

        <Button
          variant="outlined"
          color="primary"
          onClick={onNextTab}
          disabled={isDisabledNextButton}
        >
          Continuar
        </Button>
      </Stack>
    </Stack>
  );
};

export default BusinessProfileOrderManagementCollect;
