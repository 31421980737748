import { chain, words, last, toUpper, includes, replace } from 'lodash';

import { AuthExtendedUser } from '~services/auth/types';
import { Roles } from '~globals/types/enums';
import { Nullable, EnumItem } from '~globals/types';
import { ROLES_DESCRIPTION } from '~constants/rolesDescription';

import { toAbsoluteUrl } from './assetsHelpers';

const trimHead = (str: string) => chain(str).head().trim();

export const getInitialsNameLetterUpperCase = (name: string): string => {
  const [firstName, ...restName] = words(name);
  let initials = chain(firstName).toArray().slice(0, 2).join('').value();

  const lastName = last(restName);

  if (lastName) {
    initials = `${trimHead(firstName)}${trimHead(lastName)}`;
  }

  return toUpper(initials);
};

export const isAdminUser = (user: Nullable<AuthExtendedUser>): boolean =>
  user?.roleId === Roles.Admin;

export const isOnlyReadUser = (user: Nullable<AuthExtendedUser>): boolean =>
  user?.roleId === Roles.Reader;

export const isOnlyCreatorOrder = (user: Nullable<AuthExtendedUser>): boolean =>
  user?.roleId === Roles.CreatorOrder;

export const validateRoles = (
  user: Nullable<AuthExtendedUser>,
  roles: Roles[],
): boolean => includes(roles, user?.roleId);

export const getRolDescription = ({
  roleId,
  role,
  isOwner,
}: {
  roleId: Roles;
  role?: EnumItem<Roles, 'roleId'>;
  isOwner?: boolean;
}): string => {
  if (isOwner) return 'Propietario';

  if (role) return role.description;

  return ROLES_DESCRIPTION[roleId];
};

export const getDefaultAvatar = (currentUrl?: string): string =>
  !currentUrl ? toAbsoluteUrl('/icons/icon_user.svg') : currentUrl;

export const parsePhoneInPlainText = (phone: string): string => {
  const regex = new RegExp('[\\(|\\)\\s\\-]', 'g');

  return replace(phone, regex, '');
};
