import { ReactElement, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch, useHeightLayout, useRequest } from '~hooks/index';
import { useClientOnMethodMultiple } from '~hooks/useSignalR';
import { getPlansDetails, getPlanSubscription } from '~services/plan';
import { PlanExtended } from '~services/plan/types';
import { AuthSubscription } from '~services/auth/types';
import { setPlans } from '~store/slices/plans';
import { setSubscription } from '~store/slices/auth';
import HeaderActionsProvider from '~contexts/HeaderActionsContext';
import { useBannersContext } from '~contexts/Banners';
import { usePopupsContext } from '~contexts/Popups';
import { METHODS_NAME } from '~constants/signalR';

import LoadingFallback from '../LoadingFallback';
import LateralMenu from '../LateralMenu';
import Header from '../Header';
import Footer from '../Footer';
import DialogMobileNotPerformance from '../DialogMobileNotPerformance';
import ProToPlus from '../ProToPlus';
import { LoadingContainer, Container, Content, ContentWrapper } from './styles';

const Layout = (): ReactElement => {
  const { header: heightHeader } = useHeightLayout();

  const { open: openBanner, toggleOpen: toggleOpenBanner } =
    useBannersContext();
  const { open: openPopup, toggleOpen: toggleOpenPopup } = usePopupsContext();

  return (
    <Container>
      <LateralMenu />

      <Content>
        <HeaderActionsProvider>
          <Header />

          <ContentWrapper heightHeader={heightHeader}>
            <Outlet />
          </ContentWrapper>
        </HeaderActionsProvider>
      </Content>

      <Footer />

      <ProToPlus.Popup
        open={openPopup}
        onClose={() => toggleOpenPopup(false)}
      />

      <ProToPlus.Banner
        open={openBanner}
        onClose={() => toggleOpenBanner(false)}
      />

      <DialogMobileNotPerformance />
    </Container>
  );
};

const LayoutWrapper = (): ReactElement => {
  const dispatch = useAppDispatch();

  const [, loadingPlans] = useRequest({
    request: getPlansDetails,
    payload: null,
    withPostSuccess: (response) => {
      const plansDetails = response.data?.data.results as PlanExtended[];

      dispatch(setPlans(plansDetails));
    },
    withPostFailure: () => {
      console.log('Error load plans details');
    },
  });

  const [loadingCurrentPlanSubscription, setLoadingCurrentPlanSubscription] =
    useState(true);

  const [, , , reloadCurrenPlanSubscription] = useRequest({
    request: getPlanSubscription,
    payload: null,
    withPostSuccess: (response) => {
      const currentPlanSubscription = response.data?.data as AuthSubscription;

      dispatch(setSubscription(currentPlanSubscription));
      setLoadingCurrentPlanSubscription(false);
    },
    withPostFailure: () => {
      console.log('Error load plans subscription');
      setLoadingCurrentPlanSubscription(false);
    },
  });

  useClientOnMethodMultiple(METHODS_NAME.ADD_ROUTE, 'Layout', () =>
    reloadCurrenPlanSubscription(null),
  );

  useClientOnMethodMultiple(METHODS_NAME.REFRESH_SUBSCRIPTION, 'Layout', () =>
    reloadCurrenPlanSubscription(null),
  );

  if (loadingPlans || loadingCurrentPlanSubscription)
    return (
      <LoadingContainer>
        <LoadingFallback />
      </LoadingContainer>
    );

  return <Layout />;
};

export default LayoutWrapper;
