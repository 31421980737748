import { ReactElement, ReactNode, useCallback, useMemo } from 'react';
import { TextField, MenuItem, ListItemText } from '@mui/material';
import { chain } from 'lodash';

import { SelectWithScopeFieldProps } from './types';

const SelectWithScopeField = ({
  options,
  ...props
}: SelectWithScopeFieldProps): ReactElement => {
  const renderValue = useCallback(
    (selectedValue: unknown): ReactNode => {
      const currentValue = chain(options)
        .find({ value: selectedValue as string | number })
        .get('label', '')
        .value();

      return currentValue;
    },
    [options],
  );

  const nameField = useMemo(
    () => props.name ?? props.id,
    [props.name, props.id],
  );

  return (
    <TextField
      {...props}
      select
      SelectProps={{
        renderValue,
        MenuProps: {
          PaperProps: {
            style: {
              width: 'min-content',
            },
          },
        },
      }}
    >
      {options.map((option) => (
        <MenuItem
          key={`${nameField}-${option.value}`}
          value={option.value}
          sx={{ whiteSpace: 'pre-wrap' }}
        >
          <ListItemText
            primary={option.label}
            secondary={option.scopeDescription}
            secondaryTypographyProps={{ variant: 'caption' }}
          />
        </MenuItem>
      ))}
    </TextField>
  );
};

export default SelectWithScopeField;
