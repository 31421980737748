import { createContext, useContext, FC, useEffect } from 'react';
import { isNull } from 'lodash';

import { useToggle, useAppSelector } from '~hooks/index';
import { selectAuth } from '~store/slices/auth';
import { isAdminUser } from '~utils/user';

interface BusinessProfileUpdateWidgetContextValue {
  expandedCard: {
    expanded: boolean;
    toggleExpanded: (nextValue?: boolean) => void;
  };
  openDialog: {
    open: boolean;
    toggleOpen: (nextValue?: boolean) => void;
  };
}

const BusinessProfileUpdateWidgetContext =
  createContext<BusinessProfileUpdateWidgetContextValue>({
    expandedCard: { expanded: false, toggleExpanded: () => {} },
    openDialog: { open: false, toggleOpen: () => {} },
  });

export const useBusinessProfileUpdateWidgetContext =
  (): BusinessProfileUpdateWidgetContextValue =>
    useContext(BusinessProfileUpdateWidgetContext);

const BusinessProfileUpdateWidgetProvider: FC = ({ children }) => {
  const { user } = useAppSelector(selectAuth);

  const [expandedCard, toogleExpandedCard] = useToggle(false);

  useEffect(() => {
    const isExpanderCard =
      isAdminUser(user) && isNull(user?.company.businessProfileTypeId ?? null);

    toogleExpandedCard(isExpanderCard);
  }, [toogleExpandedCard, user]);

  const [openDialog, toogleOpenDialog] = useToggle(false);

  return (
    <BusinessProfileUpdateWidgetContext.Provider
      value={{
        expandedCard: {
          expanded: expandedCard,
          toggleExpanded: toogleExpandedCard,
        },
        openDialog: {
          open: openDialog,
          toggleOpen: toogleOpenDialog,
        },
      }}
    >
      {children}
    </BusinessProfileUpdateWidgetContext.Provider>
  );
};

export default BusinessProfileUpdateWidgetProvider;
