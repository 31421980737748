import { ReactElement } from 'react';
import { Slide } from '@mui/material';

import { ProToPlusPopupProps } from './types';
import ProToPlusDiscountImg from '../DiscountImg';
import { ProToPlusPopupContent } from './styles';

const ProToPlusPopup = ({
  open,
  onClose,
}: ProToPlusPopupProps): ReactElement => (
  <Slide direction="up" in={open} mountOnEnter unmountOnExit>
    <ProToPlusPopupContent>
      <ProToPlusDiscountImg onClick={onClose} />
    </ProToPlusPopupContent>
  </Slide>
);

export default ProToPlusPopup;
