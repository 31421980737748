import { toAbsoluteUrl } from '~utils/assetsHelpers';

export const sourceImportType = [
  {
    name: 'Archivo o Planilla',
    image: {
      url: toAbsoluteUrl('/images/icon_source_file@2x.png'),
      alt: 'Archivo Logo',
    },
  },
  {
    name: 'Manual',
    image: {
      url: toAbsoluteUrl('/images/icon_source_manual@2x.png'),
      alt: 'Manual Logo',
    },
  },
  {
    name: 'Tienda Nube',
    image: {
      url: toAbsoluteUrl('/images/icon_source_tn@2x.png'),
      alt: 'MercadoLibre Logo',
    },
  },
  {
    name: 'MercadoLibre',
    image: {
      url: toAbsoluteUrl('/images/icon_source_mp@2x.png'),
      alt: 'MercadoLibre Logo',
    },
  },
  {
    name: 'WooCommerce',
    image: {
      url: toAbsoluteUrl('/images/icon_source_woo@2x.png'),
      alt: 'WooCommerce Logo',
    },
  },
  {
    name: 'FLEX',
    image: {
      url: toAbsoluteUrl('/images/icon_source_mp@2x.png'),
      alt: 'MercadoLibre Logo',
    },
  },
];
