import { ReactElement, useMemo } from 'react';
import { Stack, Typography } from '@mui/material';

import { TimeWindowInfo } from '~components/index';

import { DialogFeedbackStopContentRowItem } from '../../styles';
import { useDialogFeedbackStopContext } from '../../DialogFeedbackStopContext';

const DialogFeebackStopData = (): ReactElement => {
  const { data } = useDialogFeedbackStopContext();

  const routeItem = useMemo(() => data?.routeItem, [data]);

  const dataWarehouseOrItem = useMemo(
    () => routeItem?.warehouse ?? routeItem?.item,
    [routeItem],
  );

  const trackingCode = useMemo(
    () => data?.routeItem?.item?.trackingCode,
    [data],
  );

  return (
    <Stack spacing={2}>
      {trackingCode && (
        <DialogFeedbackStopContentRowItem>
          <Typography variant="body2" fontWeight="bold">
            Nro. de Tracking
          </Typography>

          <Typography variant="body2">{trackingCode}</Typography>
        </DialogFeedbackStopContentRowItem>
      )}

      <DialogFeedbackStopContentRowItem>
        <Typography variant="body2" fontWeight="bold">
          Dirección
        </Typography>

        <Typography variant="body2">{dataWarehouseOrItem?.address}</Typography>
      </DialogFeedbackStopContentRowItem>

      {routeItem?.item && (
        <DialogFeedbackStopContentRowItem>
          <Typography variant="body2" fontWeight="bold">
            Ventana horaria
          </Typography>

          <TimeWindowInfo
            allDay={routeItem.item.timeWindowAllDay}
            windowFrom1={routeItem.item.timeWindowFrom1}
            windowFrom2={routeItem.item.timeWindowFrom2}
            windowTo1={routeItem.item.timeWindowTo1}
            windowTo2={routeItem.item.timeWindowTo2}
          />
        </DialogFeedbackStopContentRowItem>
      )}

      {routeItem?.item?.store && (
        <DialogFeedbackStopContentRowItem>
          <Typography variant="body2" fontWeight="bold">
            Tienda
          </Typography>

          <Typography variant="body2">{routeItem?.item?.store}</Typography>
        </DialogFeedbackStopContentRowItem>
      )}

      {dataWarehouseOrItem?.contactName && (
        <DialogFeedbackStopContentRowItem>
          <Typography variant="body2" fontWeight="bold">
            Recibe
          </Typography>

          <Typography variant="body2">
            {dataWarehouseOrItem.contactName}
          </Typography>

          {routeItem?.item?.contactIdentification && (
            <Typography variant="body2">
              {`Documento: ${routeItem.item.contactIdentification}`}
            </Typography>
          )}

          {(dataWarehouseOrItem.contactPhone ||
            routeItem?.item?.contactMobile) && (
            <Typography variant="body2">
              {`Teléfono de contacto: ${
                dataWarehouseOrItem.contactPhone ??
                routeItem?.item?.contactMobile
              }`}
            </Typography>
          )}

          {routeItem?.item?.contactEmail && (
            <Typography variant="body2">
              {`Email: ${routeItem?.item.contactEmail}`}
            </Typography>
          )}
        </DialogFeedbackStopContentRowItem>
      )}

      {routeItem?.item?.requestPaymentOnDestiny && (
        <DialogFeedbackStopContentRowItem>
          <Typography variant="body2" fontWeight="bold">
            Metodo de pago
          </Typography>

          <Typography variant="body2">Pago contraentrega</Typography>

          <Typography variant="body2">
            {`Monto a abonar: ${routeItem.item.cashOnDeliveryAmount ?? 'N/A'}`}
          </Typography>
        </DialogFeedbackStopContentRowItem>
      )}

      {routeItem?.item?.deliveryNotes && (
        <DialogFeedbackStopContentRowItem>
          <Typography variant="body2" fontWeight="bold">
            Comentarios
          </Typography>

          <Typography variant="body2">
            {routeItem?.item?.deliveryNotes}
          </Typography>
        </DialogFeedbackStopContentRowItem>
      )}

      {routeItem?.item?.invoice && (
        <DialogFeedbackStopContentRowItem>
          <Typography variant="body2" fontWeight="bold">
            Factura
          </Typography>

          <Typography variant="body2">{routeItem.item.invoice}</Typography>
        </DialogFeedbackStopContentRowItem>
      )}

      {routeItem?.item?.shipment && (
        <DialogFeedbackStopContentRowItem>
          <Typography variant="body2" fontWeight="bold">
            Remito
          </Typography>

          <Typography variant="body2">{routeItem.item.shipment}</Typography>
        </DialogFeedbackStopContentRowItem>
      )}
    </Stack>
  );
};

export default DialogFeebackStopData;
