import { styled, Dialog, dialogClasses, DialogActions } from '@mui/material';

export const DialogFinalizedRouteContainer = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    width: '100%',
    minHeight: 520,
  },
});

export const DialogFinalizedRouteLoadingContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  flex: 1,
});

export const DialogFinalizedRouteActions = styled(DialogActions)({
  justifyContent: 'space-between',
});
