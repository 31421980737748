import { ReactElement } from 'react';
import { Typography, Stack } from '@mui/material';

import { Markdown } from '~components/index';
import { formatterDate } from '~utils/formatter';
import { NewsData } from '~services/news/types';

import NewsMenuPlanInfoChip from './NewsMenuPlanInfoChip';

type NewsMenuItemInfoProps = Omit<NewsData, 'id' | 'tags'>;

const NewsMenuItemInfo = ({
  date,
  title,
  plans,
  description,
}: NewsMenuItemInfoProps): ReactElement => (
  <Stack spacing={1}>
    <Typography color="primary" variant="caption" textTransform="capitalize">
      {formatterDate(date, {
        format: 'DD [de] MMMM YYYY',
      })}
    </Typography>

    <Typography component="h6" fontWeight="bold">
      {title}
    </Typography>

    {plans.length > 0 && (
      <Stack spacing={1} direction="row" alignItems="center">
        <Typography color="text.secondary" variant="caption" fontWeight="bold">
          SOLO EN PLANES
        </Typography>

        <Stack spacing={1} direction="row" alignItems="center">
          {plans.map((plan) => (
            <NewsMenuPlanInfoChip
              key={`plan-${plan}`}
              name={plan.toUpperCase()}
            />
          ))}
        </Stack>
      </Stack>
    )}

    <Markdown source={description} />
  </Stack>
);

export default NewsMenuItemInfo;
