import { ReactElement, useCallback, useMemo } from 'react';
import {
  DialogTitle,
  DialogContent,
  Typography,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Button,
  Stack,
  Link,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { toAbsoluteUrl } from '~utils/assetsHelpers';
import { PATHS } from '~constants/index';
import { APP_STORES_INFO } from '~constants/appStoresInfo';
import { replaceParamsPath } from '~utils/index';

import { DialogRouteAssignmentProps } from './types';
import {
  DialogRouteAssignmentContainer,
  DialogRouteAssignmentDriverInfoContainer,
  DialogRouteAssignmentDriverInfoAvatar,
  DialogRouteAssignmentDriverInfoTextContainer,
  DialogRouteAssignmentListItemIconImage,
  DialogRouteAssignmentStoreImg,
  DialogRouteAssignmentActions,
} from './styles';

const defaultDriverUrl = toAbsoluteUrl('/icons/icon_user.svg');

const DialogRouteAssignment = ({
  open,
  onClose,
  driverInfo,
  routeInfo,
  isInRoute = false,
  onStartRoute,
}: DialogRouteAssignmentProps): ReactElement => {
  const navigate = useNavigate();

  const driverUrlAvatar = useMemo(
    () => driverInfo.avatarFile?.url ?? defaultDriverUrl,
    [driverInfo],
  );

  const fullNameDriver = useMemo(
    (): string => `${driverInfo.lastName}, ${driverInfo.firstName}`,
    [driverInfo],
  );

  const redirectToMyRoutes = useCallback(
    () => navigate(PATHS.ROUTES.BASE),
    [navigate],
  );

  const handleEditingAction = useCallback(() => {
    if (isInRoute) {
      onClose();
    } else {
      navigate(
        replaceParamsPath(PATHS.ROUTES.VEHICLE_PLANNING, {
          ':apiTransactionId': routeInfo.apiTransactionId,
          ':routeId': routeInfo.routeId,
        }),
      );
    }
  }, [
    isInRoute,
    onClose,
    navigate,
    routeInfo.apiTransactionId,
    routeInfo.routeId,
  ]);

  const editingTitle = useMemo(
    () => (!routeInfo ? 'Seguir editando' : 'Editar ruta'),
    [routeInfo],
  );

  return (
    <DialogRouteAssignmentContainer open={open} maxWidth="sm">
      <DialogTitle>Asignación de ruta completa</DialogTitle>

      <DialogContent dividers>
        <DialogRouteAssignmentDriverInfoContainer>
          <DialogRouteAssignmentDriverInfoAvatar
            src={driverUrlAvatar}
            alt={fullNameDriver}
          />

          <DialogRouteAssignmentDriverInfoTextContainer>
            <Typography
              component="p"
              variant="subtitle1"
              color="text.secondary"
            >
              Ruta asignada a
            </Typography>

            <Typography component="p" variant="h6" fontWeight="bold">
              {fullNameDriver}
            </Typography>
          </DialogRouteAssignmentDriverInfoTextContainer>
        </DialogRouteAssignmentDriverInfoContainer>

        <Typography
          component="p"
          variant="subtitle1"
          color="text.secondary"
          textAlign="center"
          paragraph
        >
          Enviamos la ruta al chofer para que la confirme.
        </Typography>

        <List>
          {onStartRoute && (
            <ListItemButton onClick={() => onStartRoute(routeInfo)}>
              <ListItemIcon>
                <DialogRouteAssignmentListItemIconImage
                  src={toAbsoluteUrl('/icons/icon_startroute.svg')}
                  alt="Start Route"
                />
              </ListItemIcon>

              <ListItemText
                primary="Iniciar ruta manualmente"
                primaryTypographyProps={{
                  variant: 'body2',
                  fontWeight: 'bold',
                  color: 'primary',
                }}
                // eslint-disable-next-line max-len
                secondary="Puedes iniciar la ruta y gestionar las paradas de manera manual."
              />
            </ListItemButton>
          )}

          <ListItemButton onClick={redirectToMyRoutes}>
            <ListItemIcon>
              <DialogRouteAssignmentListItemIconImage
                src={toAbsoluteUrl('/icons/icon_routes.svg')}
                alt="Back to my routes"
              />
            </ListItemIcon>

            <ListItemText
              primary="Volver a mis rutas"
              primaryTypographyProps={{
                variant: 'body2',
                fontWeight: 'bold',
                color: 'primary',
              }}
              // eslint-disable-next-line max-len
              secondary="Puedes seguir su estado y aguardar hasta que el chofer apruebe o rechace la ruta."
            />
          </ListItemButton>

          <ListItemButton onClick={handleEditingAction}>
            <ListItemIcon>
              <DialogRouteAssignmentListItemIconImage
                src={toAbsoluteUrl('/icons/icon_editroute_1.svg')}
                alt="Continue editing"
              />
            </ListItemIcon>

            <ListItemText
              primary={editingTitle}
              primaryTypographyProps={{
                variant: 'body2',
                fontWeight: 'bold',
                color: 'primary',
              }}
              secondary="El chofer será notificado de cualquier modificación."
            />
          </ListItemButton>
        </List>
      </DialogContent>

      <DialogContent dividers>
        <Stack spacing={1}>
          <Stack spacing={0}>
            <Typography
              component="p"
              variant="subtitle2"
              color="text.secondary"
              textAlign="center"
            >
              ¡Eres el chofer de esta ruta?
            </Typography>

            <Typography
              component="p"
              variant="subtitle1"
              textAlign="center"
              fontWeight="bold"
            >
              Descarga la app de Routix para choferes
            </Typography>
          </Stack>

          <Stack direction="row" spacing={1} justifyContent="center">
            <Link
              href={APP_STORES_INFO.PLAY_STORE.URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <DialogRouteAssignmentStoreImg
                src={APP_STORES_INFO.PLAY_STORE.IMAGE_URL}
                alt={APP_STORES_INFO.PLAY_STORE.NAME}
              />
            </Link>

            <Link
              href={APP_STORES_INFO.APP_STORE.URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <DialogRouteAssignmentStoreImg
                src={APP_STORES_INFO.APP_STORE.IMAGE_URL}
                alt={APP_STORES_INFO.APP_STORE.NAME}
              />
            </Link>
          </Stack>

          <Typography
            variant="caption"
            color="text.secondary"
            textAlign="center"
          >
            <Link
              href="https://routix.tawk.help/article/asignación-y-aceptación-de-rutas"
              color="primary"
              underline="hover"
              fontWeight="bold"
              target="_blank"
              rel="noopener noreferrer"
            >
              Haz click aquí
            </Link>{' '}
            para saber más acerca de la app de choferes
          </Typography>
        </Stack>
      </DialogContent>

      <DialogRouteAssignmentActions>
        <Button color="secondary" onClick={onClose}>
          Cerrar
        </Button>
      </DialogRouteAssignmentActions>
    </DialogRouteAssignmentContainer>
  );
};

export default DialogRouteAssignment;
