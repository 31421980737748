import {
  styled,
  Card,
  CardContent,
  Button,
  Dialog,
  dialogClasses,
  IconButton,
  DialogContent,
} from '@mui/material';

export const BusinessProfileUpdateWidgetCardRoot = styled(Card)(
  ({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
  }),
);

export const BusinessProfileUpdateWidgetCardContent = styled(CardContent)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    padding: theme.spacing(2, 3),

    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  }),
);

export const BusinessProfileUpdateWidgetCardImg = styled('img')({
  height: 60,
});

export const BusinessProfileUpdateWidgetCardButton = styled(Button)(
  ({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: theme.typography.button.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
  }),
);

export const BusinessProfileUpdateWidgetDialogContainer = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    maxWidth: 600,
    width: '100%',
    minHeight: 550,
  },
});

export const BusinessProfileUpdateWidgetDialogTitle = styled('div')(
  ({ theme }) => ({
    flex: 'none',
    padding: theme.spacing(2, 3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
);

export const BusinessProfileUpdateWidgetDialogTitleCloseIcon = styled(
  IconButton,
)(({ theme }) => ({
  color: theme.palette.grey[500],
}));

export const BusinessProfileUpdateWidgetDialogContent = styled(DialogContent)(
  ({ theme }) => ({
    paddingBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  }),
);
