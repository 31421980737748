import { ReactElement, useMemo, useState } from 'react';
import { Typography, Avatar, Badge, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap as MapIcon } from '@fortawesome/free-regular-svg-icons';
import {
  faCalendar as CalendarIcon,
  faBullhorn as NewsIcon,
  faSearch as OrderSearchIcon,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import classNames from 'classnames';

import { useHeaderActions } from '~app/contexts/HeaderActionsContext';
import {
  useAppDispatch,
  useAppSelector,
  useTypePartnerCompany,
  useRequest,
} from '~hooks/index';
import { checkNews } from '~services/news';
import { NewsCheckResponse } from '~services/news/types';
import { selectAuth } from '~store/slices/auth/index';
import { selectHome, setCheckNewsDate } from '~store/slices/home/index';
import { PATHS } from '~constants/index';
import { SCHEDULE_DEMO_URL } from '~constants/commons';
import {
  getInitialsNameLetterUpperCase,
  getDefaultAvatar,
  isAdminUser,
  isOnlyCreatorOrder,
} from '~utils/user';
import { toAbsoluteUrl } from '~utils/assetsHelpers';
import {
  ActiveRoutesMenu,
  FirstStepsMenu,
  NewsMenu,
  ProfileMenu,
  Subscription,
  DialogOrderSearch,
  ProToPlus,
} from '~components/index';

import {
  HeaderContainer,
  HeaderCardInfoColumn,
  // HeaderButton,
  HeaderButtonRouterlLink,
  HeaderButtonExternalLink,
  HeaderIconButton,
  HeaderIconButtonIcon,
  HeaderIconButtonImg,
  HeaderButtonAvatar,
} from './styles';
import { isAvailableProToPlusUpgradePopup } from '~utils/proToPlus';

const enabledBeforeDaysFirstSteps = 30;

const Header = (): ReactElement => {
  const { user } = useAppSelector(selectAuth);
  const { checkNewsDate } = useAppSelector(selectHome);
  const dispatch = useAppDispatch();
  const { isCarrier, isGiver } = useTypePartnerCompany();

  const isCurrentAdminUser = useMemo(() => isAdminUser(user), [user]);

  const {
    dialogOrderSearch,
    menuActiveRoutes,
    menuFirstSteps,
    menuProfile,
    menuNews,
  } = useHeaderActions();

  const isCurrentUserActiveFirstSteps = useMemo(() => {
    if (user?.creationDateTime) {
      const now = moment(moment().format(), 'YYYY-MM-DD');
      const creationDateUser = moment(user.creationDateTime, 'YYYY-MM-DD');
      const diffDays = now.diff(creationDateUser, 'days');

      return diffDays <= enabledBeforeDaysFirstSteps;
    }

    return false;
  }, [user]);

  const [existsNews, setExistsNews] = useState(false);

  const isUserOnlyCreatorOrder = useMemo(
    () => isOnlyCreatorOrder(user),
    [user],
  );

  useRequest({
    request: checkNews,
    isValidToRequest: !isUserOnlyCreatorOrder,
    payload: null,
    withPostSuccess: (response) => {
      const { existsNews: currentExistsNews } = response.data
        ?.data as NewsCheckResponse;

      setExistsNews(currentExistsNews);

      const nowDate = moment();

      const isBeforeCheckNewsDate = checkNewsDate
        ? moment(checkNewsDate).isBefore(nowDate, 'day')
        : true;

      if (currentExistsNews && isBeforeCheckNewsDate) {
        menuNews.handleToggleOpenMenu(true);
        dispatch(setCheckNewsDate(nowDate.format('YYYY-MM-DD')));
      }
    },
  });

  return (
    <HeaderContainer id="header-app">
      <HeaderCardInfoColumn>
        <Avatar
          alt={user?.company.name}
          src={user?.company.logoFile?.url}
          sx={{ width: 50, height: 50 }}
        >
          {getInitialsNameLetterUpperCase(user?.company.name as string)}
        </Avatar>

        <Typography component="p" variant="subtitle1" fontWeight="bold">
          {user?.company.name}
        </Typography>

        {!isUserOnlyCreatorOrder && (
          <>
            {!isGiver && (
              <Tooltip
                title="Este es tu plan actual. Haz click para ver todos los planes disponibles."
                arrow
                placement="bottom"
              >
                <span>
                  <Subscription.PlanInfoChip />
                </span>
              </Tooltip>
            )}

            {user && isAvailableProToPlusUpgradePopup(user) && (
              <ProToPlus.DiscountChip />
            )}
          </>
        )}
      </HeaderCardInfoColumn>

      <HeaderCardInfoColumn>
        {!isUserOnlyCreatorOrder && (
          <>
            {isCurrentAdminUser && (
              <Tooltip
                // eslint-disable-next-line max-len
                title="Agenda una reunión personalizada para aclarar dudas o preguntas sobre Routix y optimizar tu logística al máximo."
                arrow
                placement="bottom"
              >
                <span>
                  <HeaderButtonExternalLink
                    variant="outlined"
                    color="primary"
                    size="small"
                    href={SCHEDULE_DEMO_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                    startIcon={<FontAwesomeIcon icon={CalendarIcon} />}
                  >
                    ¿Tenés dudas? ¡Charlemos!
                  </HeaderButtonExternalLink>
                </span>
              </Tooltip>
            )}

            <Tooltip
              // eslint-disable-next-line max-len
              title="Gestiona de manera rápida los contactos que realizas visitas de manera frecuente."
              arrow
              placement="bottom"
            >
              <span>
                <HeaderButtonRouterlLink
                  variant="outlined"
                  color="secondary"
                  size="small"
                  to={PATHS.CUSTOMERS.BASE}
                >
                  Contactos Frecuentes
                </HeaderButtonRouterlLink>
              </span>
            </Tooltip>

            <Tooltip title="Buscar pedidos" arrow placement="bottom">
              <HeaderIconButton
                variant="outlined"
                color="secondary"
                aria-haspopup="true"
                onClick={() => dialogOrderSearch.handleToggleOpenDialog(true)}
              >
                <HeaderIconButtonIcon icon={OrderSearchIcon} />
              </HeaderIconButton>
            </Tooltip>

            {isCarrier && (
              <Tooltip
                title="Visualiza rápidamente tus rutas planificadas"
                arrow
                placement="bottom"
              >
                <HeaderIconButton
                  variant="outlined"
                  color="secondary"
                  ref={menuActiveRoutes.anchorElRefMenu}
                  aria-controls={
                    menuActiveRoutes.open ? 'active-routes-menu' : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={menuActiveRoutes.open ? 'true' : undefined}
                  onClick={() => menuActiveRoutes.handleToggleOpenMenu(true)}
                >
                  <HeaderIconButtonIcon icon={MapIcon} />
                </HeaderIconButton>
              </Tooltip>
            )}

            <Tooltip
              title="Informate sobre las nuevas características y funciones de la plataforma."
              arrow
              placement="bottom"
            >
              <Badge
                color="error"
                variant="dot"
                overlap="circular"
                invisible={!existsNews}
              >
                <HeaderIconButton
                  variant="outlined"
                  color="secondary"
                  ref={menuNews.anchorElRefMenu}
                  aria-controls={menuNews.open ? 'news-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={menuNews.open ? 'true' : undefined}
                  onClick={() => menuNews.handleToggleOpenMenu(true)}
                  className={classNames({ pulse: existsNews })}
                >
                  <HeaderIconButtonIcon icon={NewsIcon} />
                </HeaderIconButton>
              </Badge>
            </Tooltip>

            {isCurrentAdminUser && (
              <Tooltip
                // eslint-disable-next-line max-len
                title="Te ayudamos a configurar la herramienta para que puedas sacar el máximo provecho."
                arrow
                placement="bottom"
              >
                <HeaderIconButton
                  variant="outlined"
                  color="primary"
                  withImage
                  className={classNames({
                    pulse: isCurrentUserActiveFirstSteps,
                  })}
                  ref={menuFirstSteps.anchorElRefMenu}
                  aria-controls={
                    menuFirstSteps.open ? 'first-steps-menu' : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={menuFirstSteps.open ? 'true' : undefined}
                  onClick={() => menuFirstSteps.handleToggleOpenMenu(true)}
                >
                  <HeaderIconButtonImg
                    src={toAbsoluteUrl('/icons/icon_compass.svg')}
                    alt="Compass Icon"
                  />
                </HeaderIconButton>
              </Tooltip>
            )}
          </>
        )}

        <HeaderButtonAvatar
          size="small"
          ref={menuProfile.anchorElRefMenu}
          aria-controls={menuProfile.open ? 'profile-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={menuProfile.open ? 'true' : undefined}
          onClick={() => menuProfile.handleToggleOpenMenu(true)}
        >
          <Avatar
            src={getDefaultAvatar(user?.avatarFile?.url)}
            alt={`${user?.lastName}, ${user?.firstName}`}
            sx={{ width: 38, height: 38 }}
          />
        </HeaderButtonAvatar>
      </HeaderCardInfoColumn>

      {dialogOrderSearch.open && (
        <DialogOrderSearch
          open={dialogOrderSearch.open}
          onClose={() => dialogOrderSearch.handleToggleOpenDialog(false)}
        />
      )}

      {menuActiveRoutes.open && (
        <ActiveRoutesMenu
          id="active-routes-menu"
          anchorEl={menuActiveRoutes.anchorElRefMenu.current}
          open={menuActiveRoutes.open}
          onClose={() => menuActiveRoutes.handleToggleOpenMenu(false)}
          arrow
          arrowColor="primary"
          placement="bottom"
        />
      )}

      {menuNews.open && (
        <NewsMenu
          id="news-menu"
          anchorEl={menuNews.anchorElRefMenu.current}
          open={menuNews.open}
          onClose={() => menuNews.handleToggleOpenMenu(false)}
          arrow
          arrowColor="primary"
          placement="bottom"
        />
      )}

      <FirstStepsMenu
        id="first-steps-menu"
        anchorEl={menuFirstSteps.anchorElRefMenu.current}
        open={menuFirstSteps.open}
        onClose={() => menuFirstSteps.handleToggleOpenMenu(false)}
        arrow
        arrowColor="primary"
        placement="bottom"
      />

      <ProfileMenu
        id="profile-menu"
        anchorEl={menuProfile.anchorElRefMenu.current}
        open={menuProfile.open}
        onClose={() => menuProfile.handleToggleOpenMenu(false)}
        placement="bottom"
      />
    </HeaderContainer>
  );
};

export default Header;
