import { ReactElement, useCallback } from 'react';
import { Stack, Tooltip, TextField } from '@mui/material';
import {
  faMagnifyingGlass as SearchIcon,
  faEraser as ClearIcon,
} from '@fortawesome/free-solid-svg-icons';

import { Subscription, CustomAutocomplete } from '~components/index';

import { useDialogOrderSearchContext } from './Context';
import { STATES_SELECT_OPTIONS, DEFAULT_PAGINATION } from './utils';
import {
  DialogOrderSearchFiltersInputs,
  DialogOrderSearchIconButton,
  DialogOrderSearchIconButtonIcon,
  DialogOrderSearchFilterChip,
} from './styles';

const DialogOrderSearchFilters = (): ReactElement => {
  const {
    filters: {
      filters,
      isAppliedFilter,
      handleChangeInputFilter,
      setFilterByKey,
      clearFilters,
      parseFiltersRequest,
      refStatesAutocomplete,
    },
    table: { handleChangeTable, handleInitialTable },
  } = useDialogOrderSearchContext();

  const handleChangeDate = useCallback(
    (fieldName: string) =>
      (value: Date | null): void => {
        setFilterByKey(fieldName, value);
      },
    [setFilterByKey],
  );

  const handleApplyFilter = useCallback(() => {
    handleChangeTable('filter', {
      pagination: DEFAULT_PAGINATION,
      filters: parseFiltersRequest,
    });
  }, [handleChangeTable, parseFiltersRequest]);

  const handleClearFilters = useCallback(() => {
    clearFilters();
    refStatesAutocomplete.current?.resetAutocomplete();
    handleInitialTable();
  }, [clearFilters, refStatesAutocomplete, handleInitialTable]);

  return (
    <Stack direction="row" spacing={2} justifyContent="flex-end">
      <DialogOrderSearchFiltersInputs>
        <Stack>
          <TextField
            fullWidth
            label="Nro. Orden / Nro. Tracking / Cod. Ruta / Grupo"
            name="search"
            value={filters.search ?? ''}
            onChange={handleChangeInputFilter}
          />
        </Stack>

        <Stack spacing={1} direction="row">
          <CustomAutocomplete
            innerRef={refStatesAutocomplete}
            multiple
            disableCloseOnSelect
            filterSelectedOptions
            limitTags={2}
            renderTags={(vals, getTagsProps) =>
              vals.map((val, index) => (
                // eslint-disable-next-line react/jsx-key
                <DialogOrderSearchFilterChip
                  label={val.label as string}
                  size="small"
                  {...getTagsProps({ index })}
                />
              ))
            }
            disableClearable
            options={STATES_SELECT_OPTIONS}
            label="Estado"
            name="states"
            onChange={(_e, vals) => {
              const parseVals = vals.map((val) => val.value);
              setFilterByKey('states', parseVals);
            }}
            fullWidth
            getOptionLabel={(option) => option.label as string}
          />

          <Subscription.DataRetentionMin.DatePicker
            label="Desde"
            value={filters.dateFrom ?? null}
            onChange={handleChangeDate('dateFrom')}
            renderInput={(props) => <TextField {...props} fullWidth />}
          />

          <Subscription.DataRetentionMin.DatePicker
            label="Hasta"
            value={filters.dateTo ?? null}
            onChange={handleChangeDate('dateTo')}
            renderInput={(props) => <TextField {...props} fullWidth />}
          />

          <Subscription.DataRetentionMin.DatePicker
            label="Fecha de vencimiento"
            value={filters.expirationDate ?? null}
            onChange={handleChangeDate('expirationDate')}
            renderInput={(props) => <TextField {...props} fullWidth />}
          />
        </Stack>
      </DialogOrderSearchFiltersInputs>

      <Stack spacing={1}>
        <Tooltip title="Buscar..." arrow>
          <span>
            <DialogOrderSearchIconButton
              color="secondary"
              variant="contained"
              disabled={!isAppliedFilter}
              onClick={handleApplyFilter}
            >
              <DialogOrderSearchIconButtonIcon icon={SearchIcon} />
            </DialogOrderSearchIconButton>
          </span>
        </Tooltip>

        <Tooltip title="Borrar filtros" arrow>
          <span>
            <DialogOrderSearchIconButton
              color="primary"
              variant="contained"
              disabled={!isAppliedFilter}
              onClick={handleClearFilters}
            >
              <DialogOrderSearchIconButtonIcon icon={ClearIcon} />
            </DialogOrderSearchIconButton>
          </span>
        </Tooltip>
      </Stack>
    </Stack>
  );
};

export default DialogOrderSearchFilters;
