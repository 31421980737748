import { useCallback, useState, ChangeEvent } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface FiltersValuesReturn<F extends Record<string, any>> {
  filters: F;
  isAppliedFilter?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFilterByKey: (key: string, newVal: any) => void;
  handleChangeInputFilter: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  clearFilters: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useFiltersValues = <F extends Record<string, any>>(
  initialFilters: F,
): FiltersValuesReturn<F> => {
  const [filters, setFilters] = useState(initialFilters);
  const [isAppliedFilter, setIsAppliedFilter] = useState(false);

  const setFilterByKey = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (key: string, newVal: any) => {
      setFilters((prevFilters) => ({ ...prevFilters, [key]: newVal }));
      setIsAppliedFilter(true);
    },
    [],
  );

  const handleChangeInputFilter = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = event.target ?? event.currentTarget;

      setFilterByKey(name, value);
      setIsAppliedFilter(true);
    },
    [setFilterByKey],
  );

  const clearFilters = useCallback(() => {
    setFilters(initialFilters);
    setIsAppliedFilter(false);
  }, [initialFilters]);

  return {
    filters,
    isAppliedFilter,
    setFilterByKey,
    handleChangeInputFilter,
    clearFilters,
  };
};
