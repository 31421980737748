import { ItemSearchFilter } from '~services/item/types';
import { SelectOption } from '~globals/types';
import { RouteItemStateType, ItemStateTypes } from '~globals/types/enums';

import { DialogOrderSearchContextFiltersData } from './types';

export const ITEM_STATE_KEY = 'itemState';

export const ROUTE_ITEM_STATE_KEY = 'routeItemState';

export const ORDER_KEY_ID = 'itemId';

export const DEFAULT_PAGINATION = {
  page: 1,
  sizePerPage: 50,
};

export const DEFAULT_FILTERS_STATE: DialogOrderSearchContextFiltersData = {
  dateFrom: null,
  dateTo: null,
  driver: '',
  search: '',
  expirationDate: null,
  states: [],
};

export const DEFAULT_FILTERS_PAYLOAD: ItemSearchFilter = {
  dateFrom: null,
  dateTo: null,
  driverId: null,
  routeCode: null,
  routeItemStateTypesId: [],
  maxDeliveredDateTime: null,
  itemStateTypesId: [],
};

export const STATES_SELECT_OPTIONS: SelectOption[] = [
  {
    label: 'Nuevo',
    value: `${ITEM_STATE_KEY}-${ItemStateTypes.New}`,
  },
  {
    label: 'A Colectar',
    value: `${ITEM_STATE_KEY}-${ItemStateTypes.ToValidate}`,
  },
  {
    label: 'Colectado',
    value: `${ITEM_STATE_KEY}-${ItemStateTypes.Validated}`,
  },
  {
    label: 'En agenda',
    value: `${ROUTE_ITEM_STATE_KEY}-${RouteItemStateType.OnAgenda}`,
  },
  {
    label: 'En proceso',
    value: `${ROUTE_ITEM_STATE_KEY}-${RouteItemStateType.InProgress}`,
  },
  {
    label: 'Completa',
    value: `${ROUTE_ITEM_STATE_KEY}-${RouteItemStateType.FinalizedSuccess}`,
  },
  {
    label: 'Incompleta',
    value: `${ROUTE_ITEM_STATE_KEY}-${RouteItemStateType.FinalizedError}`,
  },
  {
    label: 'Sin visitar',
    value: `${ROUTE_ITEM_STATE_KEY}-${RouteItemStateType.WithoutVisiting}`,
  },
];
