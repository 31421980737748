import { ReactElement, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from '~hooks/index';
import { selectAuth } from '~store/slices/auth/index';
import { isAdminUser } from '~utils/user';
import { getStringifyUrlQueryString } from '~utils/router';
import { PATHS } from '~constants/paths';

import { ProToPlusDiscountChipRoot } from './styles';

const ProToPlusDiscountChip = (): ReactElement => {
  const navigate = useNavigate();
  const { user } = useAppSelector(selectAuth);

  const handleClick = useMemo(() => {
    if (!isAdminUser(user)) return undefined;

    return () => {
      const url = getStringifyUrlQueryString({
        url: PATHS.CONFIG.SUBSCRIPTIONS.BASE,
        query: {
          currentTab: 'plans-packages',
        },
      });

      navigate(url);
    };
  }, [navigate, user]);

  return (
    <ProToPlusDiscountChipRoot
      label={
        <>
          <b>OFERTA LIMITADA</b> Cambia a plan plus con <b>50% OFF</b>
        </>
      }
      size="small"
      variant="filled"
      onClick={handleClick}
    />
  );
};

export default ProToPlusDiscountChip;
