import { ReactElement, useState } from 'react';
import {
  LateralMenuListItemIconText,
  LateralMenuListItemLinkClick,
  LateralMenuListItemRoot,
} from '../ListItem/styles';
import { Feedback as FeedbackIcon } from '~components/Icons';
import { DialogCustomerFeedback } from '~app/components';

import { classes, LateralMenuTooltip } from '../styles';

export const CustomerFeedbackItem = (): ReactElement => {
  const [open, setOpen] = useState(false);

  const toggleDialog = () => setOpen((prev) => !prev);

  return (
    <>
      <LateralMenuTooltip title="Ayúdanos a mejorar!">
        <LateralMenuListItemRoot>
          <LateralMenuListItemLinkClick onClick={toggleDialog}>
            <FeedbackIcon className={classes.icon} />

            <LateralMenuListItemIconText
              variant="inherit"
              color="inherit"
              className={classes.iconText}
            >
              Comentarios
            </LateralMenuListItemIconText>
          </LateralMenuListItemLinkClick>
        </LateralMenuListItemRoot>
      </LateralMenuTooltip>

      <DialogCustomerFeedback open={open} onClose={toggleDialog} />
    </>
  );
};
