import { ReactElement, useCallback, useMemo } from 'react';
import {
  Stack,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from '@mui/material';
import { useFormikContext } from 'formik';

import { MANAGEMENT_OPERATIONS_OPTIONS } from '~constants/businessProfiles';
import { BusinessProfileManagementOperationTypes } from '~globals/types/enums';

import { useBusinessProfileTabsFormContext } from '../../BusinesProfileTabsFormContext';
import { BusinessProfileTabsFormContextPanelsId } from '../../types';
import { FIELDS_NAMES, initialValues } from '../../utils';

const BusinessProfileOperationType = (): ReactElement => {
  const { handleNextTab } = useBusinessProfileTabsFormContext();

  const { values, getFieldProps } = useFormikContext<typeof initialValues>();

  const operationTypeValue = useMemo(
    () => values[FIELDS_NAMES.OPERATION_TYPE],
    [values],
  );

  const onNextTab = useCallback(() => {
    let nextTab: BusinessProfileTabsFormContextPanelsId =
      'orderDistributionShipment';

    if (operationTypeValue === BusinessProfileManagementOperationTypes.OnlySell)
      nextTab = 'descriptionConfirm';

    if (
      operationTypeValue === BusinessProfileManagementOperationTypes.NotSell
    ) {
      nextTab = 'orderManagementCollect';
    }

    handleNextTab(nextTab);
  }, [operationTypeValue, handleNextTab]);

  const isDisabledNextButton = useMemo(
    () => !operationTypeValue,
    [operationTypeValue],
  );

  return (
    <Stack spacing={2} flex={1} justifyContent="space-between">
      <Stack spacing={1}>
        <Typography variant="subtitle1" fontWeight="bold">
          ¿Qué tipo de operaciones gestiona su negocio?
        </Typography>

        <RadioGroup {...getFieldProps(FIELDS_NAMES.OPERATION_TYPE)}>
          {MANAGEMENT_OPERATIONS_OPTIONS.map((option) => (
            <FormControlLabel
              key={`management-operation-${option.value}`}
              value={option.value}
              control={<Radio />}
              label={option.label}
            />
          ))}
        </RadioGroup>
      </Stack>

      <Stack direction="row" spacing={2} justifyContent="flex-end">
        <Button
          variant="outlined"
          color="primary"
          onClick={onNextTab}
          disabled={isDisabledNextButton}
        >
          Continuar
        </Button>
      </Stack>
    </Stack>
  );
};

export default BusinessProfileOperationType;
