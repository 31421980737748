import { ReactElement, useMemo, memo } from 'react';
import { CircularProgress } from '@mui/material';
import {
  Delete as DeleteIcon,
  LinkOff as UnLinkIcon,
} from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faRoute as RouteIcon,
  faClone as DuplicateIcon,
  faBarcode as BardCodeIcon,
  faMapMarkerAlt as FinalizeStopIcon,
  faHistory as MarkAsPendingIcon,
  faFileArrowDown as ExportListIcon,
  faCommentDots as ModifyEvidenceIcon,
} from '@fortawesome/free-solid-svg-icons';
import { isNull } from 'lodash';

import { MenuActionsDataTable } from '~components/index';
import {
  MenuActionsDataTableOptions,
  MenuActionsDataTableOptionExtraItem,
} from '~components/MenuActionsDataTable/types';
import { ShowIcon } from '~components/MenuActionsDataTable/styles';
import { useAppSelector, useTypePartnerCompany } from '~hooks/index';
import { selectAuth } from '~store/slices/auth/index';
import { ItemExtended } from '~services/item/types';
import { PATHS } from '~constants/index';
import { isOnlyReadUser } from '~utils/user';
import { isPendingOrder, isOnAgendaOrder } from '~utils/order';
import { openUrlInNewTab } from '~utils/commons';
import { replaceParamsPath, getStringifyUrlQueryString } from '~utils/router';
import { isCurrentLoggedCompany } from '~utils/partnerCompany';
import { getFeatureAvailableToDate } from '~utils/feature';
import { isRouteStopOrderFinalized } from '~utils/route';
import { validatePartnerItemCollectStateType } from '~utils/partnerItem';
import { Nullable } from '~globals/types';
import {
  PlanFeaturesTypes,
  CollectItemStateTypes,
  RouteItemStateType,
} from '~globals/types/enums';

import { ORDER_KEY_ID } from './utils';

interface DialogOrderSearchTableActionsMenuProps {
  data: ItemExtended;
  actions: {
    handleOpenDialogFeedback: (
      routeItemId: string,
      isCollect: Nullable<boolean>,
    ) => void;
    handleExportTicket: (trackingCode: string) => void;
    handleExportTicketMl: (itemId: string) => void;
    handleOpenDialogFinalizedStop: (data: ItemExtended) => void;
    handleOpenDialogConfirmationMarkAsPending: (itemId: string) => void;
    handleDowloadEvidencesOrderPDF: (trackingCode: string) => void;
    handleOpenDialogModifyEvidence: (data: ItemExtended) => void;
    handleOpenDialogCancelOrder: (data: ItemExtended) => void;
    handleOpenConfirmDeleteOrder: (data: ItemExtended) => void;
  };
  loadings: {
    loadingExportTicket: boolean;
    loadingExportTicketMl: boolean;
    loadingDowloadEvidencesOrderPDF: boolean;
  };
}

const DialogOrderSearchTableActionsMenu = ({
  data,
  actions,
  loadings,
}: DialogOrderSearchTableActionsMenuProps): ReactElement => {
  const { user } = useAppSelector(selectAuth);
  const { isCarrier } = useTypePartnerCompany();

  const isUserOnlyReader = useMemo(() => isOnlyReadUser(user), [user]);

  const items = useMemo<MenuActionsDataTableOptions>(() => {
    let enabledEdit = !isUserOnlyReader && data.canModify;

    if (!isPendingOrder(data) && !isOnAgendaOrder(data)) {
      enabledEdit = false;
    }

    return {
      show: {
        enabled: true,
        divider: !enabledEdit,
        onClick: (path) => {
          openUrlInNewTab(path);
        },
      },
      edit: {
        enabled: enabledEdit,
        divider: true,
        onClick: (path) => {
          openUrlInNewTab(path);
        },
      },
      delete: { enabled: false },
    };
  }, [isUserOnlyReader, data]);

  const isOwnItem = useMemo(() => isNull(data.carrierCompanyId), [data]);
  const isOwnCompany = useMemo(
    () =>
      isCurrentLoggedCompany(data.carrierCompanyId, user?.companyId ?? null),
    [data, user],
  );

  const extraItems = useMemo<MenuActionsDataTableOptionExtraItem[]>(() => {
    let enabledShowRoute = false;
    let enabledShowTracking = false;
    let enabledShowFeedback = false;
    let enabledExportTicket = true;
    let enabledExportTicketMl = data.sourceImportTypeId === 5;
    const enabledDuplicate = !isUserOnlyReader && data.canModify;
    const enabledFinalizedStop =
      !isUserOnlyReader &&
      data.routeItemStateTypeId === RouteItemStateType.InProgress;
    let enabledMarkAsPending = false;
    let enabledDownloadDeliveryTicket = false;
    let enabledModifyEvidence = false;
    let enabledCancel =
      !isUserOnlyReader &&
      ((isCarrier && !isOwnItem) ||
        validatePartnerItemCollectStateType(data.collectItemStateTypeId, [
          CollectItemStateTypes.ToValidate,
          CollectItemStateTypes.ToCollect,
          CollectItemStateTypes.NotValidated,
        ]));
    let enabledDeleted =
      !isUserOnlyReader &&
      data.canDelete &&
      (isOwnItem ||
        validatePartnerItemCollectStateType(data.collectItemStateTypeId, [
          CollectItemStateTypes.ToValidate,
          CollectItemStateTypes.ToCollect,
          CollectItemStateTypes.NotValidated,
        ]));

    if (!isPendingOrder(data)) {
      enabledShowRoute = isCarrier && (isOwnItem || isOwnCompany);
      enabledShowTracking =
        data.routeItemStateTypeId === RouteItemStateType.InProgress;
      enabledExportTicketMl = false;
      enabledCancel = false;
      enabledDeleted = false;

      if (!isOnAgendaOrder(data)) {
        enabledShowFeedback = true;
        enabledExportTicket = false;
        enabledMarkAsPending = !isUserOnlyReader && data.canModify;
        enabledDownloadDeliveryTicket = isRouteStopOrderFinalized(
          data.routeItemStateTypeId as RouteItemStateType,
        );
        enabledModifyEvidence = !isUserOnlyReader;
      }
    }

    return [
      {
        enabled: enabledShowRoute,
        label: 'Ver ruta',
        Icon: <ShowIcon />,
        onClick: () => {
          const urlShowRoute = replaceParamsPath(
            PATHS.ROUTES.VEHICLE_PLANNING,
            {
              ':apiTransactionId': data.apiTransactionId as string,
              ':routeId': data.routeId as string,
            },
          );

          openUrlInNewTab(urlShowRoute);
        },
        divider: !enabledShowTracking && !enabledShowFeedback,
      },
      {
        enabled: enabledShowTracking,
        label: 'Ver seguimiento de órden',
        Icon: <FontAwesomeIcon icon={RouteIcon} />,
        onClick: () => {
          // eslint-disable-next-line max-len
          const urlShowTracking = `https://track.routix.io/company/${user?.companyId}/order/${data.routeItemId}`;

          openUrlInNewTab(urlShowTracking);
        },
        divider: true,
        validSubscriptionFeature: {
          validFeature: PlanFeaturesTypes.TrackingPortalModule,
        },
      },
      {
        enabled: enabledShowFeedback,
        label: 'Ver detalles de visita',
        disabled: !data.hasFeedback,
        Icon: <ShowIcon />,
        onClick: () =>
          actions.handleOpenDialogFeedback(
            data.routeItemId as string,
            data.isCollected,
          ),
        divider: true,
      },
      {
        enabled: enabledExportTicket,
        label: 'Exportar etiqueta',
        Icon: loadings.loadingExportTicket ? (
          <CircularProgress color="primary" disableShrink size={20} />
        ) : (
          <FontAwesomeIcon icon={BardCodeIcon} />
        ),
        onClick: () => actions.handleExportTicket(data.trackingCode),
        divider:
          (enabledDuplicate || enabledDeleted || enabledCancel) &&
          !enabledExportTicketMl,
        validSubscriptionFeature: {
          validFeature: PlanFeaturesTypes.MgmGenerateTickets,
          availableToDate: getFeatureAvailableToDate(
            PlanFeaturesTypes.MgmGenerateTickets,
          ),
        },
      },
      {
        enabled: enabledExportTicketMl,
        label: 'Exportar etiqueta de Mercado Libre',
        Icon: loadings.loadingExportTicketMl ? (
          <CircularProgress color="primary" disableShrink size={20} />
        ) : (
          <FontAwesomeIcon icon={BardCodeIcon} />
        ),
        onClick: () => actions.handleExportTicketMl(data.itemId),
        divider: enabledDuplicate || enabledDeleted || enabledCancel,
        validSubscriptionFeature: {
          validFeature: PlanFeaturesTypes.MgmGenerateTickets,
          availableToDate: getFeatureAvailableToDate(
            PlanFeaturesTypes.MgmGenerateTickets,
          ),
        },
      },
      {
        enabled: enabledDuplicate,
        label: 'Duplicar',
        Icon: <FontAwesomeIcon icon={DuplicateIcon} />,
        onClick: () => {
          const urlDuplcatedOrder = getStringifyUrlQueryString({
            url: PATHS.ORDERS.NEW,
            query: {
              duplicateById: data.itemId,
            },
          });

          openUrlInNewTab(urlDuplcatedOrder);
        },
        divider: enabledCancel || enabledDeleted,
      },
      {
        enabled: enabledFinalizedStop,
        label: 'Finalizar parada',
        Icon: <FontAwesomeIcon icon={FinalizeStopIcon} />,
        onClick: () => actions.handleOpenDialogFinalizedStop(data),
      },
      {
        enabled: enabledMarkAsPending,
        label: 'Marcar como pendiente',
        Icon: <FontAwesomeIcon icon={MarkAsPendingIcon} />,
        onClick: () =>
          actions.handleOpenDialogConfirmationMarkAsPending(data.itemId),
        divider: true,
      },
      {
        enabled: enabledDownloadDeliveryTicket,
        label: 'Descargar comprobante de visita',
        Icon: loadings.loadingDowloadEvidencesOrderPDF ? (
          <CircularProgress color="primary" disableShrink size={20} />
        ) : (
          <FontAwesomeIcon icon={ExportListIcon} />
        ),
        onClick: () =>
          actions.handleDowloadEvidencesOrderPDF(data.trackingCode),
        validSubscriptionFeature: {
          validFeature: PlanFeaturesTypes.TrackingEPOD,
        },
      },
      {
        enabled: enabledModifyEvidence,
        label: 'Modificar evidencia',
        Icon: <FontAwesomeIcon icon={ModifyEvidenceIcon} />,
        onClick: () => actions.handleOpenDialogModifyEvidence(data),
      },
      {
        enabled: enabledCancel,
        label: 'Desasignar',
        Icon: <UnLinkIcon color="error" />,
        onClick: () => actions.handleOpenDialogCancelOrder(data),
      },
      {
        enabled: enabledDeleted,
        label: 'Eliminar',
        Icon: <DeleteIcon color="error" />,
        onClick: () => actions.handleOpenConfirmDeleteOrder(data),
      },
    ];
  }, [
    data,
    isCarrier,
    isOwnCompany,
    isOwnItem,
    user,
    isUserOnlyReader,
    actions,
    loadings,
  ]);

  return (
    <MenuActionsDataTable
      resource={{
        path: PATHS.ORDERS.BASE,
        id: data[ORDER_KEY_ID],
      }}
      items={items}
      extraItems={extraItems}
    />
  );
};

export default memo(DialogOrderSearchTableActionsMenu);
