import { styled, Chip, chipClasses } from '@mui/material';

export const ProToPlusDiscountChipRoot = styled(Chip)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: theme.typography.pxToRem(11),
  height: 24,
  borderRadius: 24 / 2,
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.main,

  [`&.${chipClasses.clickable}:hover`]: {
    color: theme.palette.primary.main,
  },

  [`&.${chipClasses.sizeSmall}`]: {
    fontSize: theme.typography.pxToRem(10),
    height: 16,
  },
}));
