import { ReactElement, useCallback, useMemo } from 'react';
import { get, join } from 'lodash';
import moment from 'moment';

import { Table, TimeWindowInfo } from '~components/index';
import { TableColumn } from '~components/Table/types';
import { formatterDate } from '~utils/index';
import { Nullable } from '~globals/types';

import { FIELDS_NAME, EXTRACTOR_KEY_VALUE, isVisibleRow } from '../utils';

import {
  DialogOrdersMultipeEditionPreviewModificationsProps,
  DialogOrdersMultipeEditionPreviewModificationsItem,
} from './types';
import { DialogOrderEmailsChip, DialogOrderEmailsChipWrapper } from '../styles';

const DialogOrdersMultipeEditionPreviewModifications = ({
  values,
}: DialogOrdersMultipeEditionPreviewModificationsProps): ReactElement => {
  const parseTimeWindowValue = useCallback((value: Nullable<Date>) => {
    if (!value) return null;

    return formatterDate(value, { format: moment.HTML5_FMT.TIME_SECONDS });
  }, []);

  const data = useMemo<
    DialogOrdersMultipeEditionPreviewModificationsItem[]
  >(() => {
    const rows: DialogOrdersMultipeEditionPreviewModificationsItem[] = [];

    if (isVisibleRow(values, FIELDS_NAME.CLIENT_NAME)) {
      rows.push({
        id: FIELDS_NAME.CLIENT_NAME,
        field: 'Nombre de contacto',
        value: get(values, FIELDS_NAME.CLIENT_NAME),
      });
    }

    if (isVisibleRow(values, FIELDS_NAME.ADDRESS_WAIT_TIME)) {
      rows.push({
        id: FIELDS_NAME.ADDRESS_WAIT_TIME,
        field: 'Tiempo estimado en parada',
        value: get(values, FIELDS_NAME.ADDRESS_WAIT_TIME),
        formatter: (value) => `${value} min`,
      });
    }

    if (isVisibleRow(values, FIELDS_NAME.TIME_WINDOW)) {
      rows.push({
        id: FIELDS_NAME.TIME_WINDOW,
        field: 'Ventana horaria de visita',
        value: {
          allDay: get(values, FIELDS_NAME.TIME_WINDOW_ALL_DAY),
          windowFrom1: parseTimeWindowValue(
            get(values, FIELDS_NAME.TIME_WINDOW_ONE_FROM),
          ),
          windowTo1: parseTimeWindowValue(
            get(values, FIELDS_NAME.TIME_WINDOW_ONE_TO),
          ),
          windowFrom2: parseTimeWindowValue(
            get(values, FIELDS_NAME.TIME_WINDOW_SECOND_FROM),
          ),
          windowTo2: parseTimeWindowValue(
            get(values, FIELDS_NAME.TIME_WINDOW_SECOND_TO),
          ),
        },
        formatter: (value) => <TimeWindowInfo {...value} />,
      });
    }

    if (isVisibleRow(values, FIELDS_NAME.DELIVERY_EXPIRATION_DATE)) {
      rows.push({
        id: FIELDS_NAME.DELIVERY_EXPIRATION_DATE,
        field: 'Fecha de vencimiento',
        value: get(values, FIELDS_NAME.DELIVERY_EXPIRATION_DATE),
        formatter: (value) =>
          value ? (
            formatterDate(value, { format: 'DD/MM/YYYY' })
          ) : (
            <b>Contenido eliminado</b>
          ),
      });
    }

    if (isVisibleRow(values, FIELDS_NAME.DELIVERY_TAGS)) {
      rows.push({
        id: FIELDS_NAME.DELIVERY_TAGS,
        field: 'Grupo',
        value: get(values, FIELDS_NAME.DELIVERY_TAGS),
        formatter: (value) => (value ? value : <b>Contenido eliminado</b>),
      });
    }

    if (isVisibleRow(values, FIELDS_NAME.DELIVERY_SKILLS_NEEDED)) {
      rows.push({
        id: FIELDS_NAME.DELIVERY_SKILLS_NEEDED,
        field: 'Cargas especiales o atributos requeridos',
        value: get(values, FIELDS_NAME.DELIVERY_SKILLS_NEEDED),
        formatter: (value) =>
          value.length > 0 ? join(value, ', ') : <b>Contenido eliminado</b>,
      });
    }

    if (isVisibleRow(values, FIELDS_NAME.CUSTOM_FIELD_1)) {
      rows.push({
        id: FIELDS_NAME.CUSTOM_FIELD_1,
        field: 'Campo personalizado 1',
        value: get(values, FIELDS_NAME.CUSTOM_FIELD_1),
        formatter: (value) => (value ? value : <b>Contenido eliminado</b>),
      });
    }

    if (isVisibleRow(values, FIELDS_NAME.CUSTOM_FIELD_2)) {
      rows.push({
        id: FIELDS_NAME.CUSTOM_FIELD_2,
        field: 'Campo personalizado 2',
        value: get(values, FIELDS_NAME.CUSTOM_FIELD_2),
        formatter: (value) => (value ? value : <b>Contenido eliminado</b>),
      });
    }

    if (isVisibleRow(values, FIELDS_NAME.CUSTOM_FIELD_3)) {
      rows.push({
        id: FIELDS_NAME.CUSTOM_FIELD_3,
        field: 'Campo personalizado 3',
        value: get(values, FIELDS_NAME.CUSTOM_FIELD_3),
        formatter: (value) => (value ? value : <b>Contenido eliminado</b>),
      });
    }

    if (isVisibleRow(values, FIELDS_NAME.SELLER_MAIL)) {
      rows.push({
        id: FIELDS_NAME.SELLER_MAIL,
        field: 'Emails para notificaciones del estado de la orden',
        value: get(values, FIELDS_NAME.SELLER_MAIL),
        formatter: (value: string) => (
          <DialogOrderEmailsChipWrapper
            direction={'row'}
            style={{ marginTop: 8 }}
          >
            {value.split(',')?.map((val, index) => (
              <DialogOrderEmailsChip
                key={`wrapper-tag-${index}`}
                label={val}
                size="small"
              />
            ))}
          </DialogOrderEmailsChipWrapper>
        ),
      });
    }

    if (isVisibleRow(values, FIELDS_NAME.STORE)) {
      rows.push({
        id: FIELDS_NAME.STORE,
        field: 'Tienda',
        value: get(values, FIELDS_NAME.STORE),
        formatter: (value) => (value ? value : <b>Contenido eliminado</b>),
      });
    }

    return rows;
  }, [values, parseTimeWindowValue]);

  const columns = useMemo<
    TableColumn<DialogOrdersMultipeEditionPreviewModificationsItem>[]
  >(
    () => [
      {
        dataField: 'field',
        text: 'Nombre',
        classes: {
          cell: 'truncated-two-lines',
        },
        formatters: {
          cell: (cell) => <span>{cell}</span>,
        },
        style: {
          header: { width: 300 },
        },
      },
      {
        dataField: 'value',
        text: 'Valor',
        formatters: {
          cell: (cell, row) => (
            <span>{row.formatter ? row.formatter(cell) : cell}</span>
          ),
        },
        classes: {
          cell: 'truncated-two-lines',
        },
      },
    ],
    [],
  );

  return (
    <Table
      data={data}
      columns={columns}
      extractorKeyValue={EXTRACTOR_KEY_VALUE}
    />
  );
};

export default DialogOrdersMultipeEditionPreviewModifications;
