import { ReactElement } from 'react';
import { Stack } from '@mui/material';
import ReactMarkdown from 'react-markdown';

import { MarkdownProps } from './types';
import { REMARK_PLUGINS, REHYPE_PLUGINS, CUSTOM_COMPONENTS } from './utils';

const Markdown = ({ source }: MarkdownProps): ReactElement => (
  <Stack spacing={1}>
    <ReactMarkdown
      remarkPlugins={REMARK_PLUGINS}
      rehypePlugins={REHYPE_PLUGINS}
      components={CUSTOM_COMPONENTS}
    >
      {source}
    </ReactMarkdown>
  </Stack>
);

export default Markdown;
