import { styled, Alert } from '@mui/material';

export const MapSequenceStopsInfoContainer = styled(Alert)(({ theme }) => ({
  ...theme.typography.caption,
  position: 'absolute',
  right: '50%',
  left: '50%',
  transform: 'translateX(-50%)',
  width: '100%',
  maxWidth: 450,
  bottom: 20,
  border: `1px solid ${theme.palette.info.light}`,
  boxShadow: '0 0 0 2px rgb(0 0 0 / 10%)',
  zIndex: 1,
  padding: theme.spacing(0, 2),
  justifyContent: 'center',
}));
