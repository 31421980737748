import { FC, createContext, useContext } from 'react';

import { useToggle } from '~hooks/index';

interface BannersContextValue {
  open: boolean;
  toggleOpen: (nextValue?: boolean) => void;
}

const BannersContext = createContext<BannersContextValue>({
  open: false,
  toggleOpen: () => {},
});

export const useBannersContext = (): BannersContextValue =>
  useContext(BannersContext);

const BannersProvider: FC = ({ children }) => {
  const [open, toggleOpen] = useToggle(false);

  return (
    <BannersContext.Provider value={{ open, toggleOpen }}>
      {children}
    </BannersContext.Provider>
  );
};

export default BannersProvider;
