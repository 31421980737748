import {
  ReactElement,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useFormikContext } from 'formik';
import {
  Typography,
  Stack,
  AccordionSummary,
  AccordionDetails,
  TextField,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { faMagnifyingGlass as SearchIcon } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';
import { throttle } from 'lodash';

import { PlacesAutocomplete, DialogSearchCustomers } from '~components/index';
import { hasError } from '~utils/formHelpers';
import { useToggle } from '~hooks/index';
import { PlaceType } from '~hooks/usePlacesAutocomplete';
import { CustomerBase } from '~services/customer/types';

import {
  FIELDS_NAME,
  getInitialValues,
  parseTimeWindowValue,
} from '../../utils';
import { useOrderFormContext } from '../../OrderFormContext';
import { OrderFormSectionContainer } from '../../styles';
import { OrderFormIconButton, OrderFormIconButtonIcon } from './styles';

const OrderFormDestinationInformationSection = (): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    values,
    errors,
    touched,
    getFieldProps,
    setFieldValue,
    setFieldTouched,
    setValues,
  } = useFormikContext<ReturnType<typeof getInitialValues>>();

  const {
    itemData,
    refs: { placesAutocompleteRef },
  } = useOrderFormContext();

  const [openDialogSearchCustomer, toggleOpenDialogSearchCustomer] =
    useToggle();

  const getCoordAddres = useMemo(
    () =>
      throttle<google.maps.Geocoder['geocode']>((request, callback) => {
        new window.google.maps.Geocoder().geocode(request, callback);
      }, 200),
    [],
  );

  const handleChangeAddress = useCallback(
    (_e: SyntheticEvent<Element, Event>, newValue: PlaceType | null) => {
      setFieldValue(FIELDS_NAME.ADDRESS, newValue?.description ?? '');
      setFieldValue(FIELDS_NAME.ADDRESS_COORDS, null);

      getCoordAddres({ placeId: newValue?.place_id }, (results) => {
        if (results) {
          const addresCoords = results[0].geometry.location.toJSON();

          setFieldValue(FIELDS_NAME.ADDRESS_COORDS, addresCoords);

          const zipCode = results[0].address_components
            .find((addressComponent) =>
              addressComponent.types.includes('postal_code'),
            )
            ?.long_name.replace(/\D/g, '');

          if (zipCode) {
            setFieldValue(FIELDS_NAME.ZIP_CODE, zipCode);
          }
        }
      });
    },
    [getCoordAddres, setFieldValue],
  );

  const handleBlurAddress = useCallback((): void => {
    setFieldTouched(FIELDS_NAME.ADDRESS, true);
  }, [setFieldTouched]);

  const [
    initialValueRequestPlacesAutocomplete,
    setInitialValueRequestPlacesAutocomplete,
  ] = useState(itemData?.address);

  const handleSelectCustomer = useCallback(
    (customer: CustomerBase) => {
      toggleOpenDialogSearchCustomer(false);

      setTimeout(() => {
        setValues({
          ...values,
          [FIELDS_NAME.CLIENT_NAME]: customer.legalName,
          [FIELDS_NAME.CLIENT_COD]: customer.code ?? '',
          [FIELDS_NAME.ADDRESS]: customer.address,
          [FIELDS_NAME.ADDRESS_COORDS]: {
            lat: customer.latitude,
            lng: customer.longitude,
          },
          [FIELDS_NAME.ADDRESS_NOTES]: customer.addressNotes ?? '',
          [FIELDS_NAME.ADDRESS_ACCESS_COD]: customer.accessCode ?? '',
          [FIELDS_NAME.CONTAC_FULL_NAME]: customer.contactName ?? '',
          [FIELDS_NAME.CONTACT_DOCUMENT]: customer.contactIdentification ?? '',
          [FIELDS_NAME.CONTACT_PHONE]: customer.contactPhone ?? '',
          [FIELDS_NAME.CONTACT_CELL_PHONE]: customer.contactMobile ?? '',
          [FIELDS_NAME.CONTACT_EMAIL]: customer.contactEmail ?? '',
          [FIELDS_NAME.CUSTOM_FIELD1]: customer.customField1 ?? '',
          [FIELDS_NAME.CUSTOM_FIELD2]: customer.customField2 ?? '',
          [FIELDS_NAME.CUSTOM_FIELD3]: customer.customField3 ?? '',
          [FIELDS_NAME.DELIVERY_APPROVE_DOCUMENT]:
            customer.validateContactIdentification ?? false,
          [FIELDS_NAME.TIME_WINDOW_ONE_FROM]: parseTimeWindowValue(
            customer.timeWindowFrom1 ?? null,
          ),
          [FIELDS_NAME.TIME_WINDOW_ONE_TO]: parseTimeWindowValue(
            customer.timeWindowTo1 ?? null,
          ),
          [FIELDS_NAME.TIME_WINDOW_SECOND_FROM]: parseTimeWindowValue(
            customer.timeWindowFrom2 ?? null,
          ),
          [FIELDS_NAME.TIME_WINDOW_SECOND_TO]: parseTimeWindowValue(
            customer.timeWindowTo2 ?? null,
          ),
          [FIELDS_NAME.TIME_WINDOW_ALL_DAY]: customer.timeWindowAllDay ?? false,
          [FIELDS_NAME.ZIP_CODE]: customer.zipCode ?? '',
        });

        setInitialValueRequestPlacesAutocomplete(customer.address);

        enqueueSnackbar('Contacto frecuente cargado correctamente', {
          variant: 'success',
        });
      }, 200);
    },
    [setValues, values, toggleOpenDialogSearchCustomer, enqueueSnackbar],
  );

  return (
    <OrderFormSectionContainer
      defaultExpanded
      disableGutters
      elevation={0}
      variant="outlined"
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel-order-form-destionation-information-content"
        id="panel-order-form-destionation-information-header"
      >
        <Typography component="h6" fontWeight="bold">
          Información de destino
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2}>
            <TextField
              label="Nombre de contacto"
              fullWidth
              autoComplete="off"
              error={hasError(touched, errors, FIELDS_NAME.CLIENT_NAME)}
              {...getFieldProps(FIELDS_NAME.CLIENT_NAME)}
            />

            <OrderFormIconButton
              color="primary"
              variant="contained"
              startIcon={<OrderFormIconButtonIcon icon={SearchIcon} />}
              onClick={() => toggleOpenDialogSearchCustomer(true)}
            >
              Buscar Contacto Frecuente
            </OrderFormIconButton>
          </Stack>

          <Stack direction="row" spacing={2}>
            <TextField
              label="Código de contacto"
              autoComplete="off"
              error={hasError(touched, errors, FIELDS_NAME.CLIENT_COD)}
              {...getFieldProps(FIELDS_NAME.CLIENT_COD)}
            />

            <FormControlLabel
              control={
                <Switch
                  {...getFieldProps(FIELDS_NAME.CLIENT_SCHEDULE)}
                  checked={values[FIELDS_NAME.CLIENT_SCHEDULE]}
                />
              }
              label="Agendar Contacto Frecuente"
            />
          </Stack>

          <Stack direction="row" spacing={2}>
            <PlacesAutocomplete
              ref={placesAutocompleteRef}
              id="add-order-places-autcomplete"
              onChange={handleChangeAddress}
              onBlur={handleBlurAddress}
              error={hasError(touched, errors, FIELDS_NAME.ADDRESS)}
              initialValueRequest={initialValueRequestPlacesAutocomplete}
              fullWidth
            />

            <TextField
              label="Código postal (opcional)"
              sx={{ width: '40%' }}
              error={hasError(touched, errors, FIELDS_NAME.ZIP_CODE)}
              {...getFieldProps(FIELDS_NAME.ZIP_CODE)}
            />
          </Stack>

          <TextField
            label="Notas de dirección (piso, depto, instrucciones)"
            fullWidth
            multiline
            rows={3}
            error={hasError(touched, errors, FIELDS_NAME.ADDRESS_NOTES)}
            {...getFieldProps(FIELDS_NAME.ADDRESS_NOTES)}
          />

          <Stack direction="row" spacing={2}>
            <TextField
              label="Tienda (opcional)"
              fullWidth
              error={hasError(touched, errors, FIELDS_NAME.STORE)}
              {...getFieldProps(FIELDS_NAME.STORE)}
            />

            <TextField
              label="Código de acceso (opcional)"
              fullWidth
              error={hasError(touched, errors, FIELDS_NAME.ADDRESS_ACCESS_COD)}
              {...getFieldProps(FIELDS_NAME.ADDRESS_ACCESS_COD)}
            />

            <TextField
              label="Tiempo estimado en parada (minutos)"
              fullWidth
              autoComplete="off"
              type="number"
              error={hasError(touched, errors, FIELDS_NAME.ADDRESS_WAIT_TIME)}
              {...getFieldProps(FIELDS_NAME.ADDRESS_WAIT_TIME)}
            />
          </Stack>

          <Stack direction="row" spacing={2}>
            <TextField
              label="Personalizado 1 (opcional)"
              fullWidth
              error={hasError(touched, errors, FIELDS_NAME.CUSTOM_FIELD1)}
              {...getFieldProps(FIELDS_NAME.CUSTOM_FIELD1)}
            />

            <TextField
              label="Personalizado 2 (opcional)"
              fullWidth
              error={hasError(touched, errors, FIELDS_NAME.CUSTOM_FIELD2)}
              {...getFieldProps(FIELDS_NAME.CUSTOM_FIELD2)}
            />

            <TextField
              label="Personalizado 3 (opcional)"
              fullWidth
              error={hasError(touched, errors, FIELDS_NAME.CUSTOM_FIELD3)}
              {...getFieldProps(FIELDS_NAME.CUSTOM_FIELD3)}
            />
          </Stack>
        </Stack>
      </AccordionDetails>

      {openDialogSearchCustomer && (
        <DialogSearchCustomers
          open={openDialogSearchCustomer}
          onClose={() => toggleOpenDialogSearchCustomer(false)}
          onSelectCustomer={handleSelectCustomer}
        />
      )}
    </OrderFormSectionContainer>
  );
};

export default OrderFormDestinationInformationSection;
