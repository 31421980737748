import { ReactElement, useMemo } from 'react';
import { Stack, Typography, Button, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormikContext } from 'formik';
import { omit } from 'lodash';

import {
  getBusinessProfileId,
  getBusinessProfileInfo,
} from '~utils/businessProfile';

import { useBusinessProfileTabsFormContext } from '../../BusinesProfileTabsFormContext';
import { initialValues, FIELDS_NAMES } from '../../utils';

const BusinessProfileDescriptionConfirm = (): ReactElement => {
  const { values, getFieldProps, submitForm, isSubmitting } =
    useFormikContext<typeof initialValues>();

  const { handleGoBack } = useBusinessProfileTabsFormContext();

  const profileInfo = useMemo(() => {
    const businessProfileTypeId = getBusinessProfileId(
      omit(values, FIELDS_NAMES.NOTES),
    );

    if (!businessProfileTypeId) return { name: '', description: '' };

    return getBusinessProfileInfo(businessProfileTypeId);
  }, [values]);

  return (
    <Stack spacing={2} flex={1} justifyContent="space-between">
      <Stack spacing={1}>
        <Typography variant="subtitle1" fontWeight="bold">
          El perfil de tu negocio es:
        </Typography>

        <Typography variant="subtitle1" color="primary" fontWeight="bold">
          {profileInfo.name}
        </Typography>

        <Typography variant="subtitle2" fontStyle="italic">
          {`"${profileInfo.description}"`}
        </Typography>
      </Stack>

      <TextField
        label="Notas (Opcional)"
        fullWidth
        multiline
        rows={4}
        {...getFieldProps(FIELDS_NAMES.NOTES)}
      />

      <Stack direction="row" spacing={2} justifyContent="flex-end">
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => handleGoBack('descriptionConfirm')}
        >
          Regresar
        </Button>

        <LoadingButton
          variant="outlined"
          color="primary"
          onClick={submitForm}
          loading={isSubmitting}
        >
          Confirmar Perfil
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default BusinessProfileDescriptionConfirm;
