import { ReactElement, useCallback } from 'react';
import { Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import BusinessProfileTabsForm from '~components/BusinessProfileTabsForm';

import { useBusinessProfileUpdateWidgetContext } from './Context';
import {
  BusinessProfileUpdateWidgetDialogContainer,
  BusinessProfileUpdateWidgetDialogTitle,
  BusinessProfileUpdateWidgetDialogTitleCloseIcon,
  BusinessProfileUpdateWidgetDialogContent,
} from './styles';

const BusinessProfileUpdateWidgetDialog = (): ReactElement => {
  const {
    openDialog: { open: openDialog, toggleOpen: toggleOpenDialog },
  } = useBusinessProfileUpdateWidgetContext();

  const onClose = useCallback(() => {
    toggleOpenDialog(false);
  }, [toggleOpenDialog]);

  return (
    <BusinessProfileUpdateWidgetDialogContainer open={openDialog} maxWidth="md">
      <BusinessProfileUpdateWidgetDialogTitle>
        <Typography component="h2" variant="h6">
          Completa tu perfil de empresa
        </Typography>

        <BusinessProfileUpdateWidgetDialogTitleCloseIcon
          aria-label="close"
          onClick={onClose}
          size="small"
        >
          <CloseIcon />
        </BusinessProfileUpdateWidgetDialogTitleCloseIcon>
      </BusinessProfileUpdateWidgetDialogTitle>

      <BusinessProfileUpdateWidgetDialogContent dividers>
        <Typography component="p" variant="body1" color="text.secondary">
          Según tus respuestas, ajustaremos nuestra plataforma, comunicación y
          funcionalidades para brindarte el mejor soporte posible.
        </Typography>

        <BusinessProfileTabsForm
          type="create"
          takeUserCompanyTypeId
          onSuccess={onClose}
        />
      </BusinessProfileUpdateWidgetDialogContent>
    </BusinessProfileUpdateWidgetDialogContainer>
  );
};

export default BusinessProfileUpdateWidgetDialog;
