import { styled, Dialog, dialogClasses, DialogActions } from '@mui/material';

export const DialogStartRouteContainer = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    width: '100%',
    minHeight: 395,
  },
});

export const DialogStartRouteLoadingContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  flex: 1,
});

export const DialogStartRouteActions = styled(DialogActions)({
  justifyContent: 'space-between',
});
