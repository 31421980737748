import { styled, Box } from '@mui/material';

export const ProToPlusPopupContent = styled(Box)(({ theme }) => ({
  position: 'fixed',
  width: '100%',
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'transparent',
  zIndex: theme.zIndex.drawer,
  paddingLeft: theme.maxWidths.lateralMenuExpanded,
  paddingRight: theme.maxWidths.lateralMenuExpanded,
  paddingBottom: 8,
}));
