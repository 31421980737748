/* eslint-disable max-len */
import { ReactElement } from 'react';
import { SvgIconProps, SvgIcon } from '@mui/material';

const FeedbackIcon = (props: SvgIconProps): ReactElement => (
  <SvgIcon viewBox="0 0 32 32" {...props}>
    <path
      d="M7.3,17.4c0.6,0.5,1.3,0.7,2,0.7c0,0,0,0,0.1,0l0.1,0c0.1,0,0.1,0,0.2,0c0.4-0.1,0.7-0.2,1.1-0.4
       c1-0.6,1.5-1.5,1.6-2.6c0-0.4-0.1-0.9-0.3-1.3c-0.3-0.6-0.8-1.1-1.5-1.4C10.1,12.1,9.7,12,9.2,12c0,0,0,0,0,0
       c-0.5,0-0.9,0.1-1.3,0.3c-0.5,0.2-0.9,0.6-1.2,1c-0.2,0.2-0.3,0.5-0.4,0.7c-0.2,0.5-0.2,1.1-0.1,1.6C6.4,16.4,6.7,16.9,7.3,17.4z"
    />
    <path
      d="M14.5,25.2c-0.4-0.5-0.8-0.9-1.2-1.2c-0.4-0.3-0.9-0.5-1.4-0.7c-0.5-0.1-1-0.2-1.6-0.2l-0.7,0
       c-0.2,0-0.4,0-0.5,0c-0.3,0-0.6,0-0.9,0c-0.2,0-0.5,0-0.8,0.1c-0.6,0.1-1.2,0.2-1.7,0.5c-0.4,0.2-0.8,0.5-1.3,1
       C3.9,25,3.7,25.5,3.4,26C3.2,26.5,3,27.1,3,27.6c2.5,0,5,0,7.5,0l5,0c0-0.3-0.1-0.6-0.2-1C15.1,26.1,14.8,25.6,14.5,25.2z"
    />
    <path
      d="M15.6,23.6c-0.4-0.5-0.9-0.9-1.4-1.2c-0.5-0.3-1.1-0.6-1.7-0.8c-0.7-0.2-1.4-0.3-2-0.3l-0.7,0c-0.5,0-1,0-1.6,0
       c-0.4,0-0.7,0-1.1,0.1c-1,0.1-1.8,0.4-2.5,0.8c-0.5,0.3-1,0.7-1.4,1.1c-0.6,0.6-1,1.2-1.3,1.9c-0.2,0.5-0.4,1-0.5,1.4
       c-0.1,0.3-0.1,0.7-0.1,1l0,0c0,0.3,0,0.5,0.1,0.8c0.2,0.6,0.7,0.9,1.3,0.9c0,0,0,0,0,0c1.6,0,3.2,0,4.9,0l3.8,0c1.6,0,3.2,0,4.9,0
       c0,0,0,0,0,0c0.3,0,0.6-0.1,0.8-0.3c0.3-0.3,0.5-0.7,0.4-1.1c0-0.8-0.2-1.6-0.4-2.3c-0.1-0.4-0.3-0.8-0.5-1.1
       c-0.1-0.1-0.2-0.3-0.3-0.4C15.9,23.9,15.7,23.7,15.6,23.6z M10.5,27.6c-2.5,0-5,0-7.5,0C3,27.1,3.2,26.5,3.4,26
       c0.2-0.5,0.5-0.9,0.9-1.3c0.4-0.4,0.8-0.7,1.3-1c0.5-0.3,1.1-0.5,1.7-0.5c0.3,0,0.6,0,0.8-0.1c0.3,0,0.6,0,0.9,0c0.2,0,0.4,0,0.5,0
       l0.7,0c0.6,0,1.2,0.1,1.6,0.2c0.5,0.1,1,0.4,1.4,0.7c0.4,0.3,0.8,0.7,1.2,1.2c0.3,0.4,0.6,0.9,0.7,1.5c0.1,0.4,0.2,0.7,0.2,1
       L10.5,27.6z"
    />
    <path
      d="M9.2,19.9c0.1,0,0.2,0,0.2,0c1.3-0.1,2.5-0.6,3.4-1.6c0.9-1,1.3-2.3,1.2-3.6c-0.2-2.6-2.4-4.6-5.1-4.6c0,0-0.1,0-0.1,0h0
       c-1.2,0.1-2.4,0.6-3.3,1.6c-1,1.1-1.4,2.3-1.2,3.7C4.6,18,6.7,19.9,9.2,19.9z M6.3,14.1c0.1-0.3,0.2-0.5,0.4-0.7
       c0.3-0.4,0.7-0.8,1.2-1C8.3,12.1,8.7,12,9.2,12c0,0,0,0,0,0c0.5,0,0.9,0.1,1.3,0.3c0.7,0.4,1.2,0.8,1.5,1.4
       c0.2,0.4,0.3,0.8,0.3,1.3c0,1.1-0.6,2-1.6,2.6c-0.3,0.2-0.7,0.3-1.1,0.4c-0.1,0-0.1,0-0.2,0l-0.1,0c0,0,0,0-0.1,0
       c-0.8,0-1.4-0.2-2-0.7c-0.6-0.5-0.9-1-1-1.7C6.1,15.2,6.2,14.6,6.3,14.1z"
    />
    <path
      d="M30.1,7.2c0-0.3,0-0.5-0.1-0.8c-0.2-0.6-0.4-1.1-0.7-1.4c-0.3-0.4-0.7-0.7-1.1-0.9c-0.5-0.2-1-0.3-1.6-0.3
       c-5.7,0-8.4,0-14.1,0c-0.3,0-0.6,0-0.8,0.1c-0.6,0.1-1,0.3-1.4,0.7C9.9,4.9,9.7,5.2,9.5,5.5C9.3,6,9.1,6.6,9.1,7.2
       c0,0.3,0,0.6,0,0.9c0,0.2,0,0.3,0,0.5c0,0.1,0.1,0.2,0.2,0.2c0.3,0,0.6,0,0.9,0.1c0.1,0,0.3,0,0.4,0.1c0.1,0,0.1,0,0.2,0l0.1,0V8.7
       c0-0.2,0-0.4,0-0.5c0-0.4,0-0.8,0-1.2c0-0.5,0.3-0.9,0.7-1.1c0.3-0.2,0.6-0.2,0.9-0.2c5.7,0,8.3,0,14,0c0.1,0,0.3,0,0.4,0
       c0.4,0,0.6,0.1,0.9,0.4c0.2,0.1,0.3,0.3,0.4,0.6c0,0.2,0.1,0.4,0.1,0.7c0,4.7,0,4.3,0,9c0,0.2,0,0.3-0.1,0.5
       c-0.1,0.4-0.4,0.8-0.8,1c-0.2,0.1-0.3,0.1-0.6,0.1l-7.8,0c0,0,0,0,0,0h-0.1c-1.5,0-2.6,1.2-2.6,2.7l0.1,0.1l0,0l0,0
       c0.5-0.6,1.4-0.9,2.2-0.9c0.3,0,0.6,0,0.8,0c0.7,0,1.3,0,1.9,0c0.1,0,0.1,0,0.2,0l0,0c1.5,0,2.8,0,5.2,0c0.3,0,0.6,0,0.9-0.1
       c0.1,0,0.1,0,0.2,0c0.3-0.1,0.6-0.2,0.8-0.4c0.3-0.2,0.6-0.5,0.9-1c0.4-0.6,0.6-1.2,0.6-1.9C30.1,11.5,30.1,11.9,30.1,7.2z"
    />
  </SvgIcon>
);

export default FeedbackIcon;
