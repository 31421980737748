import { FC, createContext, useContext, useState, useCallback } from 'react';

import {
  BusinessProfileTabsFormContextValue,
  BusinessProfileTabsFormContextPanelsId,
} from './types';

const BusinessProfileTabsFormContext =
  createContext<BusinessProfileTabsFormContextValue>({
    activeTab: 'operationType',
    handleNextTab: () => {},
    handleGoBack: () => {},
  });

export const useBusinessProfileTabsFormContext =
  (): BusinessProfileTabsFormContextValue =>
    useContext(BusinessProfileTabsFormContext);

const BusinessProfileTabsFormProvider: FC = ({ children }) => {
  const [activeTab, setActiveTab] =
    useState<BusinessProfileTabsFormContextPanelsId>('operationType');

  const [previousActivesTabs, setPreviousActivesTabs] = useState<
    Record<
      BusinessProfileTabsFormContextPanelsId,
      BusinessProfileTabsFormContextPanelsId | undefined
    >
  >({
    operationType: undefined,
    orderManagementCollect: undefined,
    orderCollectedTransfer: undefined,
    orderCentralizationTransfer: undefined,
    orderCentralizationShipment: undefined,
    orderDistributionShipment: undefined,
    descriptionConfirm: undefined,
  });

  const handleNextTab = useCallback(
    (nextTabId: BusinessProfileTabsFormContextPanelsId) => {
      setPreviousActivesTabs((prevPreviousActivesTabs) => ({
        ...prevPreviousActivesTabs,
        [nextTabId]: activeTab,
      }));

      setActiveTab(nextTabId);
    },
    [activeTab],
  );

  const handleGoBack = useCallback(
    (currentTabId: BusinessProfileTabsFormContextPanelsId) => {
      const currentPreviousActivesTabs = previousActivesTabs[currentTabId];

      if (currentPreviousActivesTabs) {
        setPreviousActivesTabs((prevPreviousActivesTabs) => ({
          ...prevPreviousActivesTabs,
          [currentTabId]: undefined,
        }));

        setActiveTab(currentPreviousActivesTabs);
      }
    },
    [previousActivesTabs],
  );

  return (
    <BusinessProfileTabsFormContext.Provider
      value={{ activeTab, handleNextTab, handleGoBack }}
    >
      {children}
    </BusinessProfileTabsFormContext.Provider>
  );
};

export default BusinessProfileTabsFormProvider;
