import { styled, Box, Chip, chipClasses, lighten } from '@mui/material';

export const NewsMenuContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  minWidth: 400,
  maxWidth: 400,

  '&::before': {
    content: '""',
    position: 'absolute',
    height: 54,
    left: 0,
    right: 0,
    top: 0,
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
  },
}));

export const NewsMenuHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
  color: theme.palette.common.white,
  paddingBottom: theme.spacing(1.5),
}));

export const NewsMenuContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  gap: theme.spacing(2),
  paddingTop: theme.spacing(2),
}));

export const NewsMenuContentList = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  gap: theme.spacing(1),
  overflowY: 'auto',
  height: '100%',
  minHeight: 'calc(100vh - 180px)',
  maxHeight: 'calc(100vh - 180px)',
}));

export const NewsMenuPlanInfoChipRoot = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'customColor',
})<{ customColor: string }>(({ theme, customColor }) => ({
  textTransform: 'uppercase',
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.pxToRem(9),
  height: 16,
  borderRadius: 24 / 2,
  backgroundColor: customColor,
  color: theme.palette.common.white,

  [`&.${chipClasses.clickable}:hover`]: {
    backgroundColor: lighten(customColor, 0.6),
    color: theme.palette.common.white,
  },
}));
