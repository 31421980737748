import { ReactElement } from 'react';
import { Typography, Stack, Button } from '@mui/material';

import { DialogOrderSearchProps } from './types';
import DialogOrderSearchProvider from './Context';
import DialogOrderSearchFilters from './Filters';
import DialogOrderSearchTable from './Table';
import {
  DialogOrderSearchContainer,
  DialogOrderSearchTitle,
  DialogOrderSearchContent,
  DialogOrderSearchActions,
} from './styles';

const DialogOrderSearch = ({
  open,
  onClose,
}: DialogOrderSearchProps): ReactElement => (
  <DialogOrderSearchProvider>
    <DialogOrderSearchContainer open={open}>
      <DialogOrderSearchTitle>
        <Typography component="h2" variant="h6">
          Buscar pedidos
        </Typography>
      </DialogOrderSearchTitle>

      <DialogOrderSearchContent dividers>
        <Stack spacing={2}>
          <DialogOrderSearchFilters />

          <DialogOrderSearchTable />
        </Stack>
      </DialogOrderSearchContent>

      <DialogOrderSearchActions>
        <Button color="secondary" onClick={onClose}>
          Cerrar
        </Button>
      </DialogOrderSearchActions>
    </DialogOrderSearchContainer>
  </DialogOrderSearchProvider>
);

export default DialogOrderSearch;
