import { ReactElement, ElementType, useCallback, useMemo } from 'react';
import { TabContext } from '@mui/lab';
import { useFormik, FormikProvider, FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import { omit } from 'lodash';

import {
  getBusinessProfileId,
  getCompanyTypeIdByBusinessProfileId,
} from '~utils/businessProfile';
import { useLazyRequest, useAppDispatch, useAppSelector } from '~hooks/index';
import { updateCompanyType } from '~services/admin/companySetting';
import { AdminCompanyUpdateCompanyTypeResponse } from '~services/admin/companySetting/types';
import {
  selectAuth,
  setUserCompanyType,
  setUserCompanyBusinessProfileType,
} from '~store/slices/auth';
import { PartnerCompanyTypes } from '~globals/types/enums';

import BusinessProfileTabsFormProvider, {
  useBusinessProfileTabsFormContext,
} from './BusinesProfileTabsFormContext';
import {
  BusinessProfileTabsFormContextPanelsId,
  BusinesProfileTabsFormProps,
} from './types';
import {
  BusinessProfileDescriptionConfirm,
  BusinessProfileOperationType,
  BusinessProfileOrderCentralizationShipment,
  BusinessProfileOrderCentralizationTransfer,
  BusinessProfileOrderCollectedTransfer,
  BusinessProfileOrderDistributionShipment,
  BusinessProfileOrderManagementCollect,
} from './Tabs';
import { initialValues, validationSchema, FIELDS_NAMES } from './utils';
import { BusinessProfileTabsFormTabPanel } from './styles';

const TABS_PANELS: {
  id: BusinessProfileTabsFormContextPanelsId;
  panel: ElementType;
}[] = [
  {
    id: 'operationType',
    panel: BusinessProfileOperationType,
  },
  {
    id: 'orderManagementCollect',
    panel: BusinessProfileOrderManagementCollect,
  },
  {
    id: 'orderCollectedTransfer',
    panel: BusinessProfileOrderCollectedTransfer,
  },
  {
    id: 'orderCentralizationTransfer',
    panel: BusinessProfileOrderCentralizationTransfer,
  },
  {
    id: 'orderCentralizationShipment',
    panel: BusinessProfileOrderCentralizationShipment,
  },
  {
    id: 'orderDistributionShipment',
    panel: BusinessProfileOrderDistributionShipment,
  },
  {
    id: 'descriptionConfirm',
    panel: BusinessProfileDescriptionConfirm,
  },
];

const BusinesProfileTabsForm = ({
  type = 'create',
  takeUserCompanyTypeId = false,
  onSuccess,
  onError,
}: BusinesProfileTabsFormProps): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectAuth);

  const { activeTab } = useBusinessProfileTabsFormContext();

  const isCreateType = useMemo(() => type === 'create', [type]);

  const [, , , exceuteUpdateCompanyType] = useLazyRequest({
    request: updateCompanyType,
    withPostSuccess: (response) => {
      const { companyTypeId, businessProfileTypeId } = response.data
        ?.data as AdminCompanyUpdateCompanyTypeResponse;

      dispatch(setUserCompanyType(companyTypeId));

      dispatch(setUserCompanyBusinessProfileType(businessProfileTypeId));

      const successMessage = isCreateType
        ? 'El perfil de tu negocio fue creado correctamente'
        : 'El perfil de tu negocio fue modificado correctamente';

      enqueueSnackbar(successMessage, { variant: 'success' });

      onSuccess?.({
        companyTypeId,
        businessProfileTypeId,
      });
    },
    withPostFailure: () => {
      const errorMessage = isCreateType
        ? 'No se pudo crear el perfil de tu negocio, intente nuevamente'
        : 'No se pudo modificar el perfil de tu negocio, intente nuevamente';

      enqueueSnackbar(errorMessage, { variant: 'error' });

      onError?.();
    },
  });

  const onSubmit = useCallback(
    async (
      values: typeof initialValues,
      { setSubmitting }: FormikHelpers<typeof initialValues>,
    ) => {
      const businessProfileTypeId = getBusinessProfileId(
        omit(values, FIELDS_NAMES.NOTES),
      );

      if (businessProfileTypeId) {
        const companyTypeId = takeUserCompanyTypeId
          ? (user?.company.companyTypeId as PartnerCompanyTypes)
          : getCompanyTypeIdByBusinessProfileId(businessProfileTypeId);

        await exceuteUpdateCompanyType({
          companyTypeId,
          businessProfileTypeId,
          businessProfileNote: values[FIELDS_NAMES.NOTES] ?? null,
        });
      }

      setSubmitting(false);
    },
    [exceuteUpdateCompanyType, takeUserCompanyTypeId, user],
  );

  const formikBag = useFormik({ initialValues, validationSchema, onSubmit });

  return (
    <FormikProvider value={formikBag}>
      <TabContext value={activeTab}>
        {TABS_PANELS.map(({ id, panel: Panel }) => (
          <BusinessProfileTabsFormTabPanel
            key={`business-profile-form-panel-${id}`}
            value={id}
          >
            <Panel />
          </BusinessProfileTabsFormTabPanel>
        ))}
      </TabContext>
    </FormikProvider>
  );
};

const WrapperBusinesProfileTabsForm = (
  props: BusinesProfileTabsFormProps,
): ReactElement => (
  <BusinessProfileTabsFormProvider>
    <BusinesProfileTabsForm {...props} />
  </BusinessProfileTabsFormProvider>
);

export default WrapperBusinesProfileTabsForm;
