import * as Yup from 'yup';

import { VALIDATIONS_ERROR } from '~constants/index';

export const FIELDS_NAME = {
  NOTES: 'notes',
} as const;

export const initialValues = {
  [FIELDS_NAME.NOTES]: '',
};

export const validationSchema = Yup.object().shape({
  [FIELDS_NAME.NOTES]: Yup.string().required(VALIDATIONS_ERROR.REQUIRED),
});
