import { ReactElement } from 'react';

import { MapSequenceStopsInfoContainer } from './styles';

const MapSequenceStopsInfo = (): ReactElement => (
  <MapSequenceStopsInfoContainer
    severity="info"
    variant="standard"
    icon={false}
  >
    La secuencia de paradas une las mismas para ilustrar el orden de visita
    propuesto. La ruta detallada se visualizará al iniciar el recorrido.
  </MapSequenceStopsInfoContainer>
);

export default MapSequenceStopsInfo;
